// @flow

import React from 'react';
import type { Node } from 'react';
import { IntlProvider } from 'react-intl';
import { FilterService } from '@riseart/filter-service';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';

type Props = {
  locale: Object,
  children: any,
};

/**
 * I18nProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
export const I18nProvider = ({ locale, children }: Props): Node => {
  // Config UrlAssembler with selected locale
  UrlAssembler.config(locale);

  // set locale in localized config service
  LocalizedConfig.config(locale && locale.name);

  // Configure FilterService with selected locale
  FilterService.setLocale(locale.name);

  return (
    <I18nComponentsLoader dataKey="translations" locale={locale.name}>
      {(messages) => (
        <IntlProvider locale={locale.name} messages={messages} textComponent="span">
          {children}
        </IntlProvider>
      )}
    </I18nComponentsLoader>
  );
};
