// @flow

export const EVENT_RSVP_ACTIONS = {
  STATUS_SET: 'EVENT_RSVP_STATUS_SET',
};

/**
 * setEventRsvpStatus
 *
 * @param {Object} payload
 * @returns {Object} action
 */
export function setEventRsvpStatus(payload: Object): Object {
  return {
    type: EVENT_RSVP_ACTIONS.STATUS_SET,
    payload,
  };
}
