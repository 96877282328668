import { graphql as GRAPHQL_CONFIG } from 'Config';
import parseInt from 'lodash/parseInt';

/**
 * Action Types
 */
export const AUTH_ACTIONS = {
  USER_LOGIN_DONE: 'USER_LOGIN_DONE',
  USER_LOGIN_LOADING: 'USER_LOGIN_LOADING',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_REGISTER_DONE: 'USER_REGISTER_DONE',
  USER_REGISTER_LOADING: 'USER_REGISTER_LOADING',
  USER_REGISTER_ERROR: 'USER_REGISTER_ERROR',
  TOKEN_FETCH: 'TOKEN_FETCH',
  TOKEN_FETCH_LOADING: 'TOKEN_FETCH_LOADING',
  TOKEN_FETCH_DONE: 'TOKEN_FETCH_DONE',
  TOKEN_FETCH_ERROR: 'TOKEN_FETCH_ERROR',
  TOKEN_UPDATED: 'TOKEN_UPDATED',
  TOKEN_REFRESH: 'TOKEN_REFRESH',
  TOKEN_REFRESH_DONE: 'TOKEN_REFRESH_DONE',
  TOKEN_CLEAR: 'TOKEN_CLEAR',
  AUTH_COOKIE_UPDATE: 'AUTH_COOKIE_UPDATE',
  AUTH_TOKEN_COOKIE_UPDATE: 'AUTH_TOKEN_COOKIE_UPDATE',
  AUTH_TOKEN_COOKIE_CLEAR: 'AUTH_TOKEN_COOKIE_CLEAR',
  AUTH_VISITOR_COOKIE_UPDATE: 'AUTH_VISITOR_COOKIE_UPDATE',
};

/**
 *  Action Creators
 */
export function authTokenFetch(inputData = {}) {
  // visitorId has to be an integer or null
  const visitorId = parseInt(inputData.visitorId);

  return {
    type: AUTH_ACTIONS.TOKEN_FETCH,
    payload: {
      ...inputData,
      apiKey: GRAPHQL_CONFIG.authKey,
      ...(!Number.isNaN(visitorId) ? { visitorId } : null),
    },
  };
}

export function authTokenFetchLoading() {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH_LOADING,
  };
}

export function authTokenFetchDone(payload) {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH_DONE,
    payload,
  };
}

export function authTokenFetchError(error) {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH_ERROR,
    payload: { ...error },
  };
}

export function authTokenUpdated(payload) {
  return {
    type: AUTH_ACTIONS.TOKEN_UPDATED,
    payload,
  };
}

export function authTokenRefresh(payload) {
  return {
    type: AUTH_ACTIONS.TOKEN_REFRESH,
    payload,
  };
}

export function authTokenRefreshDone(payload) {
  return {
    type: AUTH_ACTIONS.TOKEN_REFRESH_DONE,
    payload,
  };
}

export function authCookieUpdate(payload) {
  return {
    type: AUTH_ACTIONS.AUTH_COOKIE_UPDATE,
    payload: { payload },
  };
}

export function authTokenClear(payload) {
  return {
    type: AUTH_ACTIONS.TOKEN_CLEAR,
    payload,
  };
}
