// @flow

import { find } from '@riseart/fe-utils';
import { meta as META_ENUM } from 'Enum';
import { application as APP_CONFIG } from 'Config';
import { LDTYPES, generateLDJSON } from 'shared_models/seo/ldJSON';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';

const META_TRANSLATION_REGEX = /^meta\.(\S+$)/;
const { ATTRIBUTE_VALUE, TAGNAME, METATYPE, SUBSCRIBER_NAME } = META_ENUM;

/**
 * valueHandlers
 *
 * Set of functions used for generating of values used for meta tags
 * based on input value set in react router page metas
 */
const valueHandlers = {
  urlType(value: string, options: Object): string {
    const { origin, href, pathname, search } = LocationManager.get();

    if (value === 'pathname') {
      return `${origin}${pathname}`;
    }
    if (value === 'routePath') {
      return `${origin}${options.match.url}`;
    }
    if (value === 'pathnameAndQueryString') {
      return `${origin}${pathname}${search}`;
    }
    if (value === 'fullPath') {
      return href;
    }
    return value;
  },
  stringType(value: string) {
    return value;
  },
  pageTitle(value: any, options: Object) {
    const { disabled, text } =
      typeof value === 'string' ? { disabled: false, text: value } : value || {};

    return {
      disabled: disabled || false,
      text:
        typeof text === 'string' && META_TRANSLATION_REGEX.test(text)
          ? options.intl.formatMessage({ id: text })
          : text,
    };
  },
  translationString(value: any, options: Object) {
    // check if string provided is a translation key starting with meta. and without spaced
    return typeof value === 'string' && META_TRANSLATION_REGEX.test(value) && options.intl
      ? options.intl.formatMessage({ id: value })
      : value;
  },
  linkHreflang(value: any, options: Object) {
    const hrefLangValues = APP_CONFIG.i18n.locales.reduce((accumulator, locale) => {
      let { params } = options.match;

      // Dynamic data passed via MetaProvider as list of dynamic parameters for each locale
      if (value && value.type === 'dynamic') {
        const localeParams = find(value.params, (param) => param.localeCode === locale.name);
        // If not localeParams are found, then there is no content for specific locale
        if (!localeParams) {
          return accumulator;
        }
        const { localeCode: excludedLocale, id: excludeId, ...restParams } = localeParams || {};
        params = { ...params, ...restParams };
      }

      let href = null;

      if (value && value.type === 'generated') {
        href = value.href[locale.name];
      } else {
        href =
          options.pageRouteConfig.path !== undefined
            ? UrlAssembler.byRouteKey(options.pageRouteConfig.key, {
                ...(options.absolutePath ? { origin: LocationManager.get('origin') } : null),
                ...(options.location && options.location.search
                  ? { search: options.location.search }
                  : null),
                ...(options.location && options.location.hash
                  ? { hash: options.location.hash }
                  : null),
                ...(params ? { params } : {}),
                locale,
              })
            : null;
      }

      // If not href is defined, it means there is no content for specific locale
      if (!href) {
        return accumulator;
      }

      if (locale.isDefault) {
        accumulator.push({ hreflang: 'x-default', href });
      }

      accumulator.push({ hreflang: locale.language, href });

      return accumulator;
    }, []);

    return hrefLangValues;
  },
};

export const META_SUBSCRIBER_NAME = SUBSCRIBER_NAME;
export const VALID_META_KEYS: Array<any> = Object.keys(METATYPE).map((i) => METATYPE[i]);
export const META_PRIORITY = { PAGELOAD: 0, DETAILS: 1 };
export const PERMANENT_TAGS = [TAGNAME.TITLE];
export const COMBINED_META_KEYS = [
  METATYPE.PAGE_TITLE,
  METATYPE.META_TITLE,
  METATYPE.META_DESCRIPTION,
];

/**
 * META_DATA_HANDLERS
 *
 * prepares data for the dom renderer based on the tag that the meta should use
 */
export const META_DATA_HANDLERS: Object = {
  [TAGNAME.TITLE](data, attributes = {}) {
    return {
      attributes: { ...attributes },
      textNode: typeof data === 'string' ? data : JSON.stringify(data),
    };
  },
  [TAGNAME.META](data, attributes = {}) {
    return {
      attributes: { ...attributes },
      additionalAttributes: { content: data },
    };
  },
  [TAGNAME.LINK](data, attributes = {}) {
    return {
      attributes: { ...attributes },
      additionalAttributes: { href: data },
    };
  },
  [TAGNAME.SCRIPT](data, attributes = {}) {
    return {
      attributes: { ...attributes },
      textNode: typeof data === 'string' ? data : JSON.stringify(data),
    };
  },
};

/**
 * META_VALUE_BY_TYPE_HANDLER
 *
 * collects data by meta type
 */
export const META_VALUE_BY_TYPE_HANDLER: Object = {
  [METATYPE.LINK_CANONICAL]: valueHandlers.urlType,
  [METATYPE.LINK_CANONICAL_REDIRECT]: valueHandlers.urlType,
  [METATYPE.META_OG_URL]: valueHandlers.urlType,
  [METATYPE.META_OG_TYPE]: valueHandlers.stringType,
  [METATYPE.META_TWITTER_URL]: valueHandlers.urlType,
  [METATYPE.META_TWITTER_CARD]: valueHandlers.stringType,
  [METATYPE.PAGE_TITLE]: valueHandlers.pageTitle,
  [METATYPE.LINK_HREFLANG]: (value, options = {}) =>
    valueHandlers.linkHreflang(value, { ...options, absolutePath: true }),
  [METATYPE.LINK_HREFLANG_REDIRECT]: valueHandlers.linkHreflang,
  default: valueHandlers.translationString,
};

/**
 * METADATA_SEPARATORS
 *
 * separators for meta data when condition: 'concat' is set in the config
 */
export const METADATA_SEPARATORS = {
  PIPE: ' | ',
  SPACE: ' ',
  COMMA: ', ',
};

/**
 * META_TYPES_MAPPER
 *
 * prepares data based on meta type
 */
export const META_TYPES_MAPPER: Object = {
  [METATYPE.META_TITLE]: {
    tag: TAGNAME.TITLE,
    separator: METADATA_SEPARATORS.PIPE,
  },
  [METATYPE.META_KEYWORDS]: {
    tag: TAGNAME.META,
    attributes: {
      name: ATTRIBUTE_VALUE.KEYWORDS,
    },
    separator: METADATA_SEPARATORS.COMMA,
  },
  [METATYPE.META_DESCRIPTION]: {
    tag: TAGNAME.META,
    attributes: {
      name: ATTRIBUTE_VALUE.DESCRIPTION,
    },
    separator: METADATA_SEPARATORS.SPACE,
  },
  [METATYPE.META_STATUS]: {
    tag: TAGNAME.META,
    attributes: {
      'http-equiv': ATTRIBUTE_VALUE.STATUS,
    },
  },
  [METATYPE.META_ROBOTS]: {
    tag: TAGNAME.META,
    attributes: {
      name: ATTRIBUTE_VALUE.ROBOTS,
    },
  },
  [METATYPE.META_OG_TITLE]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_TITLE,
    },
    separator: METADATA_SEPARATORS.PIPE,
  },
  [METATYPE.META_OG_DESCRIPTION]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_DESCRIPTION,
    },
    separator: METADATA_SEPARATORS.SPACE,
  },
  [METATYPE.META_OG_IMAGE]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_IMAGE,
    },
  },
  [METATYPE.META_OG_URL]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_URL,
    },
  },
  [METATYPE.META_OG_TYPE]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_TYPE,
    },
  },
  [METATYPE.META_OG_PROFILE_FIRST_NAME]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_PROFILE_FIRST_NAME,
    },
  },
  [METATYPE.META_OG_PROFILE_LAST_NAME]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_PROFILE_LAST_NAME,
    },
  },
  [METATYPE.META_OG_PROFILE_GENDER]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.OG_PROFILE_GENDER,
    },
  },
  [METATYPE.META_TWITTER_CARD]: {
    tag: TAGNAME.META,
    attributes: {
      name: ATTRIBUTE_VALUE.TWITTER_CARD,
    },
  },
  [METATYPE.META_TWITTER_URL]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.TWITTER_URL,
    },
  },
  [METATYPE.META_TWITTER_TITLE]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.TWITTER_TITLE,
    },
    separator: METADATA_SEPARATORS.PIPE,
  },
  [METATYPE.META_TWITTER_DESCRIPTION]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.TWITTER_DESCRIPTION,
    },
    separator: METADATA_SEPARATORS.SPACE,
  },
  [METATYPE.META_TWITTER_IMAGE]: {
    tag: TAGNAME.META,
    attributes: {
      property: ATTRIBUTE_VALUE.TWITTER_IMAGE,
    },
  },
  [METATYPE.LINK_NEXT]: {
    tag: TAGNAME.LINK,
    attributes: {
      rel: ATTRIBUTE_VALUE.NEXT,
    },
  },
  [METATYPE.LINK_PREV]: {
    tag: TAGNAME.LINK,
    attributes: {
      rel: ATTRIBUTE_VALUE.PREV,
    },
  },
  [METATYPE.LINK_CANONICAL]: {
    tag: TAGNAME.LINK,
    attributes: {
      rel: ATTRIBUTE_VALUE.CANONICAL,
    },
  },
  [METATYPE.LINK_CANONICAL_REDIRECT]: {
    tag: TAGNAME.LINK,
    excludeFromDom: true,
    attributes: {
      rel: ATTRIBUTE_VALUE.CANONICAL,
    },
  },
  [METATYPE.LINK_HREFLANG]: {
    tag: TAGNAME.LINK,
    attributes: {
      rel: ATTRIBUTE_VALUE.ALTERNATE,
    },
  },
  [METATYPE.LINK_HREFLANG_REDIRECT]: {
    tag: TAGNAME.LINK,
    excludeFromDom: true,
    attributes: {
      rel: ATTRIBUTE_VALUE.ALTERNATE,
    },
  },
  [METATYPE.SCRIPT_LD_JSON_WEBSITE]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-website',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_WEBPAGE]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-webpage',
    },
    collectFromOtherMetas: (ownProperties: Object = {}, meta: Object = {}): Object => {
      return generateLDJSON(LDTYPES.WEBPAGE, {
        ...ownProperties,
        metaTitle: meta.metaTitle,
        metaDescription: meta.metaDescription,
      });
    },
  },
  [METATYPE.SCRIPT_LD_JSON_BREADCRUMBS]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-breadcrumbs',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_PRODUCT]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-product',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTWORK]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-artwork',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_COLLECTION]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-collection',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_PERSON]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-person',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_BLOG]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-blog',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTICLE]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-article',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTICLES_LIST]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-articles-list',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTICLE_CATEGORIES_LIST]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-article-categories-list',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_EVENT]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-event',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTWORKS_ARTIST]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-artworks-artist',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTWORKS_SIMILAR]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-artworks-similar',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTWORKS_LIST]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-artworks-list',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTISTS_LIST]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-artists-list',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_ARTIST]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-artist',
    },
  },
  [METATYPE.SCRIPT_LD_JSON_AGGREGATE_RATING]: {
    tag: TAGNAME.SCRIPT,
    attributes: {
      type: ATTRIBUTE_VALUE.APPLICATION_LDJSON,
      id: 'ld-json-type-aggregate-rating',
    },
  },
};
