// @flow

import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { store as STORE_CONFIG } from 'Config';
import { Query } from 'shared_services/apollo/Query';
import {
  favoritesFetchLoading,
  favoritesFetchDone,
  favoritesFetchError,
} from 'shared_services/redux/actions/favorites/favorites';
import LIST_FAVORITES_QUERY from 'shared_data/queries/favorites/list.graphql';

type Props = {
  visitorId: number,
  children: Function | React$Element<*>,
  actionFavoritesFetchLoading: Function,
  actionFavoritesFetchDone: Function,
  actionFavoritesFetchError: Function,
};

/**
 * ListFavorites
 *
 * @param {Props} props
 */
class ListFavorites extends React.Component<Props> {
  /**
   * defaultProps
   */
  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    if (this.props.visitorId) {
      this.props.actionFavoritesFetchLoading();
    }
  }

  /**
   * componentDidUpdate
   * @param {Props} prevProps
   */
  componentDidUpdate(prevProps: Props): void {
    if (this.props.visitorId !== prevProps.visitorId) {
      this.props.actionFavoritesFetchLoading();
    }
  }

  /**
   * shouldComponentUpdate
   * @param {Props} nextProps
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps: Props): boolean {
    return nextProps.visitorId !== this.props.visitorId;
  }

  /**
   * render
   */
  render() {
    const { children, visitorId, actionFavoritesFetchDone, actionFavoritesFetchError } = this.props;

    if (!visitorId) {
      return children;
    }

    return (
      <Query
        query={LIST_FAVORITES_QUERY}
        variables={{ visitorId }}
        onCompleted={(data) => actionFavoritesFetchDone(data.listFavorites)}
        onError={(error) => actionFavoritesFetchError(error)}
        ssr={false}
      >
        {({ loading, error, data = {} }) => {
          if (typeof children === 'function') {
            return children({ loading, error, data: data.listFavorites });
          }

          return children;
        }}
      </Query>
    );
  }
}

/**
 * mapStateToProps
 * @param {Object} state
 */
const mapStateToProps = (state: Object): Object => ({
  visitorId: get(state[STORE_CONFIG.keys.auth], 'data.payload.visitor_id'),
});

/**
 * mapDispatchToProps
 * @param {Function} dispatch
 */
const mapDispatchToProps = (dispatch: Function): Object =>
  bindActionCreators(
    {
      actionFavoritesFetchLoading: favoritesFetchLoading,
      actionFavoritesFetchDone: favoritesFetchDone,
      actionFavoritesFetchError: favoritesFetchError,
    },
    dispatch,
  );

export const ListFavoritesQuery = connect(mapStateToProps, mapDispatchToProps)(ListFavorites);
