// @flow

/**
 * Action Types
 */
export const NOTIFICATION_ACTIONS = {
  NOTIFICATION_ADD: 'NOTIFICATION_ADD',
  NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
  NOTIFICATION_TYPE_REMOVE: 'NOTIFICATION_TYPE_REMOVE',
};

/**
 * Action Creators
 */
export function addNotification(payload: Object): Object {
  return {
    type: NOTIFICATION_ACTIONS.NOTIFICATION_ADD,
    payload,
  };
}

export function removeNotification(payload: Object): Object {
  return {
    type: NOTIFICATION_ACTIONS.NOTIFICATION_REMOVE,
    payload,
  };
}

export function removeNotificationsByType(type: string): Object {
  return {
    type: NOTIFICATION_ACTIONS.NOTIFICATION_TYPE_REMOVE,
    payload: { type },
  };
}
