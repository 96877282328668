// @flow

import { AUTH_ACTIONS } from 'shared_services/redux/actions/container';

const DEFAULT_AUTH_STATE = {
  data: {
    token: null,
    payload: null,
  },
  error: null,
  status: {
    loading: false,
    loaded: false,
    error: false,
  },
};

/**
 * authReducer
 *
 * @param {Object} state
 * @param {Object} action
 * @return {Object} next state
 */
export function authReducer(state: Object = DEFAULT_AUTH_STATE, action: Object = {}) {
  switch (action.type) {
    case AUTH_ACTIONS.TOKEN_FETCH_LOADING:
      return {
        ...state,
        status: {
          error: false,
          loading: true,
          loaded: false,
        },
      };
    case AUTH_ACTIONS.TOKEN_REFRESH_DONE:
    case AUTH_ACTIONS.TOKEN_FETCH_DONE: {
      return {
        data: { ...DEFAULT_AUTH_STATE.data, ...action.payload },
        error: null,
        status: {
          error: false,
          loading: false,
          loaded: true,
        },
      };
    }
    case AUTH_ACTIONS.TOKEN_FETCH_ERROR:
      return {
        data: { ...DEFAULT_AUTH_STATE.data, ...state.data },
        error: { ...action.payload },
        status: {
          error: true,
          loading: false,
          loaded: false,
        },
      };
    case AUTH_ACTIONS.TOKEN_CLEAR:
      return {
        data: { token: null, payload: null },
        error: null,
        status: {
          error: false,
          loading: false,
          loaded: true,
        },
      };
    default:
      return state;
  }
}
