// @flow

import { ErrorService, type ErrorMessageType } from 'shared_services/riseart/errors/ErrorService';

/**
 * Action Types
 */
export const ERRORS_ACTIONS = {
  ERROR_ADD: 'ERROR_ADD',
};

/**
 * Action Creators
 */
export function errorAdd(error: ErrorMessageType): Object {
  const payload = ErrorService.buildPayload(error);

  return {
    type: ERRORS_ACTIONS.ERROR_ADD,
    payload,
  };
}
