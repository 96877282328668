// @flow

import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GOOGLE_TAGMANAGER_ACTIONS } from 'shared_data/providers/google/tagmanager/Actions';
import { actionFactory } from 'shared_services/riseart/utils/Utils';
import { LocationManager } from 'shared_services/riseart/url/Location';

type Props = {
  onUrlChange?: Function,
  children: any,
};

export function UrlChangeTracker({ children, onUrlChange }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [locationState, setLocationState] = useState({});

  const handleUrlChange = useCallback(
    (location: Object = {}, action: ?string): void => {
      const { href, pathname, search, hash } = LocationManager.get();

      if (
        locationState &&
        (locationState.pathname !== pathname ||
          locationState.search !== search ||
          locationState.hash !== hash)
      ) {
        const newState = {
          pathname,
          search,
          hash,
          url: href,
          uri: [pathname, search].join(''),
        };

        if (typeof onUrlChange === 'function') {
          onUrlChange(newState, location, action);
        }

        // Update location state
        setLocationState(newState);

        // Dispatch new data to GTM
        dispatch(actionFactory(GOOGLE_TAGMANAGER_ACTIONS.PAGEVIEW, newState));
      }
    },
    [locationState], // eslint-disable-line
  );

  useEffect(() => {
    // Add listen handler on mount
    const unlisten = history.listen(handleUrlChange);

    // Remove listener on unmount
    return unlisten;
  }, [handleUrlChange, history]); // eslint-disable-line
  return children;
}
