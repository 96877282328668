// @flow

import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { connect } from 'react-redux';
import { withGUIState } from 'shared_hocs/gui/withGuiState';
import { MetaService } from 'shared_services/riseart/meta/Meta';
import { pageMetaResolved } from 'shared_services/redux/actions/application/meta';
import {
  applicationStoreUpdateRedirects,
  applicationStoreReset,
} from 'shared_services/redux/actions/application/store';

type Props = {
  isSSR: boolean,
  match: Object,
  intl: Object,
  dispatch: Function,
  actionGuiUpdate: Function,
  actionPageMetaResolved: Function,
  actionAppStoreUpdateRedirects: Function,
  actionReset: Function,
  children: ?any,
};

type State = {
  pathname: ?string,
};

/**
 * MetaConfigComponent
 */
class MetaConfig extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      isSSR,
      match,
      intl,
      dispatch,
      actionGuiUpdate,
      actionPageMetaResolved,
      actionAppStoreUpdateRedirects,
      actionReset,
    } = this.props;
    MetaService.config(
      { isSSR, match, intl, dispatch },
      actionGuiUpdate,
      actionPageMetaResolved,
      actionAppStoreUpdateRedirects,
      actionReset,
    ).reset();
  }

  render() {
    return this.props.children || null;
  }
}

/**
 * mapDispatchToProps
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch: Function): Object => ({
  actionPageMetaResolved: (payload) => dispatch(pageMetaResolved(payload)),
  actionReset: () => dispatch(applicationStoreReset('redirects')),
  actionAppStoreUpdateRedirects: (meta) => dispatch(applicationStoreUpdateRedirects(meta)),
});

export const MetaConfigProvider = withGUIState()(
  withTranslatedRouter(injectIntl(connect(null, mapDispatchToProps)(MetaConfig))),
);
