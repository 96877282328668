// @flow
import { EVENT_ACTIONS } from 'shared_services/redux/actions/container';
import { RaEventsService } from 'shared_services/riseart/RaEvents';
import { application as CONFIG_APP } from 'Config';

/**
 * MiddlewareEvent
 */
export class MiddlewareEvent {
  static NAMES = {
    COMMISSION_CREATE: 'enquire.commission.create',
    ARTWORK_ENQUIRE_CREATE: 'enquire.art.create',
    CMS_ENQUIRE_CREATE: 'enquire.cms.create',
  };

  /**
   * Redux Middleware
   *
   * @param store
   */
  // eslint-disable-next-line no-unused-vars
  static middleware = (store: Object) => (next: Function) => (action: Object) => {
    switch (action.type) {
      case EVENT_ACTIONS.ME_UPDATED: {
        const { user, visitor } = (action && action.payload && action.payload.payload) || {};

        if (visitor || user) {
          RaEventsService.identify(
            user ? 'user' : 'visitor',
            user ? user.id : (visitor && visitor.id) || null,
          );
        }

        break;
      }
      case EVENT_ACTIONS.ARTIST_COMMISSION_SUBMIT:
        MiddlewareEvent.enquireFormSubmit(
          MiddlewareEvent.NAMES.COMMISSION_CREATE,
          'commission',
          action && action.payload,
        );
        break;
      case EVENT_ACTIONS.ARTWORK_ENQUIRE_SUBMIT:
        MiddlewareEvent.enquireFormSubmit(
          MiddlewareEvent.NAMES.ARTWORK_ENQUIRE_CREATE,
          'artworkEnquire',
          action && action.payload,
        );
        break;
      case EVENT_ACTIONS.CMS_ENQUIRE_SUBMIT:
        MiddlewareEvent.enquireFormSubmit(
          MiddlewareEvent.NAMES.CMS_ENQUIRE_CREATE,
          'cmsEnquire',
          action && action.payload,
        );
        break;
      default:
        break;
    }
    next(action);
  };

  /**
   * enquireFormSubmit
   *
   * @param {string} eventName
   * @param {string} eventField
   * @param {Object} action
   */
  static enquireFormSubmit(eventName: string, eventField: string = 'enquire', action: Object = {}) {
    if (!action || !action.payload || !eventName) {
      return;
    }

    const { artwork, artist, storeCode } = action.payload;
    const resources = [];
    const additionalPayload = {};

    // Add artwork resource
    if (artwork && storeCode) {
      resources.push({
        type: 'art',
        fields: { id: artwork.id, store: storeCode },
      });
    }

    // Add artist resource
    if (artist) {
      resources.push({
        type: 'artist',
        fields: { id: artist.id },
      });

      additionalPayload.artistId = artist.id;
      additionalPayload.artistName = artist.name;
    }

    RaEventsService.track(
      eventName,
      {
        [eventField]: {
          ...action.payload,
          ...additionalPayload,
          ...(storeCode ? { currency: CONFIG_APP.i18n.currency.codes[storeCode] } : {}),
        },
      },
      resources && resources.length ? resources : null,
    );
  }
}
