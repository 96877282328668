// @flow

export const NOTFOUND_ACTIONS = {
  UPDATE_PROPERTY: 'NOTFOUND_UPDATE_PROPERTY',
};

export const NOTFOUND_STATE_KEYS = {
  ART: 'art',
  ARTIST: 'artist',
};

export type ActionType = {
  type: string,
  payload: Object,
};

// Action Creators
export function notFoundUpdate(property: string, value: any): ActionType {
  return {
    type: NOTFOUND_ACTIONS.UPDATE_PROPERTY,
    payload: { property, value },
  };
}
