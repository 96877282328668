// @flow

import { Component } from 'react';
import { connect } from 'react-redux';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { ErrorService, type ErrorPayloadType } from 'shared_services/riseart/errors/ErrorService';
import { LocationManager } from 'shared_services/riseart/url/Location';

type Props = {
  onError?: Function,
  errorRender: Function,
  children: any,
  dispatch?: Function,
};

type State = {
  error: ?ErrorPayloadType,
  pathname: ?string,
};

/**
 * ErrorBoundaryComponent
 */
class ErrorBoundaryComponent extends Component<Props, State> {
  /**
   * constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { error: null, pathname: null };
  }

  /**
   * componentDidCatch
   *
   * @param {Error} error
   * @param {Object} info
   */
  componentDidCatch(error: Error, info: Object) {
    // Check if the error is triggered before the application is hydrated on client
    // In this case: remove the markup from the application and only render the error component
    if (!window.RiseArt.hydrated) {
      const applicationNode =
        typeof document !== 'undefined' && document.getElementById('application');

      if (applicationNode && applicationNode.firstChild) {
        while (applicationNode.firstChild) {
          applicationNode.removeChild(applicationNode.firstChild);
        }
      }
    }

    const { onError, dispatch } = this.props;

    /*
     * error.message is not passed as detail here,
     * because it will be shown to the user,
     * and usually these type of errors do not
     * have to be visible to the user
     */
    const errorAction = errorAdd(ErrorService.mapJSError(error, { additional: { info } }));

    if (typeof dispatch === 'function') {
      dispatch(errorAction);
    }

    this.setState({ error: errorAction.payload, pathname: LocationManager.get('pathname') });

    if (onError) {
      onError(errorAction.payload);
    }
  }

  /**
   * componentDidUpdate
   *
   * Check if there is a catched error from componentDidCatch and also
   * if the pathname is the same as the current one, and if not
   * then resets the error state because the error was not triggered
   * for the current requested url
   */
  componentDidUpdate() {
    const { error, pathname } = this.state;

    if (error && pathname !== LocationManager.get('pathname')) {
      this.setState({
        error: null,
        pathname: null,
      });
    }
  }

  /**
   * render
   */
  render() {
    const { errorRender } = this.props;
    const { error } = this.state;

    if (error) {
      /*
       * The error is set to silent althought the ErrorBoundary.
       * This is on purpose and exception to the whole error handling
       * in the app, because the app flow should not be controlled by
       * the ErrorBoundary, but instead it handles the error itself
       */
      return errorRender(error);
    }

    return this.props.children;
  }
}

export const ErrorBoundary = connect()(ErrorBoundaryComponent);
