// @flow

import {
  init as initBrowser,
  configureScope as configureScopeBrowser,
  withScope as withScopeBrowser,
  captureException as captureExceptionBrowser,
  captureMessage as captureMessageBrowser,
} from '@sentry/browser';

export const SentryBrowser = {
  init: initBrowser,
  withScope: withScopeBrowser,
  captureException: captureExceptionBrowser,
  captureMessage: captureMessageBrowser,
  configureScope: configureScopeBrowser,
};
