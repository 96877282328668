// @flow

import React, { Component } from 'react';
import { ErrorPage } from 'shared_components/pages/error/Error';
import { withErrorState } from 'shared_hocs/errors/withErrorState';
import { type ErrorPayloadType } from 'shared_services/riseart/errors/ErrorService';
import { errors as ERRORS_ENUM } from 'Enum';

type Props = {
  errors?: Array<ErrorPayloadType>,
  children: any,
};

type State = {
  showError: boolean,
  lastError: ?ErrorPayloadType,
};

/**
 * ErrorRenderer
 */
class ErrorRenderer extends Component<Props, State> {
  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      showError: false,
      lastError: null,
    };
  }

  /**
   * getDerivedStateFromProps
   *
   * @param {Props} props
   * @param {State} state
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const { errors = [] } = props;
    const lastError = errors[errors.length - 1];

    if (
      !lastError ||
      (!state.showError && state.lastError === null && !lastError) ||
      (state.lastError && lastError && state.lastError.uid === lastError.uid)
    ) {
      return null;
    }

    return {
      showError: true,
      lastError,
    };
  }

  /**
   * shouldComponentUpdate
   */
  shouldComponentUpdate() {
    return !this.state.showError;
  }

  /**
   * render
   */
  render() {
    return !this.state.showError ? this.props.children : <ErrorPage error={this.state.lastError} />;
  }
}

export const ErrorPageRenderer = withErrorState(ErrorRenderer, (state, ownProps) => ({
  errors: state.data.filter((i) => i.handler === ERRORS_ENUM.handlers.ERROR_PAGE),
  ...ownProps,
}));
