// @flow

import get from 'lodash/get';
import { application as CONFIG_APP, store as CONFIG_STORE } from 'Config';

/**
 * selectStoreCode
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectStoreCode(state: Object): ?string {
  const authState = state[CONFIG_STORE.keys.auth];

  return (
    get(authState, 'data.payload.store') ||
    (authState.status.error && CONFIG_APP.store.default) ||
    null
  );
}
