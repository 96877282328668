// @flow

import { NOTFOUND_ACTIONS } from 'shared_services/redux/actions/container';
import { NOTFOUND_STATE_KEYS } from 'shared_services/redux/actions/application/notFound';

const DEFAULT_STATE = { [NOTFOUND_STATE_KEYS.ART]: null, [NOTFOUND_STATE_KEYS.ARTIST]: null };

/**
 * notFoundReducer
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function notFoundReducer(state: Object = DEFAULT_STATE, action: Object): Object {
  switch (action.type) {
    case NOTFOUND_ACTIONS.UPDATE_PROPERTY:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    default:
      return { ...state };
  }
}
