import cloneDeep from 'lodash/cloneDeep';
import { MARKETING_ACTIONS } from 'shared_services/redux/actions/container';
import { DEFAULT_STORE_STATE } from 'shared_services/redux/defaultStoreStates';

const DEFAULT_MARKETING_STATE = cloneDeep(DEFAULT_STORE_STATE);

/**
 * marketingReducer
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} New Marketing state
 */
export function marketingReducer(state = DEFAULT_MARKETING_STATE, action) {
  switch (action.type) {
    case MARKETING_ACTIONS.CAMPAIGN_READ_FETCH_LOADING:
      return {
        ...state,
        status: {
          error: false,
          loading: true,
          loaded: false,
        },
      };
    case MARKETING_ACTIONS.CAMPAIGN_READ_FETCH_DONE:
      return {
        data: { ...action.payload },
        error: null,
        status: {
          error: false,
          loading: false,
          loaded: true,
        },
      };
    case MARKETING_ACTIONS.CAMPAIGN_READ_FETCH_ERROR:
      return {
        data: { ...state.data },
        error: { ...action.payload },
        status: {
          error: true,
          loading: false,
          loaded: false,
        },
      };
    default:
      return state;
  }
}
