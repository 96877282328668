// @flow

export const ENQUIRE_ACTIONS = {
  ARTIST_COMMISSION_REQUEST: 'ARTIST_COMMISSION_REQUEST',
  ARTWORK_ENQUIRE_REQUEST: 'ARTWORK_ENQUIRE_REQUEST',
  ARTWORK_OFFER_REQUEST: 'ARTWORK_OFFER_REQUEST',
  CMS_ENQUIRE_REQUEST: 'CMS_ENQUIRE_REQUEST',
};

type ActionType = {
  type: string,
  payload: Object,
};

// Action Creators
export function artistCommissionRequestSend(payload: Object): ActionType {
  return {
    type: ENQUIRE_ACTIONS.ARTIST_COMMISSION_REQUEST,
    payload,
  };
}

export function artworkEnquireRequestSend(payload: Object): ActionType {
  return { type: ENQUIRE_ACTIONS.ARTWORK_ENQUIRE_REQUEST, payload };
}

export function artworkOfferRequestSend(payload: Object): ActionType {
  return { type: ENQUIRE_ACTIONS.ARTWORK_OFFER_REQUEST, payload };
}

export function cmsEnquireRequestSend(payload: Object): ActionType {
  return { type: ENQUIRE_ACTIONS.CMS_ENQUIRE_REQUEST, payload };
}
