// @flow

import {
  raScreenXsMax,
  raScreenSmMax,
  raScreenMd,
  raScreenMdMax,
  raScreenLgMax,
} from '@riseart/antd-provider/dist/website/variables.less';

export const ART_DIRECTION_SIZES = {
  avatar: {
    small: {
      defaultSrcDimension: 20,
      sizes: `20px`,
    },
    medium: {
      defaultSrcDimension: 40,
      sizes: `40px`,
    },
    large: {
      defaultSrcDimension: 80,
      sizes: `80px`,
    },
  },
  grid: {
    fluid: {
      defaultSrcDimension: 350,
      sizes: `(max-width: ${raScreenXsMax}) 350px, (max-width: ${raScreenMdMax}) 320px, 310px`,
    },
    flat4Col: {
      defaultSrcDimension: 550,
      sizes: `(max-width: ${raScreenXsMax}) 550px, (max-width: ${raScreenSmMax}) 340px, (max-width: ${raScreenMdMax}) 300px, 320px`,
    },
    tilesLarge: {
      defaultSrcDimension: 360,
      sizes: `(max-width: ${raScreenXsMax}) 300px, 360px`,
    },
    smallVariant1: {
      defaultSrcDimension: 280,
      sizes: `(max-width: ${raScreenXsMax}) 300px, (max-width: ${raScreenSmMax}) 192px, (max-width: ${raScreenMdMax}) 256px, 190px`,
    },
    smallVariant2: {
      defaultSrcDimension: 280,
      sizes: `(max-width: ${raScreenXsMax}) 300px, (max-width: ${raScreenSmMax}) 127px, (max-width: ${raScreenMdMax}) 170px, 240px`,
    },
    cmsEditorialTwoCol: {
      defaultSrcDimension: 540,
      sizes: `(max-width: ${raScreenXsMax}) 540px, (max-width: ${raScreenSmMax}) 329px, (max-width: ${raScreenMdMax}) 446px, 540px`,
    },
    cmsEditorialThreeCol: {
      defaultSrcDimension: 330,
      sizes: `(max-width: ${raScreenXsMax}) 375px, (max-width: ${raScreenSmMax}) 209px, (max-width: ${raScreenMdMax}) 284px, 375px`,
    },
  },
  slider: {
    gridLarge: {
      defaultSrcDimension: 350,
      sizes: `(max-width: ${raScreenSmMax}) 350px, (max-width: ${raScreenMdMax}) 315px, 270px`,
    },
    gridLargeWithPadding: {
      defaultSrcDimension: 280,
      sizes: `(max-width: ${raScreenSmMax}) 300px, (max-width: ${raScreenMdMax}) 261px, 220px`,
    },
    gridCommon: {
      defaultSrcDimension: 350,
      sizes: `(max-width: ${raScreenXsMax}) 340px, (max-width: ${raScreenSmMax}) 226px, (max-width: ${raScreenMdMax}) 229px, 212px`,
    },
    gridFixedHeight300: {
      fixedBy: 'height',
      defaultSrcDimension: 600,
      sizes: `(max-width: ${raScreenMdMax}) 275px, 300px`,
    },
    gridFixedHeight450: {
      fixedBy: 'height',
      defaultSrcDimension: 600,
      sizes: `(max-width: ${raScreenXsMax}) 300px, (max-width: ${raScreenMdMax}) 360px, 450px`,
    },
  },
  list: {
    common: {
      defaultSrcDimension: 600,
      sizes: `(max-width: ${raScreenXsMax}) 350px, (max-width: ${raScreenMdMax}) 450px, 600px`,
    },
  },
  art: {
    imageActionMain: {
      defaultSrcDimension: 700,
      sizes: `(max-width: ${raScreenXsMax}) 579px, (max-width: ${raScreenSmMax}) 717px, (max-width: ${raScreenMdMax}) 528px, 600px`,
    },
    imageActionSecondary: {
      defaultSrcDimension: 700,
      sizes: `(max-width: ${raScreenXsMax}) 579px, (max-width: ${raScreenSmMax}) 717px, (max-width: ${raScreenMdMax}) 528px, 295px`,
    },
    imageViewInRoom: {
      defaultSrcDimension: 700,
      sizes: `(max-width: ${raScreenXsMax}) 559px, 800px`,
    },
    imageTestimonials: {
      sizes: '140px',
    },
  },
  viewInRoom: {
    scaled: {
      defaultSrcDimension: 1120,
      sizes: `(max-width: ${raScreenXsMax}) 559px, (max-width: ${raScreenSmMax}) 717px, (max-width: ${raScreenMdMax}) 973px, (max-width: ${raScreenLgMax}) 2240px, 2240px`,
    },
  },
  gallery: {
    fullWidth: {
      defaultSrcDimension: 1120,
      sizes: `(max-width: ${raScreenXsMax}) 559px, (max-width: ${raScreenSmMax}) 717px, (max-width: ${raScreenMdMax}) 973px, (max-width: ${raScreenLgMax}) 1029px, 1120px`,
    },
    fullWidthThumbnail: {
      defaultSrcDimension: 140,
      sizes: `(max-width: ${raScreenXsMax}) 66px, 66px`,
    },
    zoomed: {
      defaultSrcDimension: 1120,
      sizes: `(max-width: ${raScreenXsMax}) 700px, 2240px`,
    },
  },
  banner: {
    fullWidth: {
      defaultSrcDimension: 1440,
      sizes: `(max-width: ${raScreenXsMax}) 450px, (max-width: ${raScreenMdMax}) 800px, 1440px`,
    },
    page: {
      defaultSrcDimension: 720,
      sizes: `(max-width: ${raScreenXsMax}) 600px, (max-width: ${raScreenMdMax}) 720px, 720px`,
    },
  },
  quiz: {
    carousel: {
      defaultSrcDimension: 900,
      sizes: `(max-width: ${raScreenXsMax}) 400px, (max-width: ${raScreenMdMax}) 800px, 900px`,
    },
    questions: {
      defaultSrcDimension: 960,
      sizes: `(max-width: ${raScreenXsMax}) 580px, (max-width: ${raScreenMdMax}) 960px, 580px`,
    },
  },
  cart: {
    item: {
      defaultSrcDimension: 350,
      sizes: `(max-width: ${raScreenXsMax}) 350px, 135px`,
    },
    basketSummary: {
      defaultSrcDimension: 140,
      sizes: `(max-width: ${raScreenXsMax}) 65px, 135px`,
    },
  },
  blog: {
    gridTwoCol: {
      defaultSrcDimension: 500,
      sizes: `(max-width: ${raScreenXsMax}) 196px, (max-width: ${raScreenSmMax}) 115px, (max-width: ${raScreenMdMax}) 156px, 183px`,
    },
    singleCardFeatured: {
      defaultSrcDimension: 500,
      sizes: `(max-width: ${raScreenXsMax}) 500px, (max-width: ${raScreenSmMax}) 323px, (max-width: ${raScreenMdMax}) 500px, 500px`,
    },
    singleCardAdditional: {
      defaultSrcDimension: 500,
      sizes: `(max-width: ${raScreenXsMax}) 196px, (max-width: ${raScreenSmMax}) 323px, (max-width: ${raScreenMdMax}) 500px, 500px`,
    },
    article: {
      defaultSrcDimension: 500,
      sizes: `(max-width: ${raScreenXsMax}) 374px, (max-width: ${raScreenSmMax}) 330px, (max-width: ${raScreenMdMax}) 223px, 250px`,
    },
  },
  activity: {
    gridTwoCol: {
      defaultSrcDimension: 750,
      sizes: `(max-width: ${raScreenXsMax}) 375px, (max-width: ${raScreenSmMax}) 600px, (max-width: ${raScreenMdMax}) 350px, 655px`,
    },
  },
  artist: {
    listArt: {
      defaultSrcDimension: 140,
      sizes: `(max-width: ${raScreenXsMax}) 115px, 140px`,
    },
    profileMainBanner: {
      defaultSrcDimension: 1100,
      sizes: `(max-width: ${raScreenXsMax}) 450px, (max-width: ${raScreenMd}) 768px, (max-width: ${raScreenMdMax}) 900px, 1075px`,
    },
    profileSecondaryBanner: {
      defaultSrcDimension: 1600,
      sizes: `(max-width: ${raScreenXsMax}) 450px, (max-width: ${raScreenMdMax}) 800px, 1440px`,
    },
    profileCommentImage: {
      defaultSrcDimension: 600,
      sizes: `(max-width: ${raScreenXsMax}) 450px, (max-width: ${raScreenMdMax}) 450px, 600px`,
    },
    profileFeedRight: {
      defaultSrcDimension: 750,
      sizes: `(max-width: ${raScreenXsMax}) 375px, (max-width: ${raScreenSmMax}) 600px, (max-width: ${raScreenMdMax}) 375px, 655px`,
    },
    profileFeedLeft: {
      defaultSrcDimension: 750,
      sizes: `(max-width: ${raScreenXsMax}) 375px, (max-width: ${raScreenSmMax}) 600px, (max-width: ${raScreenMdMax}) 750px, 655px`,
    },
    feedList: {
      defaultSrcDimension: 750,
      sizes: `(max-width: ${raScreenXsMax}) 375px, (max-width: ${raScreenSmMax}) 600px, (max-width: ${raScreenMdMax}) 350px, 655px`,
    },
    eventCol1: {
      defaultSrcDimension: 550,
      sizes: `(max-width: ${raScreenXsMax}) 374px, (max-width: ${raScreenSmMax}) 717px, (max-width: ${raScreenMdMax}) 375px, 550px`,
    },
    eventCol2: {
      defaultSrcDimension: 550,
      sizes: `(max-width: ${raScreenXsMax}) 374px, (max-width: ${raScreenSmMax}) 329px, (max-width: ${raScreenMdMax}) 344px, 504px`,
    },
    eventCol3: {
      defaultSrcDimension: 550,
      sizes: `(max-width: ${raScreenXsMax}) 374px, (max-width: ${raScreenSmMax}) 218px, (max-width: ${raScreenMdMax}) 303px, 345px`,
    },
    article: {
      defaultSrcDimension: 500,
      sizes: `(max-width: ${raScreenXsMax}) 374px, (max-width: ${raScreenSmMax}) 330px, (max-width: ${raScreenMdMax}) 223px, 250px`,
    },
  },
  offer: {
    details: {
      defaultSrcDimension: 350,
      sizes: `(max-width: ${raScreenXsMax}) 350px, 270px`,
    },
    list: {
      defaultSrcDimension: 350,
      sizes: `(max-width: ${raScreenXsMax}) 350px, 135px`,
    },
  },
};
