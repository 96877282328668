// @flow

import React from 'react';
import type { Node } from 'react';
import { LOCALE_COMPONENTS } from 'shared_locales/index';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/RouteConfigContext';

type Props = {
  dataKey: string,
  locale?: string,
  children: Function,
};

/**
 * I18nComponentsLoader
 *
 * @param {Props} props
 * @returns {Node}
 */
export const I18nComponentsLoader = ({ dataKey, locale, children }: Props): Node => {
  const getLocaleComponent = (dataKey: string, locale: string) => {
    const LocaleComponent = LOCALE_COMPONENTS[dataKey] && LOCALE_COMPONENTS[dataKey][locale];

    if (!LocaleComponent) {
      throw new Error(
        `[I18nComponentsLoader] Component for '${dataKey}' with locale '${locale}' is not implemented yet.`,
      );
    }

    return LocaleComponent;
  };

  if (locale) {
    const Component = getLocaleComponent(dataKey, locale);

    return <Component>{children}</Component>;
  }

  return (
    <RouteConfigContext.Consumer>
      {({ currentLocale: { name } }) => {
        const Component = getLocaleComponent(dataKey, name);

        return <Component>{children}</Component>;
      }}
    </RouteConfigContext.Consumer>
  );
};
