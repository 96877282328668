import { RiseartLogger } from 'shared_services/riseart/Logger';

/**
 * Logger middleware for log exception and state
 */
export class MiddlewareLogger {
  /**
   * Redux Middleware
   *
   * @param store
   */
  static middleware = (store) => (next) => (action) => {
    try {
      return next(action);
    } catch (Exception) {
      RiseartLogger.exception(Exception, {
        action,
        state: store.getState(),
      });
      throw Exception;
    }
  };
}
