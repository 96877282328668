// @flow

import trimEnd from 'lodash/trimEnd';
import {
  version as CONFIG_VERSION,
  environment as CONFIG_ENV,
  graphql as CONFIG_GQL,
  application as CONFIG_APP,
  libs as CONFIG_LIBS,
} from 'Config';
import { MESSAGE_TYPES, RiseartLogger } from 'shared_services/riseart/Logger';
import configureStore from 'shared_services/redux/store';
import rootSaga from 'client_services/redux/saga/container';
import { Client as ApolloClient } from 'shared_services/apollo/client';
import { ENVIRONMENTS } from 'shared_models/Environment';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { GTMService } from 'shared_services/riseart/GTM';
import { SentryBrowser } from 'shared_services/riseart/sentry/Browser';
import { RaEventsService } from 'shared_services/riseart/RaEvents';
import { NavigatorService } from 'shared_services/riseart/Navigator';
import { LocationManager } from 'shared_services/riseart/url/Location';

// Error message type
const { ERROR } = MESSAGE_TYPES;

/**
 * setupEnvironment
 *
 * @param { Object } config
 * @param { Object } store
 */
const setupEnvironment = (environment: string, store: Object) => {
  switch (environment) {
    case ENVIRONMENTS.DEVELOPMENT:
      // Expose store for development purposes
      window.store = store;
      break;

    case ENVIRONMENTS.TEST:
    case ENVIRONMENTS.PRODUCTION:
      break;

    default:
      RiseartLogger.message({
        message: `Unsupported environment '${environment}' supplied during application setup`,
        level: ERROR,
      });
      break;
  }
};

/**
 * setGlobalInfo: sets general environment and build information
 *
 * @param {string} version
 * @param {string} environment
 * @param {string} graphqlEndpoint
 * @param {string} hash
 */
const setGlobalInfo = (version, environment, graphqlEndpoint, hash) => {
  window.RiseArt = window.RiseArt || {};
  window.RiseArt.applicationInfo = {
    version,
    environment,
    graphql: graphqlEndpoint,
    hash,
  };
};

/**
 * setupConsole: display rise art branding and build information in console
 *
 * @param {string} environmentLoggerScript
 */
const setupConsole = (environmentLoggerScript: string) => {
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.async = true;
  element.src = `${trimEnd(process.env.PUBLIC_URL, '/')}${environmentLoggerScript}`;

  const tag = document.getElementsByTagName('script')[0];
  tag.parentNode && tag.parentNode.insertBefore(element, tag);
};

/**
 * setupFacebook
 *
 * @param {Object} fbConfig
 */
const setupFacebook = (fbConfig: Object) => {
  const facebookId = fbConfig.tagId;
  const script = fbConfig.scriptTag;
  const fjs = document.getElementsByTagName(script)[0];
  if (document.getElementById(facebookId)) return;
  const js: Object = document.createElement(script);
  js.id = facebookId;
  js.async = false;
  js.defer = true;
  js.src = fbConfig.source;
  fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: fbConfig.appId,
      cookie: true,
      xfbml: true,
      version: fbConfig.sdkVersion,
    });
  };
};

/**
 * setupGoogle
 *
 * @param {Object} googleConfig
 */
const setupGoogle = (googleConfig: Object) => {
  // Set global key for callback function
  window[googleConfig.callbackName] = null;

  window.onGoogleLibraryLoad = () => {
    window.google.accounts.id.initialize({
      client_id: googleConfig.clientId,
      callback: (data) => {
        if (window && window[googleConfig.callbackName]) {
          window[googleConfig.callbackName](data);
        }
      },
    });
    window.googleAccountsIdInitialized = true;
  };

  const e: Object = document.createElement(googleConfig.scriptTag);
  e.type = googleConfig.jsType;
  e.async = false;
  e.defer = true;
  e.id = googleConfig.tagId;
  e.src = googleConfig.source;
  const t = document.getElementsByTagName(googleConfig.scriptTag)[0];
  t.parentNode && t.parentNode.insertBefore(e, t);
};

/**
 * setupRaEvents
 *
 * @param {Object} raEventsConfig
 * @param {string} environment
 */
const setupRaEvents = (raEventsConfig: Object, environment: string) => {
  const { host, version, file, namespace, apiKey, debug, source } = raEventsConfig;

  // eslint-disable-next-line
  (function (w, d, s, n) {
    w[n] = w[n] || [];
    // eslint-disable-next-line
    var f = d.getElementsByTagName('script')[0],
      j = d.createElement('script'),
      // eslint-disable-next-line
      dn = n != 'raev' ? '?namespace=' + n : '';
    j.id = 'ra-events-lib';
    j.defer = true;
    j.src = s + dn;
    f.parentNode.insertBefore(j, f);
  })(window, document, `${host}${version ? `/${version}` : ''}/${file}`, namespace);

  window.raev.push(['config', apiKey, environment, debug, source]);

  // Setup RaEventsService
  RaEventsService.init(
    window.RiseArt &&
      window.RiseArt.initialRaEventsState &&
      JSON.parse(decodeURIComponent(window.RiseArt.initialRaEventsState)),
  );

  if (window.RiseArt && window.RiseArt.initialRaEventsState) {
    delete window.RiseArt.initialRaEventsState;
  }
};

/**
 * initClientEnvironment
 *
 * @returns {Object}
 */
export const initClientEnvironment = () => {
  // initial device width detected by user agent from server
  const initialDeviceWidth =
    (window.RiseArt && window.RiseArt.initialDeviceWidth) || window.innerWidth;

  if (window.RiseArt && window.RiseArt.initialDeviceWidth) {
    delete window.RiseArt.initialDeviceWidth;
  }

  // Configure redux store and run the sagas
  const initialReduxState =
    window.RiseArt &&
    window.RiseArt.initialReduxState &&
    JSON.parse(decodeURIComponent(window.RiseArt.initialReduxState));
  const reduxStore = configureStore(initialReduxState || {});
  reduxStore.runSaga(rootSaga);

  if (window.RiseArt && window.RiseArt.initialReduxState) {
    delete window.RiseArt.initialReduxState;
  }

  // Create and configure apollo GraphQL client
  const apolloClient = ApolloClient.init(reduxStore);

  // Configure NavigatorService
  NavigatorService.config({
    userAgent: window.navigator.userAgent,
    language:
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage,
  });

  // Configure LocationService
  // window.location is passes by reference because it is an object,
  // so there is no need to update it each time when client routing
  LocationManager.config(window.location);

  // Configure logger
  RiseartLogger.config(SentryBrowser).init(reduxStore);

  // Configure cookie library
  Cookies.config();

  // Setup GTMService dataLayer
  GTMService.config(
    window.RiseArt &&
      window.RiseArt.initialGTMDataLayer &&
      JSON.parse(decodeURIComponent(window.RiseArt.initialGTMDataLayer)),
  );

  if (window.RiseArt && window.RiseArt.initialGTMDataLayer) {
    delete window.RiseArt.initialGTMDataLayer;
  }

  // Initial client side environment setup
  setGlobalInfo(CONFIG_VERSION.number, CONFIG_ENV, CONFIG_GQL.endpoint, CONFIG_VERSION.hash);
  setupConsole(CONFIG_APP.environmentLoggerScript);
  setupEnvironment(CONFIG_ENV, reduxStore);

  // Setup external libraries and clients
  setupFacebook(CONFIG_LIBS.facebook);
  setupGoogle(CONFIG_LIBS.google);
  setupRaEvents(CONFIG_LIBS.events, CONFIG_ENV);

  return { reduxStore, apolloClient, initialDeviceWidth, initialReduxState };
};
