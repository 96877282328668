// @flow

import React from 'react';
import type { Node } from 'react';
import { meta as META_ENUM } from 'Enum';
import { META_SUBSCRIBER_NAME } from 'shared_services/riseart/meta/Meta';
import { ErrorDetails } from 'shared_components/common/error/Details';
import { MetaProvider } from 'shared_data/providers/meta/Meta';
import { Status } from 'shared_components/routers/Status';
import { type ErrorMessageType } from 'shared_services/riseart/errors/ErrorService';

type Props = {
  children?: any,
  translate?: boolean,
  error: ErrorMessageType,
};

/**
 * ErrorPage
 */
export function ErrorPage({ children = null, error, translate = true }: Props): Node {
  const { ATTRIBUTE_CONTENT, METATYPE } = META_ENUM;

  return (
    <React.Fragment>
      <MetaProvider
        metaSubscriber={META_SUBSCRIBER_NAME.ERROR_META}
        meta={{
          ...(translate
            ? {
                [METATYPE.META_TITLE]: 'meta.error.title',
                [METATYPE.META_KEYWORDS]: 'meta.error.keywords',
                [METATYPE.META_DESCRIPTION]: 'meta.error.description',
              }
            : {}),
          [METATYPE.META_STATUS]: error.status,
          [METATYPE.META_ROBOTS]: `${ATTRIBUTE_CONTENT.NO_INDEX}, ${ATTRIBUTE_CONTENT.NO_FOLLOW}`,
        }}
      />
      <Status code={error.status}>
        <ErrorDetails error={error} translate={translate}>
          {children}
        </ErrorDetails>
      </Status>
    </React.Fragment>
  );
}
