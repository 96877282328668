// @flow

import { store as CONFIG_STORE } from 'Config';

/**
 * redirectsSelector
 *
 * @param {Object} state
 * @returns {?Object}
 */
export function redirectsSelector(state: Object): Object {
  const app = state[CONFIG_STORE.keys.application];
  return (app && app.redirects) || null;
}
