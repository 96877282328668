// @flow

/**
 * Action Types
 */
export const CMS_VIEW_ACTIONS = {
  CMS_VIEW_FETCH: 'CMS_VIEW_FETCH',
};

/**
 * Action Creators
 */
export function cmsViewFetch(data: Object): Object {
  return {
    type: CMS_VIEW_ACTIONS.CMS_VIEW_FETCH,
    payload: { ...data },
  };
}
