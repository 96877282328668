// @flow

import React from 'react';

/**
 * UrlTranslationsContext
 *
 * Context for translated url parameters and their values
 */
export const UrlTranslationsContext = React.createContext({
  url: null,
  query: null,
  hash: null,
  translator: null,
  translatedLocation: null,
});
