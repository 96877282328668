// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { UrlTranslationsContext } from 'shared_data/providers/url/TanslationsContext';

const HOC_DISPLAY_NAME = 'HOCTranslatedRouter';

function HOCTranslatedRouter(DecoratedComponent) {
  return class extends Component<Object, Object> {
    static displayName = HOC_DISPLAY_NAME;

    render() {
      const { location, ...props } = this.props;

      return (
        <UrlTranslationsContext.Consumer>
          {({ translatedLocation }) => {
            return <DecoratedComponent {...props} location={{ ...translatedLocation }} />;
          }}
        </UrlTranslationsContext.Consumer>
      );
    }
  };
}

export const withTranslatedRouter = (DecoratedComponent: any) =>
  withRouter(HOCTranslatedRouter(DecoratedComponent));
