// @flow

import React from 'react';
import type { Node } from 'react';
import CONFIG_REDIRECTOR from 'Redirectors';
import { HTTP_STATUS } from 'Enum';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { RedirectWithStatus } from 'shared_components/routers/RedirectWithStatus';
import {
  getLocaleConfig,
  findRedirect,
  combineQueryStringParams,
  normalizeUri,
  extractLocaleFromUrl,
} from 'shared_services/riseart/utils/RouteUtils';
import { isRoutedUrl } from 'shared_services/riseart/utils/Utils';

type Props = {
  children: Node,
};

/**
 * RedirectorComponent
 *
 * @param {Props} props
 */
export function Redirector({ children }: Props) {
  const requestUri = LocationManager.get('uri');
  const decodedRequestUri = decodeURIComponent(requestUri);
  const foundRedirect = findRedirect(normalizeUri(decodedRequestUri), CONFIG_REDIRECTOR);
  const destinationUri =
    foundRedirect && foundRedirect.redirect === true && foundRedirect.targetUri
      ? combineQueryStringParams(foundRedirect.targetUri, decodedRequestUri)
      : normalizeUri(requestUri);
  const status = (foundRedirect && foundRedirect.status) || HTTP_STATUS.MOVED_PERMANENTLY;

  if (destinationUri !== requestUri) {
    const { pathname } = LocationManager.get();

    if (
      isRoutedUrl(
        destinationUri,
        (extractLocaleFromUrl(pathname) || getLocaleConfig(true, 'isDefault') || {}).name,
      )
    ) {
      // Redirect routed by react router url
      return <RedirectWithStatus to={destinationUri} status={status} />;
    }

    // Redirect non routed url (will do a page refresh)
    LocationManager.redirect(destinationUri, { status, target: '_self' });
  }

  return children;
}
