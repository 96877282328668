// @flow

import { isAbsoluteUrl } from '@riseart/fe-utils';
import { isRoutedUrl } from 'shared_services/riseart/utils/Utils';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import type { authParamsType } from 'shared_hocs/auth/Params.js';

const DEFAULT_AUTH_REDIRECT_DELAY = 1000;
let redirectTimeoutIndex;

/**
 * getAuthForwardDestination
 *
 * @param {authParamsType} params
 * @param {Object} locale
 * @returns {string} redirect url based on forward parameter, or quizResponseId. default is the home page
 */
export function getAuthForwardDestination(
  { forward, quizResponseId }: authParamsType,
  locale: Object,
): string {
  if (forward) {
    return forward;
  }

  if (quizResponseId) {
    return UrlAssembler.byRouteKey('quizResults', { locale, search: { id: quizResponseId } });
  }

  return UrlAssembler.byRouteKey('home', { locale });
}

/**
 * authRedirect
 *
 * @param queryParameters
 * @param history
 * @param locale
 * @param redirectDelay
 */
export function authRedirect(
  queryParameters,
  history,
  locale,
  location,
  redirectDelay = DEFAULT_AUTH_REDIRECT_DELAY,
) {
  const redirectRoute =
    getAuthForwardDestination(queryParameters, locale) + ((location && location.hash) || '');
  const match = isRoutedUrl(redirectRoute);

  if (!history || !match || isAbsoluteUrl(redirectRoute)) {
    // redirectDelay is set so that the page redirect happens after a timeout
    // so that analytics data like GTM events can be send to services before redirecting
    if (LocationManager.isSSR || !redirectDelay) {
      LocationManager.redirect(redirectRoute);
      return;
    }

    clearTimeout(redirectTimeoutIndex);

    redirectTimeoutIndex = setTimeout(() => {
      LocationManager.redirect(redirectRoute);
    }, redirectDelay);
  } else {
    history.push(redirectRoute);
  }
}
