// @flow

import { fork } from 'redux-saga/effects';
import {
  graphqlAuth,
  graphqlMe,
  graphqlRefreshToken,
} from 'shared_services/redux/saga/watcher/graphql';

/**
 * Merge client watchers
 */
export default function* rootSaga(): Generator<void, void, void> {
  // GraphQL sagas
  yield fork(graphqlAuth);
  yield fork(graphqlMe);
  yield fork(graphqlRefreshToken);
}
