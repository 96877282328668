// @flow

import React from 'react';
import type { Node } from 'react';
import { Route } from 'react-router-dom';

type Props = {
  code: ?number,
  children?: ?Node,
};

/**
 * Status
 * component that uses the staticContext of React Static Router
 * to pass the status code to the server
 *
 * @param {Props} props
 */
export function Status({ code, children = null }: Props) {
  return (
    <Route
      render={({ staticContext }: Object): any => {
        if (staticContext && code && code >= 100 && code < 600) staticContext.status = code;
        return children;
      }}
    />
  );
}
