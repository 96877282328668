// @flow

import get from 'lodash/get';
import pick from 'lodash/pick';
import fetch from 'node-fetch';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  concat,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import { graphql as GRAPHQL_CONFIG, store as STORE_CONFIG, errors as ERRORS_CONFIG } from 'Config';
import { errors as ERRORS_ENUM, cart as ENUM_CART } from 'Enum';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { extractLocaleFromUrl } from 'shared_services/riseart/utils/RouteUtils';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';

// possibleTypes need to be updated whenever we use a new union or interface in queries
import { possibleTypes } from 'shared_services/apollo/possibleTypes';

const {
  NetworkError: { type: NETWORK_ERROR_TYPE },
} = ERRORS_CONFIG.errorsTypes;

const {
  levels: { ERROR: ERROR_LEVEL },
} = ERRORS_ENUM;

/**
 * Client
 */
export const Client: Object = {
  /**
   * clientInstance
   */
  clientInstance: null,

  /**
   * init
   *
   * @param {Object} reduxStore
   * @param {boolean} isSSR
   * @param {Object} options
   * @returns {ApolloClient<any>}
   */
  init: (reduxStore: Object, isSSR: boolean = false, options: Object = {}): ApolloClient<any> => {
    // In SSR mode we should create a new client or store instance
    // for each request as described in Apollo Client documentation
    if (Client.clientInstance && !isSSR) {
      return Client.clientInstance;
    }

    // Core client HTTP link
    const httpLink = new HttpLink({
      uri: GRAPHQL_CONFIG.endpoint,
      fetch,
      headers: options.headers || {},
    });

    // Set request headers for each request (API key, JWT token, locale)
    const headersLink = new ApolloLink((operation, forward) => {
      try {
        const reduxState = reduxStore.getState();
        const token = get(reduxState, `${STORE_CONFIG.keys.auth}.data.token`);
        const locale =
          get(localeSelector(reduxState), 'name') ||
          get(extractLocaleFromUrl(LocationManager.get('pathname')), 'name');

        operation.setContext({
          headers: {
            'x-api-key': GRAPHQL_CONFIG.apiKey,
            // $FlowFixMe
            ...(locale ? { 'accept-language': locale } : {}),
            ...(token ? { authorization: `Bearer ${token}` } : {}),
          },
        });
      } catch (error) {
        reduxStore.dispatch(errorAdd(ErrorService.mapJSError(error)));
      }

      return forward(operation);
    });

    // Log each response to the core logger
    const logLink = new ApolloLink((operation, forward) => {
      const { customOptions: { errorSuppressFromResponse = false } = {} } = operation.getContext();
      return forward(operation).map((response) => {
        // eslint-disable-next-line
        try {
          Client.logResponse(operation.query.definitions, response);
          // Suppress gql errors from SSR queries marked with errorSuppressFromResponse
          // since we handle them in the application using the error service
          // instead of allowing Apollo to bubble up the error on SSR
          return isSSR && errorSuppressFromResponse && response.errors
            ? { ...response, errors: null }
            : response;
        } catch (error) {
          reduxStore.dispatch(errorAdd(ErrorService.mapJSError(error)));
          return response;
        }
      });
    });

    // Handle and log GQL errors
    const errorLink = new ErrorLink(({ graphQLErrors, networkError, operation }) => {
      try {
        const {
          customOptions: {
            errorHandler,
            errorFilter = (i: Array<Object>): Array<Object> => i,
            error: errorOptions = {},
          } = {},
        } = operation.getContext();
        const inputData = pick(operation, ['operationName', 'query', 'variables', 'extensions']);
        const filteredGraphQLErrors = errorFilter(graphQLErrors);

        // ErrorHandler provided in operation context
        // for custom error handling logic
        if (errorHandler) {
          const { shouldOverwriteDefaultHandler } = errorHandler({
            graphQLErrors: filteredGraphQLErrors,
            networkError,
          });
          if (shouldOverwriteDefaultHandler === true) {
            return;
          }
        }

        if (filteredGraphQLErrors) {
          // Custom options are passed from Query/Mutation components
          // using the context prop with data like { customOptions: { errorOptions } }
          filteredGraphQLErrors.forEach((err) => {
            const errorPayload = {
              ...ErrorService.mapGraphqlError({ ...err, inputData }),
              ...errorOptions,
            };

            reduxStore.dispatch(errorAdd(errorPayload));
          });
        }

        if (networkError) {
          reduxStore.dispatch(
            errorAdd(
              ErrorService.mapNotification({ type: NETWORK_ERROR_TYPE, level: ERROR_LEVEL }),
            ),
          );
        }
      } catch (error) {
        reduxStore.dispatch(errorAdd(ErrorService.mapJSError(error)));
      }
    });

    // Create and restore inital query cache
    const cache = new InMemoryCache({
      possibleTypes,
      dataIdFromObject: (object) => {
        // eslint-disable-next-line
        switch (object.__typename) {
          case 'ArtFlat':
            return `${object.id}/${object.storeCode}${
              object.collectionId ? `/${object.collectionId}` : ''
            }`;
          case 'UserPrivacySetting':
            return object.id || `${object.userId}/${object.category}/${object.name}`;
          case 'Cart':
            return object.rental === true ? ENUM_CART.type.TYPE_RENT : ENUM_CART.type.TYPE_BUY;
          case 'CartItemOption':
            return `${object.cartItemId}/${object.id}`;
          case 'CartItemOptionValue':
            return `${object.cartItemId}/${object.optionId}/${object.id}`;
          case 'Article':
            return `article/${object.type}/${object.text !== null ? 'detail/' : ''}${object.id}`;
          case 'ArticleCategory':
            return `article/category/${object.type}/${
              object.description !== null ? 'detail/' : ''
            }${object.id}`;
          default:
            return defaultDataIdFromObject(object); // Fall back to default Apollo handling
        }
      },
    });
    const initialApolloState =
      !isSSR && typeof window !== 'undefined' && get(window.RiseArt, 'initialApolloState');
    if (initialApolloState) {
      cache.restore(JSON.parse(decodeURIComponent(initialApolloState)));
      delete window.RiseArt.initialApolloState;
    }

    // Create new apollo client instance
    const client: ApolloClient<any> = new ApolloClient({
      ssrMode: isSSR,
      link: concat(headersLink, logLink.concat(errorLink.concat(httpLink))),
      cache,
      ssrForceFetchDelay: isSSR ? 0 : 100,
    });

    Client.clientInstance = client;
    return client;
  },

  /**
   * query
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  query: (payload: Object): Object => Client.clientInstance.query(payload).then((result) => result),

  /**
   * mutate
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  mutate: (payload: Object): Object =>
    Client.clientInstance.mutate(payload).then((result) => result),

  /**
   * logResponse
   *
   * @param {Object} definitions
   * @param {Object} response
   * @returns {void}
   */
  logResponse: (definitions: Object, response: Object): void => {
    const definitionList = definitions
      .map((definition) => {
        const { kind, name = {}, operation } = definition;

        switch (kind) {
          case 'OperationDefinition':
            return `${operation} ${name.value}`;
          case 'FragmentDefinition':
            return `fragment ${name.value}`;
          default:
            return `unknown type ${name.value}`;
        }
      })
      .join(', ');
    RiseartLogger.groupedLog(`[GRAPHQL] Result from ${definitionList}`, response);
  },

  /**
   * getInstance
   *
   * @returns {ApolloClient} ApolloClient instance
   */
  getInstance: (): ApolloClient<any> => Client.clientInstance,

  /**
   * clearInstance
   *
   * @param {boolean} stop
   * @returns {boolean}
   */
  clearInstance: (stop: boolean = false): boolean => {
    if (Client.clientInstance) {
      if (stop) {
        Client.clientInstance.stop();
      }
      Client.clientInstance = null;
      return true;
    }
    return false;
  },
};
