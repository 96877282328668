// @flow

import React from 'react';
import { isRoleAllowedForACL } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';
import { ACLContext } from 'shared_data/providers/acl/ACLContext';

type Props = {
  profileRole: string,
  children?: React$Element<*> | null,
  render?: Function,
};

/**
 * ACLProvider
 *
 * Usage:
 * // using a render prop
 *  <ACLProvider profileRole="visitor" render={
 *      (isAllowed, resourcePermissions) => {
 *          return isAllowed ? <div>Allowed</div> : <div>Restricted</div>
 *          }
 *      }
 *  />
 *
 * // using function as children
 * // note that this is directly passed as children to context consumer
 * // and it is up to the developer to add the render logic
 *  <ACLProvider>
 *      {(resourcePermissions) => (
 *          resourcePermissions.indexOf('admin') !== -1 ? <div>Allowed</div> : <div>Restricted</div>
 *      )}
 *  </ACLProvider>
 *
 * @param {Props} props
 * @returns {React$Element<*>}
 */
export const ACLProvider = ({
  profileRole,
  render = () => {},
  children = null,
}: Props): React$Element<*> => (
  <ACLContext.Consumer>
    {typeof children === 'function'
      ? children
      : ({ roles: aclPageRoles }: Object) =>
          render(
            isRoleAllowedForACL(aclPageRoles, profileRole, CONFIG_APP.acl.rolesHierarchy),
            aclPageRoles,
          )}
  </ACLContext.Consumer>
);
