// @flow

import React from 'react';
import { ConfigProvider } from '@riseart/antd-provider';
import { AppRouter } from 'shared_components/routers/App';
import { ListFavoritesQuery } from 'shared_data/providers/queries/Favorites';
import { MarketingProvider } from 'shared_data/providers/marketing/Marketing';
import { ReferrerProvider } from 'shared_data/providers/referrer/Referrer';
import { ErrorBoundary } from 'shared_data/providers/errors/ErrorBoundary';
import { ErrorPage } from 'shared_components/pages/error/Error';

// Import core application styles
import '@riseart/antd-provider/dist/website/main.css';
import 'shared_styles/app.less';
import 'shared_styles/print.less';

/**
 * App
 *
 * @returns {React$Element<*>}
 */
export const App = (): React$Element<*> => (
  <ErrorBoundary errorRender={(error) => <ErrorPage error={error} translate={false} />}>
    <ListFavoritesQuery />
    <MarketingProvider />
    <ReferrerProvider />
    <ConfigProvider>
      <AppRouter />
    </ConfigProvider>
  </ErrorBoundary>
);
