// @flow

import { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { cookies as CONFIG_COOKIES } from 'Config';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { normalizeUri } from 'shared_services/riseart/utils/RouteUtils';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { findValidCampaign, setAffiliateCookie } from 'shared_services/riseart/utils/marketing';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectAuthToken } from 'shared_services/redux/selectors/auth';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import {
  campaignReadFetchLoading,
  campaignReadFetchError,
  campaignReadFetchDone,
  campaignMatched,
} from 'shared_services/redux/actions/marketing/campaign';

type Props = {
  token: ?string,
  store: ?string,
  locale: ?string,
  children: any,
  actionCampaignReadFetchLoading: Function,
  actionCampaignReadFetchError: Function,
  actionCampaignReadFetchDone: Function,
  actionCampaignMatched: Function,
};

/**
 * MarketingProviderComponent
 */
class MarketingProviderComponent extends Component<Props, *> {
  affiliateCookie: string | Object;

  marketingSessionCookie: string | Object;

  /**
   * constructor
   * @param {*} props
   */
  constructor(props: Props) {
    super(props);
    this.affiliateCookie = Cookies.get(CONFIG_COOKIES.marketing.name) || null;
    this.marketingSessionCookie = Cookies.get(CONFIG_COOKIES.marketingSession.name) || null;
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.fetchCampaign(this.props);
  }

  /**
   * componentDidUpdate
   * @param {Props} prevProps
   */
  componentDidUpdate(prevProps: Props) {
    // NOTE: This is only used for local development purposes when app is run without SSR
    // In all other cases the token will be present when componentDidMount is called
    // This ensures the fetchCampaign will be executed only once when the token is
    // provided from store. Once set then this will not be called anymore
    if (!prevProps.token && this.props.token) {
      this.fetchCampaign(this.props);
    }
  }

  /**
   * fetchCampaign
   *
   * @param {Props} props
   */
  fetchCampaign(props: Props) {
    if (!props.token) {
      return;
    }

    let campaign = null;

    try {
      const { pathname, search } = LocationManager.get();

      props.actionCampaignReadFetchLoading();
      campaign = findValidCampaign({
        sessionCookieCode: this.marketingSessionCookie,
        queryStringCode:
          queryString.parse(search)[LocalizedConfig.get('navigation.uri.query.params.marketing')],
        uri: normalizeUri(pathname.trim()),
        cookieCampaignId: this.affiliateCookie,
      });
      props.actionCampaignReadFetchDone(campaign);
    } catch (error) {
      props.actionCampaignReadFetchError(error);
    }

    if (campaign) {
      props.actionCampaignMatched(campaign);

      // always set the session marketing cookie when we store in redux store
      Cookies.set(
        CONFIG_COOKIES.marketingSession.name,
        campaign.code,
        CONFIG_COOKIES.marketingSession,
      );

      if (!this.affiliateCookie) {
        setAffiliateCookie(campaign, { store: props.store, locale: props.locale });
      }
    }
  }

  /**
   * render
   */
  render() {
    return this.props.children || null;
  }
}

export const MarketingProvider = connect(
  (state) => {
    return {
      token: selectAuthToken(state),
      store: selectStoreCode(state),
      locale: get(localeSelector(state), 'name'),
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        actionCampaignReadFetchLoading: campaignReadFetchLoading,
        actionCampaignReadFetchError: campaignReadFetchError,
        actionCampaignReadFetchDone: campaignReadFetchDone,
        actionCampaignMatched: campaignMatched,
      },
      dispatch,
    ),
)(MarketingProviderComponent);
