// @flow

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { App } from 'client_components/app/App';

if (typeof window !== 'undefined' && window.RiseArt && window.RiseArt.isSSR) {
  loadableReady(() => {
    hydrateRoot(document.getElementById('application'), <App />);
  });
} else {
  createRoot(document.getElementById('application')).render(<App />);
}
