// @flow

import React from 'react';
import { v4 } from 'uuid';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Url from 'url-parse';
import queryString from 'query-string';
import { matchPath } from 'react-router-dom';
import { generateUrlFromSource, find } from '@riseart/fe-utils';
import { FilterService } from '@riseart/filter-service';
import { router as CONFIG_ROUTER, cdn as CONFIG_CDN, application as CONFIG_APP } from 'Config';
import { filter as FILTER_ENUM, mailingList as ENUM_MAILING_LIST } from 'Enum';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { pathToParams } from 'shared_services/riseart/utils/FilterRouteUtils';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';
import { getLocaleConfig } from 'shared_services/riseart/utils/RouteUtils';

/**
 * areDifferent
 *
 * @param {any} objectOne
 * @param {any} objectTwo
 * @returns {boolean}
 */
export function areDifferent(objectOne: any, objectTwo: any): boolean {
  return JSON.stringify(objectOne) !== JSON.stringify(objectTwo);
}

/**
 * getRandomObjectFromArray
 *
 * @param {Array<any>} arrayItems
 * @returns {any}
 */
export const getRandomObjectFromArray = (arrayItems: Array<any>): any => {
  if (arrayItems instanceof Array) {
    return arrayItems[Math.floor(Math.random() * arrayItems.length)];
  }
  throw new Error('getRandomObjectFromArray invalid array');
};

/**
 * ParseNumberToLocaleString
 *
 * @param {number | string} value
 * @param {string} locale
 * @returns {string}
 */
export function parseNumberToLocaleString(
  value: number | string = 0,
  locale?: string = (getLocaleConfig(true, 'isDefault') || {}).name,
): string | number {
  const parsedValue = parseInt(value, 10);

  return !Number.isNaN(parsedValue) ? parsedValue.toLocaleString(locale) : value;
}

/**
 * parseFloatToLocaleString
 *
 * @param {string | number} value
 * @param {number} fixedDigits
 * * @param {string} locale
 * @returns {string} formatted value
 */
export function parseFloatToLocaleString(
  value: string | number,
  fractionDigits: number = 2,
  localeCode?: string = (getLocaleConfig(true, 'isDefault') || {}).name,
): string | number {
  const parsedValue = parseFloat(value);

  return !Number.isNaN(parsedValue)
    ? parsedValue.toLocaleString(localeCode, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })
    : value;
}

/**
 * actionFactory
 *
 * @param {string} type
 * @param {Object} payload
 * @returns {?Object}
 */
export function actionFactory(type: string, payload: Object): ?Object {
  if (type && payload) {
    return {
      type,
      payload,
      uid: v4(),
    };
  }
}

/**
 * convertPayloadToQueryVars
 *
 * @param {?Object} payload
 * @param {?string} storeCode
 * @return {Object}
 */
export function convertPayloadToQueryVars(payload: ?Object, storeCode: ?string): Object {
  return payload
    ? {
        store: storeCode,
        ...Object.keys(payload).reduce((accumulator: Object, key: string): Object => {
          const item = payload[key];

          return { ...accumulator, [key]: isArray(item) ? item.join(',') : item };
        }, {}),
      }
    : {};
}

/**
 * Determines if the client has a retina display
 *
 * @returns {boolean}
 */
export function isRetina(): boolean {
  const { matchMedia } = typeof window !== 'undefined' ? window : {};

  if (!matchMedia) {
    return false;
  }

  const mq = matchMedia(
    'only screen and (-moz-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)',
  );

  return !!(mq && mq.matches);
}

/**
 * calculateOverlayOffset
 *
 * @param {number} notificationsHeight
 * @param {number} applicationsHeight
 * @returns {number}
 */
export function calculateOverlayOffset(
  notificationsHeight: number = 0,
  applicationsHeight: number = 0,
): number {
  // Default offset, always include notifications height (fixed position)
  let offset = notificationsHeight;
  // Add application messages offset correction
  if (applicationsHeight > 0) {
    const { pageYOffset = 0 } = typeof window !== 'undefined' ? window : {};
    if (pageYOffset > 0) {
      if (pageYOffset <= applicationsHeight) {
        offset +=
          applicationsHeight - pageYOffset > notificationsHeight
            ? applicationsHeight - pageYOffset - notificationsHeight
            : 0;
      }
    } else {
      offset += applicationsHeight;
    }
  }
  return offset;
}

/**
 * isRoutedUrl
 *
 * @param {string} url
 */
export function isRoutedUrl(url: string, locale?: string): boolean | Object {
  const origin = LocationManager.get('origin');
  const oUrl = new Url(url, origin);

  if (oUrl.origin !== origin) {
    return false;
  }

  return find(CONFIG_ROUTER, (route) => {
    const matchedExactPath = matchPath(oUrl.pathname, {
      path: route.path,
      exact: true,
      strict: false,
    });

    // if path is matched exactly by route patters, then it is assumed
    // this will always be routed through the app router
    if (matchedExactPath) {
      return true;
    }

    // at this point the matchedExactPath is false and if route.exact === true then,
    // it means that no match is found, thus we need to skip next condition checks
    if (route.exact === true) {
      return false;
    }

    // check if path with exact: false is matched.
    // if yes then if filterDomainKey is provided we should check for valid filters
    if (matchPath(oUrl.pathname, { path: route.path, exact: route.exact })) {
      if (route.filterDomainKey) {
        const filterDomain = FILTER_ENUM.domain[route.filterDomainKey];

        if (filterDomain) {
          const { search } = LocationManager.get();
          const filterDomainConfig = {
            // build the locale for the FilterService instance.
            // If this function is called outside of the I18Provider component,
            // the locale might not be set in the FilterService,
            // and it has to be provided when instantiating the class
            ...(locale ? { locale } : null),
            ...getFilterDomainConfig(filterDomain),
          };
          const FilterServiceInstance = new FilterService(filterDomainConfig);
          const filters = FilterServiceInstance.route(
            pathToParams(oUrl.pathname),
            queryString.parse(search),
          );

          return !isEmpty(filters.filters);
        }

        return false;
      }

      return true;
    }

    return false;
  });
}

/**
 * generateContentUrl
 * @param {string} key
 * @param {string} extension
 * @param {string} cdnHost
 * @returns {string} combined content url
 */
export function generateContentUrl(
  key: string,
  extension: string,
  cdnHost: string = CONFIG_CDN.fileSystem.host,
): string {
  return generateUrlFromSource(key, extension, cdnHost);
}

/**
 * formatCurrency
 *
 * @param {number | string} amount
 * @param {string} storeCode
 * @param {string} currencySymbol
 * @param {{ precision: number, hasSpaceBetweenCurrency?: boolean }} options
 * @returns {string}
 */
export function formatCurrency(
  amount: number | string,
  storeCode?: string = CONFIG_APP.i18n.currency.signs.uk,
  currencySymbol?: ?string,
  options: {
    locale?: string,
    precision?: number,
    hasSpaceBetweenCurrency?: boolean,
    hideCurrencyCode?: boolean,
  } = {},
): string {
  const { hasSpaceBetweenCurrency, precision, locale, hideCurrencyCode } = {
    precision: 2,
    hideCurrencyCode: false,
    hasSpaceBetweenCurrency: undefined,
    ...options,
    locale: (options && options.locale) || (getLocaleConfig(true, 'isDefault') || {}).name,
  };
  const localeFormat = {
    'fr-FR': (currency, value) =>
      `${value}${hasSpaceBetweenCurrency === false ? '' : ' '}${currency}`,
    'de-DE': (currency, value) => `${currency}${hasSpaceBetweenCurrency ? ' ' : ''}${value}`,
    'en-GB': (currency, value) => `${currency}${hasSpaceBetweenCurrency ? ' ' : ''}${value} `,
  };
  const currency =
    !hideCurrencyCode &&
    (currencySymbol ||
      CONFIG_APP.i18n.currency.signs[storeCode] ||
      CONFIG_APP.i18n.currency.signs.uk);

  const value =
    precision > 0
      ? parseFloatToLocaleString(amount, precision, locale)
      : parseNumberToLocaleString(amount, locale);

  return localeFormat[locale](!hideCurrencyCode ? currency : '', value);
}

/**
 * isSubscribed
 *
 * @param {Array<Object>} emailSubscriptions
 * @param {string} subscriptionType
 * @returns {boolean}
 */
export function isSubscribed(
  emailSubscriptions: Array<Object>,
  subscriptionType: string = ENUM_MAILING_LIST.type.TYPE_NEWSLETTER,
): boolean {
  return (
    emailSubscriptions &&
    emailSubscriptions.some(({ type, status }) => type === subscriptionType && status === 1)
  );
}

/**
 * handlePrintClick
 */
export function handlePrintClick() {
  window.print();
}

/**
 * isPromise
 *
 * @param {any} promise
 * @returns {boolean}
 */
export function isPromise(promise: any): boolean {
  return !!promise && typeof promise.then === 'function';
}

/**
 * convertUTCToLocal
 * Converts a UTC time date to local time, based on the timezone offset
 *
 * @param {string | Date} date
 * @returns {Date}
 */
export function convertUTCToLocal(date: string | Date): Date {
  return new Date(new Date(date).getTime() + new Date().getTimezoneOffset() * 60 * 1000);
}

/**
 * getTimeDiffLabel
 *
 * @param {?Object} startDate
 * @param {string} endDate
 * @param {Function} formatMessage
 * @returns {string} Difference between two dates
 */
export function getTimeDiffLabel(
  startDate: ?Object = null,
  endDate: string,
  formatMessage: Function,
): string {
  const currentDate = startDate || new Date();
  const pastDate = new Date(endDate);
  const currentUnixtime = currentDate.getTime();
  const pastUnixtime = pastDate.getTime();

  const difference = (currentUnixtime - pastUnixtime) / 1000;

  // Seconds
  if (difference < 60) {
    return formatMessage({ id: 'common.timediff.lessThanMin' });
  }

  // Minutes
  if (difference < 3600) {
    const minutes = Math.floor(difference / 60);

    return formatMessage({ id: 'common.timediff.minutes' }, { minutes });
  }

  // Hours
  if (difference < 86400) {
    const hours = Math.floor(difference / 60 / 60);

    return formatMessage({ id: 'common.timediff.hours' }, { hours });
  }

  // Days
  if (difference < 604800) {
    const days = Math.floor(difference / 60 / 60 / 24);

    return formatMessage({ id: 'common.timediff.days' }, { days });
  }

  // Weeks
  if (difference < 2628000) {
    const weeks = Math.floor(difference / 60 / 60 / 24 / 7);

    return formatMessage({ id: 'common.timediff.weeks' }, { weeks });
  }

  const months =
    (currentDate.getFullYear() - pastDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    pastDate.getMonth();

  // Months
  if (months < 12) {
    return formatMessage({ id: 'common.timediff.months' }, { months });
  }

  // Years
  return formatMessage({ id: 'common.timediff.moreThanYear' });
}

/**
 * sortByLabel
 *
 * @param {Object} a
 * @param {Object} b
 * @returns number
 */
export const sortByLabel = (a: Object, b: Object): number => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

/**
 * setTimeToDate
 *
 * @param {string | Date} date
 * @param {string | Date} time
 * @returns {Date}
 */
export function setTimeToDate(date: string | Date, time: string | Date): Date {
  const newDate = new Date(date);

  if (typeof time === 'string') {
    const [hours, minutes, seconds] = time.split(':');

    if (hours) {
      newDate.setHours(parseInt(hours, 10));
    }
    if (minutes) {
      newDate.setMinutes(parseInt(minutes, 10));
    }
    if (seconds) {
      newDate.setSeconds(parseInt(seconds, 10));
    }
  } else if (!Number.isNaN(new Date(time).getTime())) {
    const timeObj = new Date(time);
    newDate.setHours(timeObj.getHours());
    newDate.setMinutes(timeObj.getMinutes());
    newDate.setSeconds(timeObj.getSeconds());
  }

  return newDate;
}

/**
 * delay
 *
 * @param {() => any} callback
 * @param {number} delay
 * @returns {TimeoutID}
 */
export function delay(callback: () => any, msDelay: number = CONFIG_APP.readWriteDelay): TimeoutID {
  return setTimeout(callback, msDelay);
}

/**
 * isCountryExcluded
 *
 * @param {string} countryCode
 * @param {Array<string>} excludedCountries
 * @returns {boolean}
 */
export function isCountryExcluded(
  countryCode: string,
  excludedCountries: Array<string> = CONFIG_APP.countries.excluded,
): boolean {
  return (
    typeof countryCode === 'string' && excludedCountries.indexOf(countryCode.toUpperCase()) > -1
  );
}

/**
 * renderTextByLines
 *
 * @param {Array<string> | string} inputText
 * @returns {Object}
 */
export function renderTextByLines(inputText: Array<string> | string): Object {
  return (
    (Array.isArray(inputText) &&
      inputText.map((text, idx) => (
        <React.Fragment key={idx}>
          {idx ? <br /> : null}
          {text}
        </React.Fragment>
      ))) ||
    inputText ||
    null
  );
}

/**
 * getSystemUnits
 *
 * @param {string} system
 * @returns {Object}
 *
 */
export function getSystemUnits(system: string): Object {
  const units = CONFIG_APP.units[system];

  return units;
}

/**
 * filterSearchTextRedirectHandler
 *
 * Helper function for custom url routing
 * based on selected filters (depending on filter domains: art, artists, etc)
 * that will keep the current url paramters and merge the search text parameter
 *
 * @param {string} searchValue
 * @param {Object} url
 * @param {Object} helpers
 * @returns {void}
 */
export function filterSearchTextRouteHandler(
  searchValue: string,
  { pathname, restQueryParams }: Object,
  { history, UrlAssembler }: Object,
): void {
  const { page, ...restParams } = restQueryParams;
  const urlOptions = UrlAssembler.mergeOptions({
    search: { ...restParams, q: searchValue },
  });
  history.push(`${pathname}${urlOptions.search}`);
}
