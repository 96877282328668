// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';
import { Status } from 'shared_components/routers/Status';

type Props = {
  from?: string | Object,
  to: string | Object,
  status?: number,
};

/**
 * RedirectWithStatus
 *
 * Redirect component with status (default statusCode: 302)
 * passed to the staticContext if existing, and read on server
 * for setting redirect status sode
 */
export function RedirectWithStatus({ to, status = 302, ...restProps }: Props) {
  return (
    <Status code={status}>
      <Redirect to={to} {...restProps} />
    </Status>
  );
}
