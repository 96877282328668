// @flow

export const PAGE_PRELOAD_ACTIONS = {
  ATTACH_COMPONENT: 'PAGE_PRELOAD_ATTACH_COMPONENT',
  DETACH_COMPONENT: 'PAGE_PRELOAD_DETACH_COMPONENT',
  CLEAR_STATE: 'PAGE_PRELOAD_CLEAR_STATE',
};

// Action Creators

/**
 * toggleAttachComponent
 * @param {string} id
 * @param {boolean} attach
 * @return {Object}
 */
export function toggleAttachComponent(id: string, attach: boolean = true): Object {
  return {
    type: attach ? PAGE_PRELOAD_ACTIONS.ATTACH_COMPONENT : PAGE_PRELOAD_ACTIONS.DETACH_COMPONENT,
    payload: { id, resolved: !attach, ...(attach ? { timestamp: new Date().getTime() } : null) },
  };
}

/**
 * clearPreloaderState
 * @returns {Object}
 */
export function clearPreloaderState(): Object {
  return { type: PAGE_PRELOAD_ACTIONS.CLEAR_STATE };
}
