import { call, put } from 'redux-saga/effects';
import get from 'lodash/get';
import { Jwt, ExpiredToken, InvalidToken } from '@riseart/jwt';
import { Client as ApolloClient } from 'shared_services/apollo/client';
import CREATE_JWT_QUERY from 'shared_data/queries/jwt/createJwt.graphql';
import READ_ME_QUERY from 'shared_data/queries/me/me.graphql';
import { graphql as GRAPHQL_CONFIG } from 'Config';
import { auth as ENUM_AUTH } from 'Enum';
import {
  authTokenFetch,
  authTokenFetchLoading,
  authTokenFetchDone,
  authTokenFetchError,
  authTokenRefreshDone,
  authTokenUpdated,
} from 'shared_services/redux/actions/auth/auth';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { meFetchLoading, meFetchDone, meFetchError } from 'shared_services/redux/actions/me/me';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { GTM_EVENT_TRIGGER_REASONS } from 'shared_services/redux/middleware/MiddlewareGoogleTagManager';

/**
 * graphqlRequest
 *
 * @param {string} query
 * @param {Object} payload
 */
const graphqlRequest = async (payload) => {
  try {
    return await ApolloClient[payload.mutation ? 'mutate' : 'query'](payload);
  } catch (error) {
    return { errors: error };
  }
};

/**
 * graphqlTokenFetch
 *
 * @param {Object} action
 */
export function* graphqlTokenFetch(action) {
  const { trigger: actionTrigger, requestContextOptions, ...filteredPayload } = action.payload;

  yield put(authTokenFetchLoading());
  const response = yield call(graphqlRequest, {
    mutation: CREATE_JWT_QUERY,
    variables: { inputJwt: { ...filteredPayload } },
    ...(requestContextOptions ? { context: requestContextOptions } : {}),
  });

  if (response.errors) {
    yield put(authTokenFetchError(response.errors));
    return true;
  }

  try {
    const { token } = response.data.createJwt;
    const tokenPayload = yield Jwt.decode(token);
    const isRegistered = get(tokenPayload, 'payload.is_registered');

    // Check required to detect the exact reason for the update of token.
    // Usually this is predefined in the call payload, but in the case of
    // social login we do not know in advance if the actual reason was
    // register or login unless the backend returns that
    const trigger =
      actionTrigger && !actionTrigger.reason && typeof isRegistered === 'boolean'
        ? {
            ...actionTrigger,
            reason: GTM_EVENT_TRIGGER_REASONS[isRegistered ? 'registration' : 'login'],
          }
        : actionTrigger;
    yield put(authTokenFetchDone({ token, ...tokenPayload }));

    const authTokenUpdatedAction = authTokenUpdated({ token, trigger, ...tokenPayload });
    yield put(authTokenUpdatedAction);

    // Return last action pushed into the middleware
    // This is used server side to chain the sagas
    // while on the initial server load
    return authTokenUpdatedAction;
  } catch (error) {
    yield put(authTokenFetchError(error));

    if (error instanceof ExpiredToken || error instanceof InvalidToken) {
      yield put(authTokenFetch(action.payload));
    } else {
      yield put(errorAdd(ErrorService.mapJSError(error)));
    }
  }
}

/**
 * graphqlTokenRefresh
 *
 * @param {Object} action
 */
export function* graphqlTokenRefresh(action) {
  // Look for nested payload key if the action to refresh
  // was triggered from middleware action router
  const { currentToken, visitorId, requestContextOptions } =
    action.payload.payload || action.payload;

  yield put(authTokenFetchLoading());
  const response = yield call(graphqlRequest, {
    mutation: CREATE_JWT_QUERY,
    variables: {
      inputJwt: {
        apiKey: GRAPHQL_CONFIG.authKey,
        authModule: ENUM_AUTH.modules.WEBSITE_REFRESH,
        currentToken,
      },
    },
    ...(requestContextOptions ? { context: requestContextOptions } : {}),
  });

  if (response.errors) {
    yield put(authTokenFetchError(response.errors));
    return true;
  }

  try {
    const { token } = response.data.createJwt;
    const tokenPayload = yield Jwt.decode(token);

    yield put(authTokenRefreshDone({ token, ...tokenPayload }));
  } catch (error) {
    yield put(authTokenFetchError(error));

    if (error instanceof ExpiredToken || error instanceof InvalidToken) {
      yield put(
        authTokenFetch({
          visitorId,
          authModule: ENUM_AUTH.modules.WEBSITE_VISITOR,
          requestContextOptions,
        }),
      );
    } else {
      yield put(errorAdd(ErrorService.mapJSError(error)));
    }
  }
}

/**
 * graphqlMeRead
 *
 * @param {Object} action
 */
export function* graphqlMeRead(action) {
  yield put(meFetchLoading());
  const trigger = get(action, 'payload.payload.trigger');
  const response = yield call(graphqlRequest, {
    query: READ_ME_QUERY,
    fetchPolicy: 'network-only',
  });

  if (response.errors) {
    yield put(meFetchError(response.errors));
    return true;
  }

  yield put(meFetchDone({ ...response.data.readMe, trigger }));
}
