// @flow

/**
 * @type {{FAVORITE_ADD, FAVORITE_REMOVE, ADD_FILTER, REMOVE_FILTER, BROWSER_TAB_OPEN, BROWSER_TAB_CLOSE, MAILING_LIST_SUBSCRIPTION: string}}
 */
export const GOOGLE_TAGMANAGER_ACTIONS = {
  FAVORITE_ADD: 'GTM_ACTIONS_FAVORITE_ADD',
  FAVORITE_REMOVE: 'GTM_ACTIONS_FAVORITE_REMOVE',
  FOLLOW_CREATE: 'GTM_ACTIONS_FOLLOW_CREATE',
  FOLLOW_DELETE: 'GTM_ACTIONS_FOLLOW_DELETE',
  ADD_FILTER: 'GTM_ACTIONS_ADD_FILTER',
  REMOVE_FILTER: 'GTM_ACTIONS_REMOVE_FILTER',
  BROWSER_TAB_OPEN: 'GTM_ACTIONS_BROWSER_TAB_OPEN',
  BROWSER_TAB_CLOSE: 'GTM_ACTIONS_BROWSER_TAB_CLOSE',
  ART_VIEW: 'GTM_ACTIONS_ART_VIEW',
  ARTIST_VIEW: 'GTM_ACTIONS_ARTIST_VIEW',
  MAILING_LIST_SUBSCRIPTION: 'GTM_ACTIONS_MAILING_LIST_SUBSCRIPTION',
  QUIZ_CLICK_START: 'GTM_ACTIONS_QUIZ_CLICK_START',
  QUIZ_QUESTION_LOADED: 'GTM_ACTIONS_QUIZ_QUESTION_LOADED',
  QUIZ_COMPLETED: 'GTM_ACTIONS_QUIZ_COMPLETED',
  QUIZ_RESULT_LOADED: 'GTM_ACTIONS_QUIZ_RESULT_LOADED',
  SOCIAL_SHARE: 'GTM_ACTIONS_SOCIAL_SHARE',
  QUIZ_TAKEN: 'GTM_ACTIONS_QUIZ_TAKEN',
  PAGEVIEW: 'GTM_ACTIONS_PAGEVIEW',
  ME_UPDATED: 'GTM_ACTIONS_ME_UPDATED',
  ME_UPDATED_POST_EVENT: 'GTM_ACTIONS_ME_UPDATED_POST_EVENT',
  AUTH_LOGIN: 'GTM_ACTIONS_AUTH_LOGIN',
  AUTH_REGISTER: 'GTM_ACTIONS_AUTH_REGISTER',
  MARKETING_CAMPAIGN_MATCHED: 'GTM_ACTIONS_MARKETING_CAMPAIGN_MATCHED',
  ARTWORK_ENQUIRE_OPEN: 'GTM_ACTIONS_ARTWORK_ENQUIRE_OPEN',
  ARTWORK_OFFER_OPEN: 'GTM_ACTIONS_ARTWORK_OFFER_OPEN',
  ARTIST_COMMISSION_OPEN: 'GTM_ACTIONS_ARTIST_COMMISSION_OPEN',
  ARTWORK_ENQUIRE_SEND: 'GTM_ACTIONS_ARTWORK_ENQUIRE_SEND',
  ARTWORK_OFFER_SEND: 'GTM_ACTIONS_ARTWORK_OFFER_SEND',
  ARTIST_COMMISSION_SEND: 'GTM_ACTIONS_ARTIST_COMMISSION_SEND',
  CMS_ENQUIRE_SEND: 'GTM_ACTIONS_CMS_ENQUIRE_SEND',
  CART_VIEW: 'GTM_ACTIONS_CART_VIEW',
  CART_ITEM_ADD: 'GTM_ACTIONS_CART_ITEM_ADD',
  CART_ITEM_UPDATE: 'GTM_ACTIONS_CART_ITEM_UPDATE',
  CART_ITEM_REMOVE: 'GTM_ACTIONS_CART_ITEM_REMOVE',
  CART_CREDIT_USE: 'GTM_ACTIONS_CART_CREDIT_USE',
  CART_CREDIT_REMOVE: 'GTM_ACTIONS_CART_CREDIT_REMOVE',
  CART_COUPON_USE: 'GTM_ACTIONS_CART_COUPON_USE',
  CART_COUPON_REMOVE: 'GTM_ACTIONS_CART_COUPON_REMOVE',
  CHECKOUT_STEP_ENTER: 'GTM_ACTIONS_CHECKOUT_STEP_ENTER',
  CHECKOUT_STEP_UPDATE: 'GTM_ACTIONS_CHECKOUT_STEP_UPDATE',
  CHECKOUT_PURCHASE: 'GTM_ACTIONS_CHECKOUT_PURCHASE',
  RECENT_WIDGET_TOGGLE: 'GTM_ACTIONS_RECENT_WIDGET_TOGGLE',
  APPLICATION_PROPERTY_UPDATE: 'GTM_ACTIONS_APPLICATION_PROPERTY_UPDATE',
  PAGE_META_RESOLVED: 'GTM_ACTIONS_PAGE_META_RESOLVED',
  EVENT_RSVP: 'GTM_ACTIONS_EVENT_RSVP',
  EVENT_ELEMENT_CLICKED: 'GTM_ACTIONS_ELEMENT_CLICKED',
  ART_TAB_DETAIL_TOGGLE: 'GTM_ACTIONS_ART_TAB_DETAIL_TOGGLE',
  ART_TAB_FAQ_TOGGLE: 'GTM_ACTIONS_ART_TAB_FAQ_TOGGLE',
  CART_TAB_FAQ_TOGGLE: 'GTM_ACTIONS_CART_TAB_FAQ_TOGGLE',
  VISUAL_SEARCH_ADD: 'GTM_ACTIONS_VS_ADD',
  VISUAL_SEARCH_RESTART: 'GTM_ACTIONS_VS_RESTART',
  VISUAL_SEARCH_REFRESH: 'GTM_ACTIONS_VS_REFRESH',
  VISUAL_SEARCH_MENU_CLICK: 'GTM_ACTIONS_VS_MENU_CLICK',
  PROMO_CARD_SHOW: 'GTM_ACTIONS_PROMO_CARD_SHOW',
  PROMO_CARD_CLICK: 'GTM_ACTIONS_PROMO_CARD_CLICK',
  ARTWORK_LIST_VIEW: 'GTM_ACTIONS_ARTWORK_LIST_VIEW',
  ARTWORK_LIST_ITEM_SELECTED: 'GTM_ACTIONS_ARTWORK_LIST_ITEM_SELECTED',
  SEARCH_TEXT: 'GTM_ACTIONS_SEARCH_TEXT',
  CONSENT_REJECT: 'GTM_ACTIONS_CONSENT_REJECT',
  CONSENT_ACCEPT: 'GTM_ACTIONS_CONSENT_ACCEPT',
};

const ACTION_NAMES = {
  OPEN_BROWSER_TAB: 'ra.browserTabOpen',
  CLOSE_BROWSER_TAB: 'ra.browserTabClose',
  FILTER_ADD: 'ra.filterAdd',
  FILTER_REMOVE: 'ra.filterRemove',
};

// Action Creators
export function gtmQuizQuestionLoaded(payload: Object): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.QUIZ_QUESTION_LOADED,
    payload,
  };
}

export function gtmQuizCompleted(): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.QUIZ_COMPLETED };
}

export function gtmQuizTaken(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.QUIZ_TAKEN, payload };
}

export function gtmQuizResultLoaded(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.QUIZ_RESULT_LOADED, payload };
}

export function gtmSocialShare(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.SOCIAL_SHARE, payload };
}

export function gtmFilterAdd(payload: Object): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ADD_FILTER,
    payload: {
      actionName: ACTION_NAMES.FILTER_ADD,
      ...payload,
    },
  };
}

export function gtmFilterRemove(payload: Object): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.REMOVE_FILTER,
    payload: {
      actionName: ACTION_NAMES.FILTER_REMOVE,
      ...payload,
    },
  };
}

export function gtmTabOpen(payload: Object): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.BROWSER_TAB_OPEN,
    payload: {
      actionName: ACTION_NAMES.OPEN_BROWSER_TAB,
      ...payload,
    },
  };
}

export function gtmTabClose(payload: Object): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.BROWSER_TAB_CLOSE,
    payload: {
      actionName: ACTION_NAMES.CLOSE_BROWSER_TAB,
      ...payload,
    },
  };
}

export function gtmMailingListSubscription(payload: Object): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.MAILING_LIST_SUBSCRIPTION,
    payload,
  };
}

export function gtmAuthLogin(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.AUTH_LOGIN,
    payload,
  };
}

export function gtmAuthRegister(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.AUTH_REGISTER,
    payload,
  };
}

export function gtmArtworkEnquireOpen(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_ENQUIRE_OPEN,
    payload,
  };
}

export function gtmArtworkOfferOpen(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_OFFER_OPEN,
    payload,
  };
}

export function gtmArtistCommissionOpen(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ARTIST_COMMISSION_OPEN,
    payload,
  };
}

export function gtmArtistView(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ARTIST_VIEW,
    payload,
  };
}

export function gtmEventRsvp(payload: Object) {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.EVENT_RSVP,
    payload,
  };
}

export function gtmArtTabDetailToggle(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.ART_TAB_DETAIL_TOGGLE, payload };
}

export function gtmArtTabFaqToggle(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.ART_TAB_FAQ_TOGGLE, payload };
}

export function gtmCartTabFaqToggle(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.CART_TAB_FAQ_TOGGLE, payload };
}

export function gtmCartView(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.CART_VIEW, payload };
}

export function gtmPromoCardShow(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.PROMO_CARD_SHOW, payload };
}

export function gtmPromoCardClick(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.PROMO_CARD_CLICK, payload };
}

export function gtmElementClicked(
  location: string,
  action: string,
  objectType: string,
  objectUrl: string,
): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.EVENT_ELEMENT_CLICKED,
    payload: {
      location,
      action,
      objectType,
      objectUrl,
    },
  };
}

export function gtmArtworkListView(
  name?: string | null,
  location?: string,
  items?: Object,
): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_LIST_VIEW,
    payload: { name, location, items },
  };
}

export function gtmArtworkListItemSelected(
  name?: string | null,
  location?: string,
  artwork?: Object,
): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_LIST_ITEM_SELECTED,
    payload: { name, location, artwork },
  };
}

export function gtmMainSearchText(payload: Object): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.SEARCH_TEXT, payload };
}

export function gtmConsentReject(payload: Object = {}): Object {
  return {
    type: GOOGLE_TAGMANAGER_ACTIONS.CONSENT_REJECT,
    payload: { state: 'required', ...payload },
  };
}

export function gtmConsentAccept(payload: Object = {}): Object {
  return { type: GOOGLE_TAGMANAGER_ACTIONS.CONSENT_ACCEPT, payload: { state: 'all', ...payload } };
}
