import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { ME_ACTIONS } from 'shared_services/redux/actions/container';
import { DEFAULT_STORE_STATE } from 'shared_services/redux/defaultStoreStates';

const DEFAULT_ME_STATE = {
  ...cloneDeep(DEFAULT_STORE_STATE),
  data: { visitor: null, user: null },
};

/**
 * meReducer
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} New ME state
 */
export function meReducer(state = DEFAULT_ME_STATE, action) {
  switch (action.type) {
    case ME_ACTIONS.ME_FETCH_LOADING:
      return {
        ...state,
        status: {
          error: false,
          loading: true,
          loaded: false,
        },
      };
    case ME_ACTIONS.ME_FETCH_DONE:
      return {
        data: { ...DEFAULT_ME_STATE.data, ...omit(action.payload, 'trigger') },
        error: null,
        status: {
          error: false,
          loading: false,
          loaded: true,
        },
      };
    case ME_ACTIONS.ME_FETCH_ERROR:
      return {
        data: { ...state.data },
        error: { ...action.payload },
        status: {
          error: true,
          loading: false,
          loaded: false,
        },
      };
    case ME_ACTIONS.ME_PATCH:
      return {
        data: { ...action.payload },
        error: null,
        status: {
          error: false,
          loading: false,
          loaded: true,
        },
      };
    default:
      return state;
  }
}
