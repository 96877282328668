// @flow

import { art as ENUM_ART, artist as ENUM_ARTIST } from 'Enum';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import SEARCH_SELECTION_QUERY from 'shared_data/queries/search/selection.graphql';
import FILTER_SELECTION_QUERY from 'shared_data/queries/filter/selection.graphql';

export const getFilterDomainConfig: Object = (domain: string, routeOptions: Object = {}) => {
  const DOMAIN_CONFIG = {
    art(routeOptions) {
      return {
        backendDomain: 'art',
        frontendDomain: 'art',
        translationDomain: 'art',
        rootUrlPath: UrlAssembler.artList(routeOptions),
        selectionQuery: SEARCH_SELECTION_QUERY,
        textSearchDefaultSort: ENUM_ART.rank.RANKING_RELEVANCE,
      };
    },
    artist(routeOptions) {
      return {
        backendDomain: 'artist',
        frontendDomain: 'artist',
        translationDomain: 'artist',
        rootUrlPath: UrlAssembler.artistList(routeOptions),
        selectionQuery: SEARCH_SELECTION_QUERY,
        textSearchDefaultSort: ENUM_ARTIST.rank.RANKING_RELEVANCE,
      };
    },
    collection(routeOptions) {
      return {
        backendDomain: 'collection',
        frontendDomain: 'collection',
        translationDomain: 'collection',
        rootUrlPath: UrlAssembler.collectionList(routeOptions),
        selectionQuery: FILTER_SELECTION_QUERY,
      };
    },
    collectionArt({ id, slug, ...options }) {
      return {
        backendDomain: 'art',
        frontendDomain: 'collectionArt',
        translationDomain: 'art',
        rootUrlPath: UrlAssembler.collectionDetail(id, slug, options),
        selectionQuery: FILTER_SELECTION_QUERY,
      };
    },
    favoritesArt(routeOptions) {
      return {
        backendDomain: 'art',
        frontendDomain: 'favoritesArt',
        translationDomain: 'art',
        rootUrlPath: UrlAssembler.byRouteKey('myWishlist', routeOptions),
        selectionQuery: FILTER_SELECTION_QUERY,
      };
    },
    favoritesArtist(routeOptions) {
      return {
        backendDomain: 'artist',
        frontendDomain: 'favoritesArtist',
        translationDomain: 'artist',
        rootUrlPath: UrlAssembler.byRouteKey('myArtists', routeOptions),
        selectionQuery: FILTER_SELECTION_QUERY,
      };
    },
    artistArt({ id, alias, role, ...options }) {
      return {
        backendDomain: 'art',
        frontendDomain: 'artistArt',
        translationDomain: 'art',
        rootUrlPath: UrlAssembler.artistSubpage('artistProfile', id, alias, options),
        selectionQuery: FILTER_SELECTION_QUERY,
      };
    },
  };

  return DOMAIN_CONFIG[domain](routeOptions);
};
