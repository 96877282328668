// @flow

import { Component } from 'react';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { MetaService, VALID_META_KEYS } from 'shared_services/riseart/meta/Meta';

type Props = {
  meta: Object,
  metaSubscriber?: string,
  forceResolution?: boolean,
  hasError?: boolean,
  priority?: any,
  cacheState?: boolean,
  children?: any,
};

type State = {
  currentMeta: ?Object,
};

const HOC_DISPLAY_NAME = 'HOCMeta';

/**
 * pushMetaData
 * @param {Props} props
 * @param {Object} currentMeta
 */
function pushMetaData(props: Props, currentMeta: ?Object) {
  const { meta = {}, cacheState, metaSubscriber, priority, forceResolution, hasError } = props;

  if (hasError) {
    MetaService.errorSubscription(metaSubscriber);
    return;
  }

  if (cacheState && (isEmpty(meta) || isEqual(meta, currentMeta))) {
    return;
  }

  if (forceResolution) {
    MetaService.unresolveSubscription(metaSubscriber);
  }

  MetaService.add(
    Object.keys(pick(meta, VALID_META_KEYS)).map((i) => ({
      type: i,
      ...(priority ? { priority } : {}),
      value: meta[i],
    })),
    metaSubscriber,
  );
}

/**
 * MetaDataProvider
 *
 * pushing the passed corresponding data in props to meta tags
 */
export class MetaProvider extends Component<Props, State> {
  static displayName = HOC_DISPLAY_NAME;

  static defaultProps: Object = { meta: {}, children: null, cacheState: true };

  currentMeta: Object = {};

  /**
   * constructor
   */
  constructor(props: Props) {
    super(props);

    pushMetaData(this.props);
    this.state = {
      currentMeta: null, // eslint-disable-line
    };
  }

  /**
   * getDerivedStateFromProps
   * This method is used instead of the componentDidUpdate
   * because it is called both on server and client
   *
   * @param {Props} props
   * @param {State} state
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    pushMetaData(props, state.currentMeta);

    if (isEmpty(props.meta) || (props.cacheState && isEqual(props.meta, state.currentMeta))) {
      return null;
    }

    return { currentMeta: { ...props.meta } };
  }

  /**
   * render
   *
   * @returns {React$Element<*>}
   */
  render() {
    return this.props.children;
  }
}
