// @flow

export const GUI_PROPERTIES = {
  PAGE_TITLE: 'pageTitle',
  APPLICATION_MSG_HEIGHT: 'applicationMsgHeight',
  NOTIFICATIONS_HEIGHT: 'notificationsHeight',
  QUIZ_PROGRESS: 'quizProgress',
  CART_SIDEBAR: 'cartSidebar',
  REGIONAL_SETTINGS_SIDEBAR: 'regionalSettingsSidebar',
  RECENT_ART_WIDGET: 'recentArtWidget',
};
