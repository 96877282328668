// @flow

import get from 'lodash/get';
import { Jwt, ExpiredToken, InvalidToken } from '@riseart/jwt';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import {
  authTokenFetch,
  authTokenFetchDone,
  authCookieUpdate,
  authTokenUpdated,
  authTokenRefresh,
} from 'shared_services/redux/actions/auth/auth';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { auth as AUTH_ENUM, errors as ERRORS_ENUM } from 'Enum';

// Extract configuration values
const { WEBSITE_VISITOR: WEBSITE_VISITOR_AUTH_MODULE } = AUTH_ENUM.modules;

// Error handler configuration for token fetch actions
const TOKEN_FETCH_ERROR_OPTIONS = {
  handler: ERRORS_ENUM.handlers.ERROR_PAGE,
};

/*
 * All values in cookies are strings, so this is a list of falsey values in token cookie.
 * IMPORTANT: token cookie will and should not be set with falsey values in the first place,
 * but if somehow this happens then it will fallback to case when no value in cookie is set
 * and will enter a valid app state
 */
const FALSEY_VALUES = ['undefined', 'null', 'NaN', 'Infinity', '-Infinity', ''];

/**
 * getCookieValue
 *
 * @param {string} cookieName
 * @returns {string} token cookie value
 */
export function getCookieValue(cookieName: string): string | null {
  const value: ?string = Cookies.get(cookieName);

  return value && FALSEY_VALUES.indexOf(value) === -1 ? value : null;
}

/**
 * validateToken
 *
 * @param {string} token
 * @returns {Object} decoded token payload
 */
async function validateToken(token: string): Promise<Object> {
  const decodedToken = await Jwt.decode(token);
  const payload = get(decodedToken, 'payload', null);
  const visitorId = get(payload, 'visitor_id');

  if (!payload || !visitorId) {
    throw new InvalidToken();
  }

  return payload;
}

/**
 * normalizeVisitorId
 *
 * @param {?string | ?number} visitorId
 * @returns {number | null}
 */
function normalizeVisitorId(visitorId: ?string | ?number): number | null {
  return !Number.isNaN(+visitorId) && parseInt(visitorId, 10) > 0 ? parseInt(visitorId, 10) : null;
}

/**
 * getInitialAuthActions
 *
 * @param {?string} tokenFromCookie
 * @param {?string | ?number} visitorIdFromCookie
 * @param {?string | ?number} visitorIdFromQuery
 * @param {?string} tokenFromState
 * @returns { Promise<Array<Object>> }
 */
export async function getInitialAuthActions(
  tokenFromCookie: ?string,
  visitorIdFromCookie: ?string | ?number,
  visitorIdFromQuery?: ?string | ?number,
  tokenFromState: ?string,
): Promise<Array<Object>> {
  // Normalize visitor IDs
  const parsedVisitorIdFromCookie = normalizeVisitorId(visitorIdFromCookie);
  const parsedVisitorIdFromQuery = normalizeVisitorId(visitorIdFromQuery);

  try {
    // We have a valid cookie and a token settled in the store...means that it could be a valid user
    if (tokenFromCookie && tokenFromState) {
      // This check will trigger an exception if the token is expired...
      // Could be replaced by some logic about the token renewal
      const payload = await validateToken(tokenFromState);

      // Checks if token visitor equals the query visitor if provided
      if (parsedVisitorIdFromQuery && payload && payload.visitor_id) {
        if (normalizeVisitorId(payload.visitor_id) !== parsedVisitorIdFromQuery) {
          throw new InvalidToken();
        }
      }

      if (payload && tokenFromState !== tokenFromCookie) {
        return [authCookieUpdate({ token: tokenFromState, payload })];
      }
    }

    // Session must be initialized, there isn't cookie or token
    if (tokenFromCookie === null && !tokenFromState) {
      // requestContextOptions will pass additional context data to the graphql request
      return [
        authTokenFetch({
          visitorId: parsedVisitorIdFromQuery || parsedVisitorIdFromCookie || null,
          authModule: WEBSITE_VISITOR_AUTH_MODULE,
          requestContextOptions: { customOptions: { error: TOKEN_FETCH_ERROR_OPTIONS } },
        }),
      ];
    }

    // Token is not in the store, could be: a page refresh/an address inserted manually/user is coming back
    if (tokenFromCookie && !tokenFromState) {
      const payload = await validateToken(tokenFromCookie);

      // Checks if token visitor equals the query visitor if provided
      if (parsedVisitorIdFromQuery && payload && payload.visitor_id) {
        if (normalizeVisitorId(payload.visitor_id) !== parsedVisitorIdFromQuery) {
          throw new InvalidToken();
        }
      }

      // Refresh the token if current token does not include the visitor store
      // (it means that the token is valid but old and dows not have the additional visitor parameters)
      if (payload && !payload.store) {
        return [
          authTokenRefresh({
            visitorId: parsedVisitorIdFromQuery || parsedVisitorIdFromCookie || null,
            currentToken: tokenFromCookie,
            requestContextOptions: { customOptions: { error: TOKEN_FETCH_ERROR_OPTIONS } },
          }),
        ];
      }

      return [
        authTokenFetchDone({ token: tokenFromCookie, payload }),
        authTokenUpdated({ token: tokenFromCookie, payload }),
      ];
    }
  } catch (error) {
    if (error instanceof ExpiredToken || error instanceof InvalidToken) {
      return [
        authTokenFetch({
          visitorId: parsedVisitorIdFromQuery || parsedVisitorIdFromCookie || null,
          authModule: WEBSITE_VISITOR_AUTH_MODULE,
          requestContextOptions: { customOptions: { error: TOKEN_FETCH_ERROR_OPTIONS } },
        }),
      ];
    }

    return [errorAdd(ErrorService.mapJSError(error, TOKEN_FETCH_ERROR_OPTIONS))];
  }

  return [];
}
