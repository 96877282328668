// @flow

import { FilterService } from '@riseart/filter-service';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { cookies as CONFIG_COOKIES } from 'Config';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { AUTH_ACTIONS, ME_ACTIONS } from 'shared_services/redux/actions/container';

/**
 * MiddlewareAuth
 */
export class MiddlewareAuth {
  /**
   * middleware
   *
   * @param {Object} store
   * @returns {Function}
   */
  // eslint-disable-next-line no-unused-vars
  static middleware = (store: Object) => (next: Function) => (action: Object) => {
    switch (action.type) {
      case AUTH_ACTIONS.AUTH_COOKIE_UPDATE:
        MiddlewareAuth.updateAuthCookies(action.payload.payload);
        break;
      case AUTH_ACTIONS.AUTH_TOKEN_COOKIE_UPDATE:
        MiddlewareAuth.updateTokenCookie(action.payload.payload.token);
        break;
      case AUTH_ACTIONS.AUTH_TOKEN_COOKIE_CLEAR:
        MiddlewareAuth.clearTokenCookie();
        break;
      case AUTH_ACTIONS.AUTH_VISITOR_COOKIE_UPDATE:
        MiddlewareAuth.updateVisitorCookie(action.payload.payload);
        break;
      case ME_ACTIONS.ME_UPDATED:
        // set Sentry user only if visitor data is received from graphql which includes email
        if (action.payload.payload.visitor) {
          RiseartLogger.setUserContext(action.payload.payload);
          FilterService.setUnitLength(action.payload.payload.visitor.unitLength);
        }
        break;
      default:
        break;
    }

    next(action);
  };

  /**
   * updateAuthCookies
   *
   * @param {Object} payload
   */
  static updateAuthCookies(payload: Object) {
    const { token } = payload;

    MiddlewareAuth.updateTokenCookie(token);
    MiddlewareAuth.updateVisitorCookie({ payload });
  }

  /**
   * updateTokenCookie
   *
   * @param {string} token
   */
  static updateTokenCookie(token: string) {
    if (!token) {
      return;
    }

    Cookies.set(CONFIG_COOKIES.token.name, token, CONFIG_COOKIES.token);
  }

  /**
   * clearTokenCookie
   */
  static clearTokenCookie() {
    Cookies.remove(CONFIG_COOKIES.token.name);
  }

  /**
   * updateVisitorCookie
   *
   * @param {Object} data
   */
  static updateVisitorCookie(data: Object = {}) {
    let visitorId = null;
    if (data.payload) {
      // Update from JWT payload
      visitorId = data.payload.visitor_id;
    } else if (data.visitor) {
      // Update from ME payload
      visitorId = data.visitor.id;
    }

    if (!visitorId) {
      return;
    }

    Cookies.set(CONFIG_COOKIES.visitor.name, visitorId, CONFIG_COOKIES.visitor);
  }
}
