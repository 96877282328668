// @flow

import { useSelector } from 'react-redux';
import { redirectsSelector } from 'shared_services/redux/selectors/redirects';

/**
 * useRedirects
 *
 * @returns {?Object}
 */
export const useRedirects = (): ?Object => {
  return useSelector(redirectsSelector);
};
