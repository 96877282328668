// @flow

import has from 'lodash/has';
import { normalizeUri } from 'shared_services/riseart/utils/RouteUtils';

const TARGET_SELF = '_self';
const DERIVED_PROPERTIES = { uri: 'uri', fullPath: 'fullPath', originWithPath: 'originWithPath' };

/**
 * LocationManager
 * Manages url location object assignment and redirects both on server (httpResponse) and client (window.location)
 */
export const LocationManager = {
  redirected: null,
  location: {},
  httpResponse: null,
  /**
   * config
   * The location is passes by reference if it is a js object.
   * In case of window.location for the client, it can be passed as parameter
   * and then used without updating it each time when it changes
   * @param {Object} location
   * @param {Object} httpResponse
   * @returns {void}
   */
  config(location: Object = {}, httpResponse?: Object): void {
    const runOnServer = typeof window === 'undefined';
    const hasValidHTTPResponse = typeof httpResponse === 'object';
    LocationManager.redirected = null;

    if (runOnServer && !hasValidHTTPResponse) {
      throw new Error('[LocationManager] Please provide an http response object from server.');
    }

    LocationManager.location = location;
    // httpResponse is added in a condition so that Flow does not show an error
    if (runOnServer && httpResponse) {
      LocationManager.httpResponse = httpResponse;
    }
  },
  /**
   * isSSR
   * @returns {boolean}
   */
  get isSSR(): boolean {
    return !!(LocationManager.httpResponse || typeof window === 'undefined');
  },
  /**
   * get
   * @param {string} property
   * @returns {string | Object | Function}
   */
  get(property?: string): any {
    if (!property) {
      return LocationManager.location;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (has(LocationManager.location, property)) {
      return LocationManager.location[property];
    }

    const derivedProperty = LocationManager.getDerivedProperties(property);
    if (derivedProperty) {
      return derivedProperty;
    }

    throw new Error(
      `[LocationManager] Trying to access a non existing property '${
        property || 'undefined'
      }' in location.`,
    );
  },
  /**
   * getDerivedProperties
   * get derived properties from the main LocationManager.location object
   * @param {string} property
   * @returns {string | void}
   */
  getDerivedProperties(property: string): any {
    const { location } = LocationManager;

    if (property === DERIVED_PROPERTIES.uri) {
      return normalizeUri(
        [location.pathname, location.search, location.hash].filter((i) => !!i).join(''),
      );
    }

    if (property === DERIVED_PROPERTIES.originWithPath) {
      return [location.origin, normalizeUri(location.pathname)].filter((i) => !!i).join('');
    }

    if (property === DERIVED_PROPERTIES.fullPath) {
      return [location.origin, normalizeUri(location.pathname), location.search, location.hash]
        .filter((i) => !!i)
        .join('');
    }

    // fix for old browsers which do not support window.location.origin
    if (property === 'origin' && typeof window !== 'undefined' && !window.location.origin) {
      return `${location.protocol}//${location.hostname}${
        location.port ? `:${location.port}` : ''
      }`;
    }
  },
  /**
   * redirect
   * @param {string} url
   * @param {Object} options
   * @returns {?Object}
   */
  redirect(path: string, { status = 302, target = TARGET_SELF }: Object = {}): ?Object {
    if (!LocationManager.isSSR) {
      if (target === TARGET_SELF) {
        // this is equivalent to window.location.href = path
        // and it makes a page refresh
        LocationManager.location.href = path;
        return;
      }
      return window.open(path, target);
    }

    if (LocationManager.httpResponse) {
      LocationManager.redirected = { status, url: path };
      return;
    }

    throw new Error(
      `[LocationManager] Unable to detect client or server environment for redirection.`,
    );
  },
};

/**
 * buildLocationFromHTTPRequest
 * Helper function that uses the httpRequest from Express and the url nodejs module
 * and returns an object similar to window.location on client
 *
 * @param {string} path
 * @param {Function} urlParser
 * @returns {}
 */
export function buildLocationFromHTTPRequest(path: string, urlParser: Function): string {
  const parsedUrl: Object = urlParser(decodeURIComponent(path));

  return {
    ...parsedUrl,
    origin: `${parsedUrl.protocol}//${parsedUrl.host}`,
  };
}
