// @flow

import React from 'react';
import type { Node } from 'react';
import { RedirectWithStatus } from 'shared_components/routers/RedirectWithStatus';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/RouteConfigContext';
import { useRedirects } from 'shared_services/redux/hooks/useRedirects';
import { findMetaRedirect } from 'shared_services/riseart/meta/utils';

/**
 * MetaRedirect
 *
 * @param {Props} props
 * @returns {Node}
 */
export const MetaRedirect = (): Node => {
  const redirects = useRedirects();

  return (
    <RouteConfigContext.Consumer>
      {({ currentParams, currentLocale, userLocale, location, path }) => {
        const metaRedirect = findMetaRedirect(redirects, {
          currentParams,
          currentLocale,
          userLocale,
          location,
          routeConfigPath: path,
        });

        return metaRedirect && metaRedirect.url ? (
          <RedirectWithStatus to={metaRedirect.url} status={metaRedirect.status} />
        ) : null;
      }}
    </RouteConfigContext.Consumer>
  );
};
