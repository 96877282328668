// @flow

import { cart as ENUM_CART } from 'Enum';

const { ACTION_ADD, ACTION_REMOVE, ACTION_UPDATE, ACTION_DELETE } = ENUM_CART.itemAction.action;

export const CART_ACTIONS = {
  ITEM_ADD: 'ITEM_ADD',
  ITEM_REMOVE: 'ITEM_REMOVE',
  ITEM_UPDATE: 'ITEM_UPDATE',
  CREDIT_USE: 'CREDIT_USE',
  CREDIT_REMOVE: 'CREDIT_REMOVE',
  COUPON_USE: 'COUPON_USE',
  COUPON_REMOVE: 'COUPON_REMOVE',
};

/**
 * findCartItemById
 *
 * @param {Array<?Object>} items
 * @param {number} itemId
 * @return <?Object>
 */
function findCartItemById(items: Array<Object> = [], itemId: number): ?Object {
  if (!items || !items.length) {
    return;
  }

  return items.filter((item) => parseInt(item.id, 10) === parseInt(itemId, 10))[0];
}

/**
 * collectCartItemData
 *
 * @param {Object} actionData
 * @param {Array<Object>} cartItems
 * @param {Array<Object>} prevItems
 */
function collectCartItemData(
  actionData: Object = {},
  cartItems: Array<Object>,
  prevItems?: Array<Object>,
): Object {
  const { action, previousQty, currentQty, itemId } = actionData || {};
  const updatedCartItemId = parseInt(itemId, 10);
  const modifiedQty = currentQty !== previousQty ? Math.abs(currentQty - previousQty) : currentQty;

  switch (action) {
    case ACTION_ADD:
      return {
        // Check if the quantity increased. In that case trigger an add action
        type: CART_ACTIONS.ITEM_ADD,
        modifiedQty,
        previousQty,
        modifiedItem: findCartItemById(cartItems, updatedCartItemId),
      };
    case ACTION_REMOVE: {
      return {
        type: CART_ACTIONS.ITEM_REMOVE,
        modifiedQty,
        previousQty,
        modifiedItem: findCartItemById(prevItems, updatedCartItemId),
      };
    }
    case ACTION_DELETE:
      return {
        type: CART_ACTIONS.ITEM_REMOVE,
        modifiedQty,
        previousQty,
        modifiedItem: findCartItemById(prevItems, updatedCartItemId),
      };
    case ACTION_UPDATE:
    default:
      return {
        type: CART_ACTIONS.ITEM_UPDATE,
        modifiedQty,
        previousQty,
        modifiedItem: findCartItemById(cartItems, updatedCartItemId),
      };
  }
}

/**
 * cartItemsActionFactory
 * A function that will separate each cartItems by type of action: add, remove,
 * update and dispatch the action with the selected cartLists
 *
 * @param {Function} dispatch
 * @returns {Function}
 */
export const cartItemsActionFactory =
  (dispatch: Function): Object =>
  (cartResponse: Object = {}, previousCartItems?: Array<Object>) => {
    const { actions = [], cart = {} } = cartResponse || {};
    const { cartItems, ...cartData } = cart || {};

    const reduxActions = actions.reduce((accumulator, action) => {
      const { type, modifiedQty, previousQty, modifiedItem } = collectCartItemData(
        action,
        cartItems,
        previousCartItems,
      );

      // Separate modifiedItem by action type and merge modifiedQty and previousQty in cart item
      if (type && CART_ACTIONS[type] && modifiedItem) {
        accumulator[type] = [
          ...(accumulator[type] ? accumulator[type] : []),
          { ...modifiedItem, modifiedQty, previousQty },
        ];
      }

      return accumulator;
    }, {});

    // Dispatch actions
    Object.keys(reduxActions).forEach((triggeredAction) => {
      if (reduxActions[triggeredAction] && reduxActions[triggeredAction].length) {
        dispatch({
          type: triggeredAction,
          payload: {
            cart: cartData,
            cartItems,
            modifiedItem: reduxActions[triggeredAction],
          },
        });
      }
    });
  };

/**
 * useCredit
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function useCredit(payload: Object): Object {
  return {
    type: CART_ACTIONS.CREDIT_USE,
    payload,
  };
}

/**
 * removeCredit
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function removeCredit(payload: Object): Object {
  return {
    type: CART_ACTIONS.CREDIT_REMOVE,
    payload,
  };
}

/**
 * useCoupon
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function useCoupon(payload: Object): Object {
  return {
    type: CART_ACTIONS.COUPON_USE,
    payload,
  };
}

/**
 * removeCoupon
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function removeCoupon(payload: Object): Object {
  return {
    type: CART_ACTIONS.COUPON_REMOVE,
    payload,
  };
}
