// @flow

import React from 'react';
import type { Node } from 'react';
import { ErrorBoundary } from 'shared_data/providers/errors/ErrorBoundary';
import { ErrorPageRenderer } from 'shared_data/providers/errors/ErrorPageRenderer';
import { ErrorPage } from 'shared_components/pages/error/Error';
import { MetaConfigProvider } from 'shared_data/providers/meta/MetaConfig';
import { AppLocale } from 'shared_components/routers/Locale';
import { SSRConsumer } from 'shared_data/providers/ssr/Consumer';
import { I18nProvider } from 'shared_components/i18n/I18nProvider';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { ACLProvider } from 'shared_data/providers/acl/ACLProvider';
import { ACLContext } from 'shared_data/providers/acl/ACLContext';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/RouteConfigContext';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { TokenProvider } from 'shared_data/providers/token/Token';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { RedirectWithStatus } from 'shared_components/routers/RedirectWithStatus';
import { MetaInit } from 'shared_hocs/meta/MetaInit';
import { getMetaByModule } from 'shared_services/riseart/meta/utils';
import { UIInit } from 'shared_hocs/gui/UIInit';
import { MetaRedirect } from 'shared_components/routers/MetaRedirect';
import { UrlTranslationsProvider } from 'shared_data/providers/url/TranslationsProvider';
import { GoogleAuthCallbackProvider } from 'shared_components/auth/google/GoogleProvider';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';

type Props = {
  isSSR: boolean,
  children: Function,
  pageRouteConfig: Object,
  location: Object,
  match: Object,
};

/**
 * AppPage
 *
 * @returns {React$Element<*>}
 */
export const AppPage = ({ isSSR, pageRouteConfig, location, match, children }: Props): Node => (
  <AppLocale isSSR={isSSR} urlLanguage={match.params.lang} location={location}>
    {({ routeLocale: currentLocale, userLocale }) => (
      <I18nProvider locale={currentLocale}>
        <ErrorBoundary errorRender={(error) => <ErrorPage error={error} />}>
          <GoogleAuthCallbackProvider pageConfig={pageRouteConfig} />
          <UrlTranslationsProvider locale={currentLocale.name}>
            {({ translatedLocation }) => (
              <React.Fragment>
                <MetaConfigProvider isSSR={isSSR} />
                <ErrorPageRenderer>
                  <RouteConfigContext.Provider
                    value={{
                      ...pageRouteConfig,
                      location: translatedLocation,
                      currentParams: match.params,
                      currentLocale,
                      userLocale,
                    }}
                  >
                    <SSRConsumer>
                      {({ isSSR }) => (
                        <React.Fragment>
                          <I18nComponentsLoader dataKey="pageMeta">
                            {(pageMeta) => (
                              <MetaInit
                                isSSR={isSSR}
                                match={match}
                                location={translatedLocation}
                                configMeta={pageRouteConfig}
                                currentLocale={currentLocale}
                                userLocale={userLocale}
                                pageMeta={getMetaByModule(pageMeta, pageRouteConfig.module)}
                              />
                            )}
                          </I18nComponentsLoader>
                          {!isSSR ? <UIInit routeConfig={pageRouteConfig} /> : null}
                        </React.Fragment>
                      )}
                    </SSRConsumer>
                    <MetaRedirect />
                    <ACLContext.Provider value={pageRouteConfig && pageRouteConfig.acl}>
                      <TokenProvider>
                        {({ token, aclRole }) =>
                          token ? (
                            <ACLProvider
                              profileRole={aclRole}
                              render={(isAllowed: boolean) =>
                                isAllowed ? (
                                  children({ currentLocale, translatedLocation })
                                ) : (
                                  <RedirectWithStatus
                                    to={UrlAssembler.byRouteKey(
                                      (pageRouteConfig &&
                                        pageRouteConfig.acl &&
                                        ((pageRouteConfig.acl.authPage &&
                                          pageRouteConfig.acl.authPage.key) ||
                                          pageRouteConfig.acl.forward)) ||
                                        'login',
                                      {
                                        ...((pageRouteConfig &&
                                          pageRouteConfig.acl &&
                                          pageRouteConfig.acl.authPage &&
                                          pageRouteConfig.acl.authPage.options) ||
                                          {}),
                                        locale: currentLocale,
                                        ...(pageRouteConfig &&
                                        pageRouteConfig.acl &&
                                        pageRouteConfig.acl.forward
                                          ? {}
                                          : {
                                              search: {
                                                forward: LocationManager.get('uri'),
                                              },
                                            }),
                                      },
                                    )}
                                  />
                                )
                              }
                            />
                          ) : (
                            <IsomorphicRipple
                              isActive
                              isFullScreen
                              customStyles={{ backgroundColor: '#ffffff' }}
                            />
                          )
                        }
                      </TokenProvider>
                    </ACLContext.Provider>
                  </RouteConfigContext.Provider>
                </ErrorPageRenderer>
              </React.Fragment>
            )}
          </UrlTranslationsProvider>
        </ErrorBoundary>
      </I18nProvider>
    )}
  </AppLocale>
);
