// @flow

import React, { useEffect } from 'react';
import type { Node } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route } from 'react-router-dom';
import { ResponsiveContext } from '@riseart/common';
import { App as SharedApp } from 'shared_components/app/App';
import { initClientEnvironment } from 'client_services/riseart/environment/client';
import { SSRProvider } from 'shared_data/providers/ssr/Provider';
import { Redirector } from 'shared_components/routers/Redirector';

// Setup client application environment
const { reduxStore, apolloClient, initialDeviceWidth, initialReduxState } = initClientEnvironment();

/**
 * App
 *
 * @returns {Node}
 */
export const App = (): Node => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.RiseArt) {
      window.RiseArt.hydrated = true;
    }
  });

  return (
    <SSRProvider isSSR={false}>
      <ReduxProvider store={reduxStore} serverState={initialReduxState}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            {/*
              Redirector is only called client side, because server config redirects
              are handled server side by separate service
            */}
            <Route
              render={() => (
                <Redirector>
                  <ResponsiveContext.Provider
                    value={{ isSSR: false, deviceWidth: initialDeviceWidth }}
                  >
                    <SharedApp />
                  </ResponsiveContext.Provider>
                </Redirector>
              )}
            />
          </BrowserRouter>
        </ApolloProvider>
      </ReduxProvider>
    </SSRProvider>
  );
};
