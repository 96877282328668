// @flow

import React from 'react';
import isArray from 'lodash/isArray';
import { FormattedMessage } from 'react-intl';
import { Heading, Paragraph } from '@riseart/common';
import { errors as ERRORS_CONFIG, debug as DEBUG_CONFIG } from 'Config';
import { LogoStatic, LogoComponent as Logo } from 'shared_components/common/logo/Logo';
import { type ErrorMessageType } from 'shared_services/riseart/errors/ErrorService';

import { errorCls, errorMainCls, errorLogoCls } from './Details.less';

type Props = {
  error?: ErrorMessageType, // eslint-disable-line react/require-default-props
  translate?: boolean,
  children?: any,
};

/**
 * ErrorDetails
 *
 * @param {Props} props
 */
export const ErrorDetails = ({ children = null, error, translate = true }: Props) => {
  const {
    image,
    detail,
    title,
    status,
    trace = [],
  } = {
    ...ERRORS_CONFIG.errorsTypes.__defaultError,
    ...error,
  };

  return (
    <section className={errorCls} style={{ backgroundImage: `url(${image})` }}>
      <div className={errorMainCls}>
        <div className={errorLogoCls}>
          {translate ? <Logo external /> : <LogoStatic external />}
        </div>
        <Heading tag="h2" align="center">
          {status ? `${status} - ` : ''}
          {translate ? <FormattedMessage id={title} defaultMessage={title} /> : title}
        </Heading>
        {detail && (
          <Paragraph>
            {translate && typeof detail === 'string' ? (
              <FormattedMessage id={detail} defaultMessage={detail} />
            ) : (
              detail
            )}
          </Paragraph>
        )}
        {children}
      </div>
      {DEBUG_CONFIG.traceback && trace ? (
        <Paragraph>
          {isArray(trace)
            ? trace.map((item, idx) => (
                <React.Fragment key={idx}>
                  {typeof item !== 'object'
                    ? item
                    : Object.keys(item)
                        .map((key) => `${key}: ${item[key]}`)
                        .join(', ')}
                  <br />
                </React.Fragment>
              ))
            : trace}
        </Paragraph>
      ) : null}
    </section>
  );
};
