// @flow

import get from 'lodash/get';
import { store as CONFIG_STORE } from 'Config';

/**
 * selectMe
 *
 * @param {Object} state
 * @returns {?Object}
 */
export function selectMe(state: Object): Object {
  return state[CONFIG_STORE.keys.me];
}

/**
 * selectMeStatus
 *
 * @param {state} Object
 * @returns {Object}
 */
export function selectMeStatus(state: Object): Object {
  return get(state[CONFIG_STORE.keys.me], 'status');
}

/**
 * selectMeLoading
 *
 * @param {state} Object
 * @returns {boolean}
 */
export function selectMeLoading(state: Object): boolean {
  return !!get(state[CONFIG_STORE.keys.me], 'status.loading');
}

/**
 * selectMeLoaded
 *
 * @param {state} Object
 * @returns {boolean}
 */
export function selectMeLoaded(state: Object): boolean {
  return !!get(state[CONFIG_STORE.keys.me], 'status.loaded');
}

/**
 * selectMeHasError
 *
 * @param {state} Object
 * @returns {boolean}
 */
export function selectMeHasError(state: Object): boolean {
  return !!get(state[CONFIG_STORE.keys.me], 'status.error');
}
