// @flow

import get from 'lodash/get';
import queryString from 'query-string';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { getLocaleConfig, parseHash } from 'shared_services/riseart/utils/RouteUtils';

export const URL_PARTS = { QUERY: 'query', HASH: 'hash' };

/**
 * UrlTranslator
 *
 * Url query string (search) and hash translator service
 */
export const UrlTranslator: Object = {
  locale: (getLocaleConfig(true, 'isDefault') || {}).name,
  translations: null,
  config(locale: string) {
    if (locale) {
      this.locale = locale;
    }

    this.translations = LocalizedConfig.get('navigation.uri');
  },
  reverseTranslations(inputTranslations?: Object) {
    const translations = inputTranslations || this.translations;
    return Object.keys(translations).reduce((accumulator, parts) => {
      const items = translations[parts];
      accumulator[parts] = {
        params: Object.keys(items.params).reduce(
          (acc, partKey) => ({ ...acc, [items.params[partKey]]: partKey }),
          {},
        ),
        values: Object.keys(items.values || {}).reduce(
          (acc, partKey) => ({ ...acc, [items.values[partKey]]: partKey }),
          {},
        ),
      };

      return accumulator;
    }, {});
  },
  translate(translations, urlParams: string | Object, urlPart: 'query' | 'hash' = URL_PARTS.QUERY) {
    let params: Object = null;

    switch (urlPart) {
      case URL_PARTS.QUERY:
        params = typeof urlParams === 'string' ? queryString.parse(urlParams) : urlParams;
        break;
      case URL_PARTS.HASH:
        params =
          typeof urlParams === 'string'
            ? parseHash(urlParams)
            : (typeof urlParams === 'object' && urlParams) || null;
        break;
      default:
        return urlParams;
    }

    if (!params) {
      return urlParams;
    }

    return Object.keys(params).reduce((accumulator: Object, paramKey: string) => {
      let decodedParam = null;

      try {
        // Decode the param if encoded
        decodedParam = decodeURIComponent(params[paramKey]);
      } catch (error) {
        // The param was not encoded and because of that it does not need to be decoded
        if (error instanceof URIError) {
          decodedParam = params[paramKey];
        }
      }

      return {
        ...accumulator,
        [get(translations, `[${urlPart}].params[${paramKey}]`, paramKey)]:
          params[paramKey] &&
          get(translations, `[${urlPart}].values[${params[paramKey]}]`, decodedParam),
      };
    }, {});
  },
  translateFrom(urlParams: string | Object, urlPart: 'query' | 'hash' = 'query', locale?: Object) {
    const translations =
      (locale &&
        locale.name &&
        // $FlowFixMe
        LocalizedConfig.get('navigation.uri', locale.name)) ||
      this.translations;

    return this.translate(this.reverseTranslations(translations), urlParams, urlPart);
  },
  translateTo(urlParams: string | Object, urlPart: 'query' | 'hash' = 'query', locale?: Object) {
    const translations =
      (locale &&
        locale.name &&
        // $FlowFixMe
        LocalizedConfig.get('navigation.uri', locale.name)) ||
      this.translations;
    return this.translate(translations || this.translations, urlParams, urlPart);
  },
};
