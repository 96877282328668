// @flow

import get from 'lodash/get';
import pick from 'lodash/pick';
import values from 'lodash/values';
import isUndefined from 'lodash/isUndefined';
import { application as CONFIG_APP } from 'Config';
import { mailingList as MAILING_LIST_ENUM, user as USER_ENUM, cart as CART_ENUM } from 'Enum';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { generateSrcForCDN } from 'shared_components/common/artdirection/picture/Picture';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';

/**
 * collectPaymentMethod
 *
 * @param {Object} data
 * @returns {string}
 */
export function collectPaymentMethod(data: Object): string | null {
  const usedPaymentMethods = [];

  // Using discount for full amount
  if (data.totalAmount === 0 && !data.creditsAmount) {
    return CART_ENUM.payment.method.METHOD_FREE;
  }

  // Using credits for full amount
  if (data.totalAmount === 0 && data.creditsAmount) {
    return CART_ENUM.payment.method.METHOD_CREDIT;
  }

  if (data.selectedPaymentCard && data.selectedPaymentCard.method) {
    // Set selected method from saved card
    usedPaymentMethods.push(data.selectedPaymentCard.method);
  } else if (data.enteredCardType) {
    // Set selected method from entered card
    usedPaymentMethods.push(CART_ENUM.payment.method.METHOD_STRIPE);
  } else if (data.paymentMethod) {
    // Set selected method from api
    usedPaymentMethods.push(data.paymentMethod);
  }

  // Using credit
  if (data.creditsAmount) {
    usedPaymentMethods.push(CART_ENUM.payment.method.METHOD_CREDIT);
  }

  return usedPaymentMethods.length ? usedPaymentMethods.join('/') : null;
}

/**
 * mapProductOptions
 *
 * @param {Array<Object>} options
 * @returns {Array<Object>}
 */
function mapProductOptions(options: Array<Object>): Array<Object> | null {
  return options
    ? options.map(({ id, type, title, values, selected = false }) => ({
        id,
        type,
        title,
        selected,
        values: values
          ? values.map(({ id, title, price, selected = false }) => ({ id, title, price, selected }))
          : null,
      }))
    : null;
}

/**
 * prepareGtmMe
 *
 * @param {Object} me
 * @returns {Object}
 */
export function prepareGtmMe(me: Object): Object {
  const authData = {
    identity: null,
    visitor: {},
  };

  const visitor = pick(me.visitor, [
    'id',
    'role',
    'email',
    'cohort',
    'firstName',
    'isCustomer',
    'language',
    'locale',
    'storeCode',
    'ipAddress',
    'userAgent',
    'countryCode',
    'shippingCountryCode',
    'created',
  ]);
  const user = pick(me.user, [
    'id',
    'role',
    'email',
    'firstName',
    'lastName',
    'lastLogin',
    'city',
    'phone',
    'birthDate',
    'ageRange',
    'gender',
    'timeZone',
    'quizDate',
  ]);

  const subscriptionsList = {};
  values(MAILING_LIST_ENUM.type).forEach((item) => {
    subscriptionsList[item] = false;
  });

  values(get(USER_ENUM.privacySetting, 'name', [])).forEach((item) => {
    subscriptionsList[item] = false;
  });

  authData.visitor = {
    id: visitor.id,
    role: visitor.role,
    email: visitor.email,
    cohort: visitor.cohort,
    customer: visitor.isCustomer,
    language: visitor.language,
    locale: visitor.locale,
    store: visitor.storeCode,
    ipAddress: visitor.ipAddress,
    userAgent: visitor.userAgent,
    country: visitor.countryCode,
    shipTo: visitor.shippingCountryCode,
    created: visitor.created,
  };
  if (me.user !== null) {
    authData.identity = {
      ...user,
      registered: get(me.user, 'registrationDate'),
      country: get(me.user, 'countryCode'),
      quizProfile: get(me.user, 'quizPersonality'),
      subscriptions: {
        newsletter: false,
        general: false,
        activityUpdates: false,
        activityWeekly: false,
      },
    };
    if (get(me.visitor, 'emailSubscriptions')) {
      me.visitor.emailSubscriptions.forEach((item) => {
        subscriptionsList[item.type] =
          item.status === MAILING_LIST_ENUM.status.STATUS_ACTIVE
            ? true
            : subscriptionsList[item.type];
      });
    }
    if (get(me.user, 'privacySettings')) {
      me.user.privacySettings.forEach((item) => {
        subscriptionsList[item.name] = JSON.parse(item.value);
      });
    }

    const {
      newsletter,
      general,
      activity_update: activityUpdates,
      activity_weekly: activityWeekly,
    } = subscriptionsList;

    authData.identity = {
      ...authData.identity,
      subscriptions: {
        newsletter,
        general,
        activityUpdates,
        activityWeekly,
      },
    };
  }
  return authData;
}

/**
 * prepareGtmArtwork
 *
 * @param {Object} artwork
 * @param {string} store
 * @returns {Object}
 */
export function prepareGtmArtwork(artwork: Object, store?: string): Object | null {
  if (!artwork) {
    return null;
  }

  const origin = LocationManager.get('origin');
  const artistId = artwork.artistId || (artwork.artist && artwork.artist.id) || null;
  const artistAlias = artwork.artistAlias || (artwork.artist && artwork.artist.alias) || null;
  const masterImage =
    extractImageFileFromData.artImage(artwork.images, 'TYPE_MAIN_MASTER', true) ||
    extractImageFileFromData.artImage(artwork.images, 'TYPE_FLAT_MASTER', true) ||
    null;
  const squareImage =
    extractImageFileFromData.artImage(artwork.images, 'TYPE_MAIN_SQUARE', true) ||
    extractImageFileFromData.artImage(artwork.images, 'TYPE_FLAT_SQUARE', true) ||
    null;
  const productSku =
    artwork.productPrimarySku ||
    (artwork.productSkus && artwork.productSkus.filter((type) => type === 'ART')[0]);
  const [skuBuyStore] =
    (productSku && store && productSku.stores.filter(({ storeCode }) => storeCode === store)) || [];

  return {
    id: artwork.id || null,
    type: artwork.isProduct ? 'product' : 'art',
    title: artwork.title || null,
    medium: artwork.medium || null,
    subMedium: artwork.subMedium || null,
    subject: artwork.subject || null,
    subSubject: artwork.subSubject || null,
    style: artwork.style || null,
    url: UrlAssembler.artDetail(artwork.id, artwork.slug, { origin }) || null,
    masterImageUrl: generateSrcForCDN({ ...masterImage, width: 1200, height: 'AUTO' }) || null,
    squareImageUrl: generateSrcForCDN({ ...squareImage, width: 1200, height: 'AUTO' }) || null,
    artistId: artistId || null,
    artistAlias: artistAlias || null,
    artistName: artwork.artistName || (artwork.artist && artwork.artist.name) || null,
    artistUrl:
      artistId && artistAlias
        ? UrlAssembler.artistProfile(artistId, artistAlias, { origin })
        : null,
    productId: artwork.productId || null,
    productType: artwork.productType || null,
    productSku: (productSku && productSku.sku) || null,
    productPrice:
      artwork.price || artwork.skuBuyPrice || (skuBuyStore && skuBuyStore.price) || null,
    productCurrency:
      artwork.currency ||
      (artwork.storeCode && CONFIG_APP.i18n.currency.codes[artwork.storeCode]) ||
      (skuBuyStore && skuBuyStore.currency) ||
      null,
    productOptions: mapProductOptions(
      artwork.options || (productSku && productSku.options && productSku.options),
    ),
    sellerId:
      artwork.sellerId || artwork.skuPrimarySellerId || (productSku && productSku.sellerId) || null,
    sellerName:
      artwork.sellerName ||
      artwork.skuPrimarySellerName ||
      (productSku && productSku.sellerName) ||
      null,
    sellerType:
      artwork.sellerType ||
      artwork.skuPrimarySellerType ||
      (productSku && productSku.sellerType) ||
      null,
  };
}

/**
 * prepareGtmArtist
 *
 * @param {Object} artist
 * @returns {Object}
 */
export function prepareGtmArtist(artist: Object): Object | null {
  if (!artist) {
    return null;
  }

  return {
    id: artist.id,
    url:
      UrlAssembler.artDetail(artist.id, artist.alias, { origin: LocationManager.get('origin') }) ||
      null,
    name: artist.name || null,
    birthYear: !isUndefined(artist.birthYear) ? artist.birthYear : null,
    birthCity: artist.birthCity || null,
    birthCountry: artist.birthCountry || null,
    livesCity: artist.livesCity || null,
    livesCountry: artist.livesCountry || null,
    score: !isUndefined(artist.score) ? artist.score : null,
    mediums: artist.mediums || null,
    styles: artist.styles || null,
    ...(artist.primaryArt ? { primaryArt: prepareGtmArtwork(artist.primaryArt) || null } : null),
  };
}

/**
 * prepareGtmCartItem
 *
 * @param {Object} cartItem
 * @param {Object} cart
 * @returns {Object}
 */
function prepareGtmCartItem(cartItem: Object, cart: Object): Object {
  return {
    ...prepareGtmArtwork({
      id: cartItem.artId,
      isProduct: true,
      title: cartItem.artTitle,
      medium: cartItem.artMedium,
      subMedium: cartItem.artSubMedium,
      subject: cartItem.artSubject,
      subSubject: cartItem.artSubSubject,
      style: cartItem.artStyle,
      slug: cartItem.artSlug,
      images: cartItem.artImages,
      artistName: cartItem.artistName,
      artistAlias: cartItem.artistAlias,
      artistId: cartItem.artistId,
      productId: cartItem.productId,
      productType: cartItem.productType,
      currency: cartItem.currency,
      sellerId: cartItem.sellerId,
      sellerName: cartItem.sellerName,
      sellerType: cartItem.sellerType,
      options: cartItem.options,
    }),
    productSku: cartItem.skuSku,
    cartId: (cart && cart.id) || null,
    itemId: cartItem.id || null,
    itemQty: !isUndefined(cartItem.qty) ? cartItem.qty : null,
    itemPrice: !isUndefined(cartItem.price) ? cartItem.price : null,
    itemTotal: !isUndefined(cartItem.total) ? cartItem.total : null,
    itemCurrency: cartItem.currency || null,
  };
}

/**
 * prepareGtmCartModifiedItem
 *
 * @param {Object} cartItem
 * @param {Object} cart
 * @returns {Object}
 */
function prepareGtmCartModifiedItem(cartItem: Object, cart: Object): Object {
  const itemTotal = cartItem.modifiedQty * cartItem.price;

  return {
    ...prepareGtmCartItem(cartItem, cart),
    itemQty: !isUndefined(cartItem.modifiedQty) ? cartItem.modifiedQty : null,
    itemPrice: !isUndefined(cartItem.price) ? cartItem.price : null,
    itemTotal: Number.isNaN(itemTotal) ? null : itemTotal,
  };
}

/**
 * prepareGtmCartData
 *
 * @param {Object} cartData
 * @returns {Object | null}
 */
export function prepareGtmCartData(cartData: Object): Object | null {
  if (!cartData) {
    return null;
  }

  return {
    id: cartData.id || null,
    currency: cartData.currency || null,
    couponCode: cartData.couponCode || null,
    customerId: cartData.customerId || null,
    customerEmail: cartData.customerEmail || null,
    customerPhone: (cartData.billingAddress && cartData.billingAddress.telephone) || null,
    creditsAmount: !isUndefined(cartData.creditsAmount) ? cartData.creditsAmount : null,
    discountAmount: !isUndefined(cartData.discountAmount) ? cartData.discountAmount : null,
    shippingAmount: !isUndefined(cartData.shippingAmount) ? cartData.shippingAmount : null,
    itemsCount: !isUndefined(cartData.itemsCount) ? cartData.itemsCount : null,
    itemsQty: !isUndefined(cartData.itemsQty) ? cartData.itemsQty : null,
    rental: !!cartData.rental,
    storeCode: cartData.storeCode || null,
    shippingCountryCode: (cartData.shippingAddress && cartData.shippingAddress.countryCode) || null,
    subtotalAmount: !isUndefined(cartData.subtotalAmount) ? cartData.subtotalAmount : null,
    totalAmount: !isUndefined(cartData.totalAmount) ? cartData.totalAmount : null,
    useCredit: !!cartData.useCredit,
    paymentMethod: collectPaymentMethod(cartData),
    paymentType: cartData.paymentType || null,
    paymentCardType:
      cartData.savedPaymentCardType ||
      (cartData.selectedPaymentCard && cartData.selectedPaymentCard.cardType) ||
      cartData.paymentCardType ||
      null,
    modifiedItem:
      (cartData.modifiedItem && prepareGtmCartModifiedItem(cartData.modifiedItem, cartData)) ||
      null,
    items:
      (cartData.cartItems &&
        cartData.cartItems.map((item) => prepareGtmCartItem(item, cartData))) ||
      null,
  };
}

/**
 * prepareGtmCartItem
 *
 * @param {Object} orderItem
 * @param {Object} order
 * @returns {Object}
 */
function prepareGtmOrderItem(orderItem: Object, order: Object): Object {
  return {
    ...prepareGtmArtwork({
      id: orderItem.artId,
      isProduct: true,
      title: orderItem.artTitle,
      medium: orderItem.artMedium,
      subMedium: orderItem.artSubMedium,
      subject: orderItem.artSubject,
      subSubject: orderItem.artSubSubject,
      style: orderItem.artStyle,
      slug: orderItem.artSlug,
      images: orderItem.artImages,
      artistName: orderItem.artistName,
      artistAlias: orderItem.artistAlias,
      artistId: orderItem.artistId,
      productId: orderItem.productId,
      productType: orderItem.productType,
      currency: orderItem.currency,
      sellerId: orderItem.sellerId,
      sellerName: orderItem.sellerName,
      sellerType: orderItem.sellerType,
      options:
        (orderItem.options &&
          orderItem.options.map(({ values, ...restOptionData }) => ({
            ...restOptionData,
            selected: true,
            values: values
              ? values.map((optionValue) => ({ ...optionValue, selected: true }))
              : null,
          }))) ||
        null,
    }),
    productSku: orderItem.skuSku,
    orderId: (order && order.id) || null,
    itemId: orderItem.id || null,
    itemQty: !isUndefined(orderItem.qty) ? orderItem.qty : null,
    itemPrice: !isUndefined(orderItem.price) ? orderItem.price : null,
    itemTotal: !isUndefined(orderItem.total) ? orderItem.total : null,
    itemCurrency: orderItem.currency || null,
  };
}

/**
 * prepareGtmOrderData
 *
 * @param {Object} order
 * @returns {Object}
 */
export function prepareGtmOrderData(order: Object): Object | null {
  if (!order) {
    return null;
  }

  return {
    id: order.id || null,
    currency: order.currency || null,
    couponCode: order.couponCode || null,
    customerId: order.customerId || null,
    customerEmail: order.email || null,
    customerPhone: (order.billingAddress && order.billingAddress.telephone) || null,
    creditsAmount: !isUndefined(order.creditsAmount) ? order.creditsAmount : null,
    discountAmount: !isUndefined(order.discountAmount) ? order.discountAmount : null,
    shippingAmount: !isUndefined(order.shippingAmount) ? order.shippingAmount : null,
    itemsCount: (order.orderItems && order.orderItems.length) || 0,
    itemsQty:
      (order.orderItems &&
        order.orderItems.length &&
        order.orderItems.reduce((accumulator, { qty }) => accumulator + qty, 0)) ||
      0,
    rental: !!order.rental,
    storeCode: order.storeCode || null,
    shippingCountryCode: (order.shippingAddress && order.shippingAddress.countryCode) || null,
    subtotalAmount: !isUndefined(order.subtotalAmount) ? order.subtotalAmount : null,
    totalAmount: !isUndefined(order.totalAmount) ? order.totalAmount : null,
    paymentMethod: collectPaymentMethod(order),
    paymentType: order.paymentType || null,
    paymentCardType: order.paymentCardType || order.savedPaymentCardType || null,
    items:
      (order.orderItems && order.orderItems.map((item) => prepareGtmOrderItem(item, order))) ||
      null,
  };
}

/**
 * prepareGtmFavoriteArtwork
 *
 * @param {Object} artwork
 * @returns {Object}
 */
export function prepareGtmFavoriteArtwork(artwork: Object): Object {
  return prepareGtmArtwork({
    id: artwork.artId,
    type: artwork.artIsProduct,
    title: artwork.artTitle,
    medium: artwork.artMedium,
    subMedium: artwork.artSubMedium,
    subject: artwork.artSubject,
    subSubject: artwork.artSubSubject,
    style: artwork.artStyle,
    slug: artwork.artSlug,
    images: null,
    artistName: artwork.artistName,
    artistAlias: artwork.artistAlias,
    artistId: artwork.artistId,
    productId: artwork.productId,
    productType: artwork.productType,
    skuPrimaryId: artwork.productSku,
    currency: artwork.productSkuCurrency,
    skuBuyPrice: artwork.productSkuPrice,
    sellerId: artwork.sellerId,
    sellerName: artwork.sellerName,
    sellerType: artwork.sellerType,
  });
}
