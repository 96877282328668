// @flow

import React from 'react';
import type { Node } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import { stringifyHash } from '@riseart/fe-utils';
import { UrlTranslationsContext } from 'shared_data/providers/url/TanslationsContext';
import { URL_PARTS, UrlTranslator } from 'shared_services/riseart/url/Translator';

type Props = {
  locale: string,
  children: Function,
};

/**
 * UrlTranslationsProvider
 *
 * @param {Props} props
 * @returns
 */
export function UrlTranslationsProvider({ locale, children }: Props): Node {
  UrlTranslator.config(locale);
  const location = useLocation();
  const { hash, pathname, search } = location;
  const translatedQueryString = querystring.stringify(
    UrlTranslator.translateFrom(search, URL_PARTS.QUERY),
  );
  const translatedSearch = translatedQueryString ? `?${translatedQueryString}` : '';
  const translatedHash = stringifyHash(UrlTranslator.translateFrom(hash, URL_PARTS.HASH));
  const contextValue = {
    url: pathname,
    query: translatedSearch,
    hash: translatedHash,
    translator: UrlTranslator,
    translatedLocation: { ...location, search: translatedSearch, hash: translatedHash },
  };

  return (
    <UrlTranslationsContext.Provider value={contextValue}>
      {children(contextValue)}
    </UrlTranslationsContext.Provider>
  );
}
