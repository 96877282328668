// @flow

import React from 'react';

/**
 * DOMTagUtils
 *
 * service class that creates/replaces/deletes/finds tag
 */
export class DOMTagUtils {
  /**
   * findTag
   *
   * @param {Object} tag data
   * @param {Object} parentEl
   */
  static findTag({ tag, attributes = {}, metaType }, parentEl = document) {
    const tagAttributesModifier = {
      linkHreflang: (attr) => {
        const { href, ...restAttributes } = attr;

        return restAttributes;
      },
    };

    const attributesSelector = Object.keys(
      typeof tagAttributesModifier[metaType] === 'function'
        ? tagAttributesModifier[metaType](attributes)
        : attributes,
    ).reduce((result, attrName) => {
      result += `[${attrName}="${attributes[attrName]}"]`;

      return result;
    }, '');

    return parentEl.querySelector(`${tag}${attributesSelector}`);
  }

  /**
   * setTagContent
   *
   * @param {Object} el
   * @param {Object} tag data
   */
  static setTagContent(el, { attributes = {}, additionalAttributes = {}, textNode }) {
    if (!el) {
      return;
    }

    const combinedAttributes = { ...attributes, ...additionalAttributes };

    Object.keys(combinedAttributes).forEach((attrName) => {
      el.setAttribute(attrName, combinedAttributes[attrName]);
    });

    if (textNode) {
      el.appendChild(
        document.createTextNode(typeof textNode === 'string' ? textNode : JSON.stringify(textNode)),
      );
    }

    return el;
  }

  /**
   * createTag
   *
   * @param {Object} tagData
   */
  static createTag(tagData, parentEl = document) {
    const el = document.createElement(tagData.tag);
    DOMTagUtils.setTagContent(el, tagData);
    parentEl.appendChild(el);
  }

  /**
   * deleteTag
   *
   * @param {Object} tagData
   * @param {Object} parentEl
   */
  static deleteTag(tagData, parentEl = document) {
    const el = DOMTagUtils.findTag(tagData, parentEl);

    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }

  /**
   * deleteMultipleTags
   *
   * @param {string} type
   * @param {string} selector
   * @param {Object} parentEl
   * @returns {boid}
   */
  static deleteMultipleTags(type: string, parentEl: Object = document, selector: string = null) {
    const META_TYPE_SELECTORS = {
      linkHreflang: 'link[hreflang][rel="alternate"]',
    };

    // Selector or type that has predefined seleector is required
    if (!selector && !META_TYPE_SELECTORS[type]) {
      return;
    }

    const nodes = parentEl.querySelectorAll(selector || META_TYPE_SELECTORS[type]);
    const countNodes = nodes.length;

    if (!countNodes) {
      return;
    }

    // Loop over the NodeList and remove any nodes that are in DOM
    for (let i = 0; i < countNodes; i += 1) {
      const node = nodes[i];

      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
      }
    }
  }

  /**
   * updateTag
   *
   * @param {Object} tagData
   * @param {Object} parentEl
   */
  static updateTag(tagData, parentEl = document) {
    const el = DOMTagUtils.findTag(tagData, parentEl);

    if (!el) {
      return DOMTagUtils.createTag(tagData, parentEl);
    }

    while (el.firstChild) {
      el.removeChild(el.firstChild);
    }

    DOMTagUtils.setTagContent(el, tagData);
  }

  /**
   * renderToString
   *
   * @param {Object} tagData
   */
  static renderToString({ tag, attributes = {}, additionalAttributes = {}, textNode }: Object) {
    const combinedAttributes = { ...attributes, ...additionalAttributes };
    const attr = Object.keys(combinedAttributes).map(
      (attrName) => `${attrName}="${combinedAttributes[attrName]}"`,
    );
    const content = textNode && typeof textNode === 'string' ? textNode : JSON.stringify(textNode);

    return `<${tag}${attr.length ? ` ${attr.join(' ')}` : ''}${
      content ? `>${content}</${tag}>` : ` />`
    }`;
  }

  static convertHtmlAttrToProps(attribute: string) {
    const PROPS = { hreflang: 'hrefLang' };

    return PROPS[attribute] || attribute;
  }

  /**
   * renderToElements
   *
   * @param {Object} tagData
   * @returns {Object}
   */
  static renderToElements(
    { tag: Tag, attributes = {}, additionalAttributes = {}, textNode }: Object,
    idx: number,
  ): Object {
    const combinedAttributes = { ...attributes, ...additionalAttributes };
    const elementProps: Object = Object.keys({
      ...attributes,
      ...additionalAttributes,
    }).reduce(
      (accumulator, attrName) => ({
        ...accumulator,
        [DOMTagUtils.convertHtmlAttrToProps(attrName)]: combinedAttributes[attrName],
      }),
      {},
    );

    const content = textNode && typeof textNode === 'string' ? textNode : JSON.stringify(textNode);

    // Render title tag
    if (Tag === 'title') {
      return (
        <Tag key={idx} {...elementProps}>
          {content}
        </Tag>
      );
    }

    // Render tag
    return (
      <Tag
        key={idx}
        {...elementProps}
        {...(content ? { dangerouslySetInnerHTML: { __html: content } } : null)}
      />
    );
  }
}
