// @flow
import get from 'lodash/get';
import { APP_STORE_ACTIONS, GUI_ACTIONS } from 'shared_services/redux/actions/container';
import { Client as ApolloClient } from 'shared_services/apollo/client';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { cookies as CONFIG_COOKIES } from 'Config';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';

/**
 * MiddlewareApplication
 */
export class MiddlewareApplication {
  /**
   * Redux Middleware
   *
   * @param store
   */
  static middleware: Function =
    (store: Object) =>
    (next: Function) =>
    (action: Object): void => {
      switch (action.type) {
        case APP_STORE_ACTIONS.UPDATE_LOCALES: {
          const { locales } = action.payload;
          const prevLocale = localeSelector(store.getState(), 'userLocale');

          if (prevLocale && locales.userLocale && prevLocale.name !== locales.userLocale.name) {
            ApolloClient.getInstance().resetStore();
          }

          break;
        }
        case GUI_ACTIONS.UPDATE_RECENT_ART_WIDGET: {
          const isOpened = get(action, 'payload.value.opened', false);
          const { recentArtworksWidget: RECENT_ARTWORKS_COOKIE } = CONFIG_COOKIES;
          const cookieValue = Cookies.get(RECENT_ARTWORKS_COOKIE.name);
          const widgetCookie = cookieValue ? JSON.parse(cookieValue) : {};

          Cookies.set(
            RECENT_ARTWORKS_COOKIE.name,
            JSON.stringify({ ...widgetCookie, closed: !isOpened }),
            RECENT_ARTWORKS_COOKIE,
          );
          break;
        }
        default:
          break;
      }
      next(action);
    };
}
