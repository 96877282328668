export const MARKETING_ACTIONS = {
  CAMPAIGN_READ_FETCH_LOADING: 'CAMPAIGN_READ_FETCH_LOADING',
  CAMPAIGN_READ_FETCH_DONE: 'CAMPAIGN_READ_FETCH_DONE',
  CAMPAIGN_READ_FETCH_ERROR: 'CAMPAIGN_READ_FETCH_ERROR',
  CAMPAIGN_MATCHED: 'CAMPAIGN_MATCHED',
};

// Action Creators
export function campaignReadFetchLoading() {
  return {
    type: MARKETING_ACTIONS.CAMPAIGN_READ_FETCH_LOADING,
  };
}

export function campaignReadFetchDone(payload) {
  return {
    type: MARKETING_ACTIONS.CAMPAIGN_READ_FETCH_DONE,
    payload,
  };
}

export function campaignReadFetchError(error) {
  return {
    type: MARKETING_ACTIONS.CAMPAIGN_READ_FETCH_ERROR,
    payload: { ...error },
  };
}

export function campaignMatched(payload) {
  return {
    type: MARKETING_ACTIONS.CAMPAIGN_MATCHED,
    payload,
  };
}
