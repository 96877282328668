// @flow

import get from 'lodash/get';
import { store as CONFIG_STORE } from 'Config';
import { isSubscribed } from 'shared_services/riseart/utils/Utils';

/**
 * selectVisitorProperty
 *
 * @param {Object} state
 * @param {string} property
 * @returns {Function}
 */
export function selectVisitorProperty(property?: string): Function {
  return (state: Object): ?Object => {
    const me = state[CONFIG_STORE.keys.me];
    const visitor = me && me.data && me.data.visitor;

    if (!visitor) {
      return null;
    }

    return property ? visitor[property] : visitor;
  };
}

/**
 * selectVisitorId
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectVisitorId(state: Object): ?string {
  return get(state[CONFIG_STORE.keys.auth], 'data.payload.visitor_id') || null;
}

/**
 * selectVisitorLocale
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectVisitorLocale(state: Object): ?string {
  return get(state[CONFIG_STORE.keys.auth], 'data.payload.locale') || null;
}

/**
 * selectVisitorCountryCode
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectVisitorCountryCode(state: Object): ?string {
  return get(state[CONFIG_STORE.keys.me], 'data.visitor.countryCode') || null;
}

/**
 * selectVisitorIsSubscribed
 *
 * @param {string} subscriptionType
 * @returns {Function}
 */
export function selectVisitorIsSubscribed(subscriptionType?: string): Function {
  return function isVisitorSubscribed(state: Object): boolean {
    const emailSubscriptions =
      get(state[CONFIG_STORE.keys.me], 'data.visitor.emailSubscriptions') || null;

    return emailSubscriptions ? isSubscribed(emailSubscriptions, subscriptionType) : false;
  };
}
