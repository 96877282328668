// @flow

import { errors as ERRORS_ENUM } from 'Enum';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { ERRORS_ACTIONS } from 'shared_services/redux/actions/container';

/**
 * MiddlewareErrors
 * listens to errors in store and takes actions
 */
export class MiddlewareError {
  /**
   * MiddlewareError
   *
   * @param store
   */
  static middleware = (store: Object) => (next: Function) => (action: Object) => {
    if (action.type === ERRORS_ACTIONS.ERROR_ADD) {
      try {
        ErrorService.dispatchHandler(store.dispatch)(action.payload);
      } catch (error) {
        next(
          errorAdd(ErrorService.mapJSError(error, { handler: ERRORS_ENUM.handlers.ERROR_PAGE })),
        );
        return;
      }
    }

    next(action);
  };
}
