// @flow

import BrowserCookie from 'js-cookie';
import { ServerCookie } from 'shared_services/riseart/utils/Cookies/Server';
import { LocationManager } from 'shared_services/riseart/url/Location';

/**
 * cookieExpirationFromDays: utility function for setting
 * expiration property for cookies based on input days
 *
 * @param {number} days
 * @param {number} startFrom
 * @returns Date object
 */
export function cookieExpirationFromDays(days: number = 0, startFrom: number = Date.now()): Object {
  return new Date(startFrom + days * 24 * 60 * 60 * 1000);
}

/**
 * Cookies: Isomorphic cookie library that uses wither js-cookie or ServerCookie
 * to work with cookies on server and client exposing the same object api
 */
export const Cookies: Object = {
  httpRequest: null,
  httpResponse: null,
  lib: null,

  /**
   * config
   *
   * @param {*} httpRequest
   * @param {*} httpResponse
   */
  config(httpRequest?: Object, httpResponse?: Object) {
    if (httpRequest && httpResponse) {
      if (typeof httpRequest !== 'object' || typeof httpResponse !== 'object') {
        throw new Error(
          'Invalid use of Cookie Library on server. Please provide a valid httpRequest and httpResponse objects',
        );
      }
      if (typeof httpRequest.cookies !== 'object') {
        throw new Error(
          'Invalid use of Cookie Library on server. Please make sure you are using the cookie-parser middleware for express',
        );
      }

      Cookies.httpRequest = httpRequest;
      Cookies.httpResponse = httpResponse;
      Cookies.lib = ServerCookie(httpRequest, httpResponse);
    } else {
      Cookies.lib = BrowserCookie;
    }

    return Cookies.lib;
  },

  /**
   * get
   *
   * @param {string} name
   */
  get(name: string) {
    if (!name) {
      throw new Error('No cookie name passed as parameter to get() method');
    }

    return Cookies.lib && Cookies.lib.get(name);
  },

  /**
   * set
   *
   * @param {string} name
   * @param {*} value
   * @param {Object} options
   * @param {boolean} replaceCookie
   */
  set(name: string, value: any, options: Object = {}, replaceCookie = false) {
    if (!name) {
      throw new Error('No cookie name set when creating a new cookie');
    }

    // By default the httpOnly: false is passed so that the
    // cookies can be accessible not only the web server
    const DEFAULT_OPTIONS = {
      httpOnly: false,
      secure: true,
      domain: LocationManager.get('hostname'),
    };
    const { name: destructName, ...configOptions } = options;
    const cookieOptions = { ...DEFAULT_OPTIONS, ...configOptions };

    if (cookieOptions.expires) {
      cookieOptions.expires = cookieExpirationFromDays(cookieOptions.expires);
    }

    return Cookies.lib && Cookies.lib.set(name, value, cookieOptions, replaceCookie);
  },

  /**
   * remove
   *
   * @param {string} name
   * @param {?Object} options
   */
  remove(name: string, options: ?Object = {}) {
    if (!name) {
      throw new Error('No cookie name passed as parameter to remove() method');
    }
    return (
      Cookies.lib &&
      Cookies.lib.remove(name, { domain: LocationManager.get('hostname'), ...options })
    );
  },
};
