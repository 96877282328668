// @flow

import { v4 } from 'uuid';
import find from 'lodash/find';
import get from 'lodash/get';
import { errors as ERRORS_ENUM } from 'Enum';
import { errors as ERRORS_CONFIG } from 'Config';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { addNotification as actionAddNotification } from 'shared_services/redux/actions/notifications/notifications';

export type ErrorMessageType = {
  status?: number,
  code?: string,
  type?: string,
  title?: string,
  detail?: string,
  trace?: ?Array<string>,
  additional?: Object,
  handler?: string,
  level?: number,
  expire?: number,
  image?: string,
  log?: boolean,
};

export type ErrorPayloadType = ErrorMessageType & { uid: string };

const { errorsTypes: ERROR_TYPES, overwriteErrors: OVERWRITE_ERRORS } = ERRORS_CONFIG;
const { handlers: ERROR_HANDLERS, levels: ERROR_LEVELS } = ERRORS_ENUM;
const INTERNAL_ERROR_TYPES = [
  'EvalError',
  'InternalError',
  'RangeError',
  'ReferenceError',
  'SyntaxError',
  'TypeError',
  'URIError',
];

const JS_ERRORS = [EvalError, RangeError, ReferenceError, SyntaxError, TypeError, URIError];

/**
 * isInstanceOfJSError
 *
 * @param {Object} error
 * @returns {boolean}
 */
export const isInstanceOfJSError = (error: Object): boolean => {
  return JS_ERRORS.some((err) => error instanceof err);
};

/**
 * isErrorOverwritten
 *
 * OVERWRITE_ERRORS is a list of error fields that can to be overwritten based on given match conditions
 * This array needs to have matchConditions key consisting key-value pairs and each key corresponds to the field
 * that it should match and each value should match the value of this field in the initial error For error fields
 * that have multidimensional structure, the key should separate the fields by using '.'
 *
 * This functionality can be used for any errors (API, Graphql, etc..) although for handling GraphQL errors we have
 * an ErrorLink in Apollo that has the filter functionality which can filter every GraphQL error with a more agile approach.
 *
 * For Example if we want to overwrite an error which is logged with type: 'InvalidField' and has a data: { action: { endpoint: '/art'} }
 * then the rule for this should be { matchConditions: { type: 'InvalidField', 'data.action.endpoint': '/arts' } }.
 *
 * @param {Object} errorData
 * @returns {?Object} returns found surpressed error or undefined
 */
const isErrorOverwritten = (errorData: ErrorMessageType): ErrorMessageType => {
  const matched = find(OVERWRITE_ERRORS, ({ matchConditions }) =>
    Object.keys(matchConditions).every((key) => get(errorData, key) === matchConditions[key]),
  );

  return matched ? matched.overwriteFields : {};
};

/**
 * ErrorService
 *
 * A set of error related utilities
 */
export const ErrorService = {
  /**
   * buildPayload
   *
   * @param {ErrorMessageType} error
   * @returns {ErrorPayloadType} error action payload
   */
  buildPayload(error: ErrorMessageType): ErrorPayloadType {
    return {
      uid: v4(),
      ...error,
    };
  },

  /**
   * augmentErrorMessage
   *
   * @param {ErrorMessageType} error
   * @param {Object} config
   * @returns {ErrorMessageType}
   */
  augmentErrorMessage(error: ErrorMessageType, config: Object = ERROR_TYPES): ErrorMessageType {
    const defaultConfig = config.__defaultError || {};
    const typeConfig =
      (INTERNAL_ERROR_TYPES.indexOf(error.type) !== -1
        ? config.__internalError
        : config[error.type]) || {};

    const overwriteError = isErrorOverwritten(error);

    return {
      ...defaultConfig,
      ...typeConfig,
      ...error,
      ...overwriteError,
    };
  },

  /**
   * mapGraphqlError
   *
   * @param {Object} graphqlError
   * @returns {ErrorMessageType} mapped data for error middleware
   */
  mapGraphqlError({
    errorInfo = {},
    errorType,
    message,
    locations,
    path,
    ...additional
  }: Object): ErrorMessageType {
    const errorData = errorInfo || {};
    return ErrorService.augmentErrorMessage({
      code: errorData.code,
      detail: errorData.detail || message,
      status: errorData.status,
      title: errorData.title,
      trace: errorData.trace,
      type: errorData.type || errorType,
      additional: {
        path,
        locations,
        validation: errorData.validation,
        ...additional,
      },
    });
  },

  /**
   * mapJSError
   *
   * @param {Error} jsError
   * @returns {ErrorMessageType} mapped data for error middleware
   */
  mapJSError(
    { name, message, stack, ...restJSProps }: Error = {},
    additionalProps?: ?ErrorMessageType,
  ): ErrorMessageType {
    const { additional: customAdditional, ...customProps } = additionalProps || {};
    const additional = {
      ...restJSProps,
      ...customAdditional,
    };

    return ErrorService.augmentErrorMessage({
      type: get(customProps, 'type') || restJSProps.type || ERROR_TYPES.__internalError.type,
      trace: restJSProps.trace || stack ? stack.split('\n').map((s) => s.trim()) : null,
      title: restJSProps.title || name,
      detail: restJSProps.detail || message || ERROR_TYPES.__internalError.detail,
      ...(restJSProps.code ? { code: restJSProps.code } : {}),
      ...customProps,
      additional,
    });
  },

  /**
   * mapNotification
   *
   * @param {ErrorMessageType} properties
   * @returns {ErrorMessageType} Enhanced ErrorMessageType with new properties
   */
  mapNotification(properties: ErrorMessageType = {}): ErrorMessageType {
    return ErrorService.augmentErrorMessage({
      handler: ERROR_HANDLERS.NOTIFICATION,
      level: ERROR_LEVELS.WARNING,
      ...properties,
    });
  },

  /**
   * dispatchHandler
   *
   * Handler to pass error payload to dispatches. Dispatcher can be any
   * function but in most cases it will be dispatch from Redux store
   *
   * @param {Function} dispatch
   * @returns {Function}
   */
  dispatchHandler:
    (dispatch: Function): Function =>
    (error: Object): void => {
      switch (error.handler) {
        case ERROR_HANDLERS.NOTIFICATION:
          dispatch(ErrorService.handleNotification(error));
          break;
        default:
          break;
      }

      ErrorService.logError(error);
    },

  /**
   * handleNotification
   *
   * Handles notification type errors (shown as notification messages)
   *
   * @param {Object} error
   * @returns {Object} payload to be dispatched to store
   */
  handleNotification({ level, detail, expire }: Object = {}): Object {
    return actionAddNotification({ level, detail, expire });
  },

  /**
   * logError
   *
   * Log error to RiseartLogger if error's log and level are set for specific values
   *
   * @param {Object} error
   * @returns {void}
   */
  logError(error: ErrorMessageType): void {
    const { type, code, detail, level = ERROR_LEVELS.WARNING, log = true } = error;

    if (!log || (log && level >= ERROR_LEVELS.WARNING)) {
      return;
    }

    RiseartLogger.message({
      message: `[${type || 'UnknownType'}][${code || 'UnknownCode'}] ${
        detail || 'No error details set'
      }`,
      level,
      data: { ...error },
    });
  },
};
