// @flow

export const META_ACTIONS = {
  PAGE_META_RESOLVED: 'PAGE_META_RESOLVED',
};

export type ActionType = {
  type: string,
  payload: Object,
};

// Action Creators
export function pageMetaResolved(payload: Object): ActionType {
  return { type: META_ACTIONS.PAGE_META_RESOLVED, payload };
}
