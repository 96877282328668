// @flow

import Url from 'url-parse';
import queryString from 'query-string';
import { meta as META_ENUM, HTTP_STATUS } from 'Enum';
import { application as APP_CONFIG } from 'Config';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { normalizeUri } from 'shared_services/riseart/utils/RouteUtils';

type MetaTransformationType = {
  condition: {
    match: Array<any>,
    params: Array<{ name: string, value: string }>,
  },
  value: any,
};

type HrefLangType = {
  type: string,
  params: Array<Object>,
};

/**
 * transformationHandlers
 */
const transformationHandlers = {
  location: {
    queryString: (params) => {
      const WILDCARD_CHAR = '*';
      const urlQueryString = LocationManager.get('search');
      const qsParams = queryString.parse(urlQueryString);

      // if any of the params name=value pair is true, then apply the meta
      return params.some(
        ({ name, value }) =>
          qsParams[name] &&
          (value === undefined || value === WILDCARD_CHAR || qsParams[name] === value),
      );
    },
  },
};

/**
 * metaValueTransformer
 *
 * @param {Object | string} data
 * @returns {Object | string}
 */
export const metaValueTransformer = (
  data: MetaTransformationType | Object | string,
): Object | string => {
  if (typeof data === 'string') {
    return data;
  }

  if (data && data.condition && data.value) {
    const isMatched = data.condition.match.some((condition) => {
      const [handler, method] = condition.split('.');

      return (
        transformationHandlers[handler] &&
        typeof transformationHandlers[handler][method] === 'function' &&
        transformationHandlers[handler][method](data.condition.params)
      );
    });

    if (isMatched) {
      return data.value;
    }

    return null;
  }
  return data;
};

/**
 * generateHrefLangParams
 *
 * @param {Array<Object>} data
 * @param {Object | Function} mapper?
 * @param {string} type?
 * @returns {HrefLangType}
 */
export function generateHrefLangParams(
  data: Array<Object>,
  mapper?: Object | Function = null,
  type?: string = 'dynamic',
): ?HrefLangType {
  if (!data) {
    return null;
  }

  let params = data;

  if (mapper) {
    const mapperType = typeof mapper;

    if (mapperType === 'function') {
      // Custom mapping of data values
      params = data.map(mapper);
    } else if (mapperType === 'object') {
      // Mapping of field values by provided { key: value } object
      params = data.map((item) => {
        return Object.keys(item).reduce((accumulator, itemKey) => {
          accumulator[mapper[itemKey] || itemKey] = item[itemKey];

          return accumulator;
        }, {});
      });
    }
  }

  return { type, params };
}

/**
 * generateStaticHrefLang
 *
 * @param {string} routeKey
 * @param {params?} Object
 * @returns {Object}
 */
export function generateStaticHrefLang(routeKey: string, params?: ?Object = null): Object {
  return {
    type: 'generated',
    href: APP_CONFIG.i18n.locales.reduce((accumulator, locale) => {
      const localeUrl = UrlAssembler.byRouteKey(routeKey, {
        locale,
        ...params,
      });

      accumulator[locale.name] = localeUrl || null;

      return accumulator;
    }, {}),
  };
}

/**
 * hrefLangComparisonFunc
 *
 * @param {Object} a
 * @param {Object} b
 * @returns {booleann}
 */
const hrefLangComparisonFunc = (a: Object, b: Object): boolean => {
  return (
    a.hasMultipleTags &&
    b.hasMultipleTags &&
    a.metaType === b.metaType &&
    a.attributes.hreflang === b.attributes.hreflang
  );
};

/**
 * checkUniqueTags
 */
export const checkUniqueTags: Object = {
  [META_ENUM.METATYPE.LINK_HREFLANG]: hrefLangComparisonFunc,
  [META_ENUM.METATYPE.LINK_HREFLANG_REDIRECT]: hrefLangComparisonFunc,
};

/**
 * findMetaRedirect
 *
 * @param {Object} redirects
 * @param {Object} data
 * @returns {{ redirectUrl: string, status: number } | null}
 */
export function findMetaRedirect(
  redirects: Object,
  { currentParams, currentLocale, userLocale, location, routeConfigPath }: Object,
): ?{ url: string, status: number } {
  // Do not redirect if path is not defined in router config for the rendered page
  // This means that probably the page should handle any not found pages
  if (routeConfigPath === undefined) {
    return null;
  }

  // No redirects passed
  if (!redirects || (!redirects.locale && !redirects.canonical)) {
    return null;
  }

  // Locale redirect
  // Covers following cases:
  // - there are localeRedirects (based on hreflangRedirects) for the selected user language
  // - language from parsed url (currentParams.lang) is not set and not default
  // - language from parsed url (currentParams.lang) is different than the currentLocale language
  if (
    redirects.locale &&
    redirects.locale[userLocale.language] &&
    (userLocale.language !== currentLocale.language ||
      (currentParams.lang && currentLocale.basePath !== currentParams.lang) ||
      (!currentParams.lang && !currentLocale.isDefault))
  ) {
    const redirectedUrl = normalizeUri(redirects.locale[userLocale.language]);
    return { url: redirectedUrl, status: HTTP_STATUS.MOVED_TEMPORARILY };
  }

  // Canonical redirect
  if (!redirects.canonical) {
    return null;
  }
  const canonicalRedirectUrl = redirects.canonical;
  const parseRedirectedUrl = Url(normalizeUri(canonicalRedirectUrl));

  if (parseRedirectedUrl && location.pathname !== parseRedirectedUrl.pathname) {
    return { url: canonicalRedirectUrl, status: HTTP_STATUS.MOVED_PERMANENTLY };
  }
}

/**
 * getMetaByModule
 * Finds page meta by searching in hierarchical combination of page module (module/controller/action)
 * example: module/controller/action will look for 'module/controller/action' OR 'module/controller' OR 'module'
 *
 * @param {Object} pageMeta
 * @param {string} module
 * @returns {Object | null}
 */
export function getMetaByModule(pageMeta: Object, module: string): Object | null {
  const moduleParts = (typeof module === 'string' && module.split('/')) || [];
  const totalModuleParts = moduleParts.length;
  const [moduleOption1, moduleOption2, moduleOption3] = moduleParts.map((item, idx) => {
    return moduleParts.slice(0, totalModuleParts - idx).join('/');
  });

  return (
    (pageMeta && (pageMeta[moduleOption1] || pageMeta[moduleOption2] || pageMeta[moduleOption3])) ||
    null
  );
}
