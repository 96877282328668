// @flow

import { takeEvery } from 'redux-saga/effects';
import { AUTH_ACTIONS, ME_ACTIONS } from 'shared_services/redux/actions/container';
import { graphqlTokenFetch, graphqlTokenRefresh, graphqlMeRead } from '../sagas/graphql/graphql';

export function* graphqlAuth(): Generator<Function, void, void> {
  yield takeEvery(AUTH_ACTIONS.TOKEN_FETCH, graphqlTokenFetch);
}

export function* graphqlMe(): Generator<Function, void, void> {
  yield takeEvery(ME_ACTIONS.ME_FETCH, graphqlMeRead);
}

export function* graphqlRefreshToken(): Generator<Function, void, void> {
  yield takeEvery(AUTH_ACTIONS.TOKEN_REFRESH, graphqlTokenRefresh);
}
