// @flow

import React from 'react';
import type { Node } from 'react';
import { Query as ApolloQuery } from '@apollo/client/react/components';

/**
 * Query
 *
 * A wrapper for the base Apollo query component
 * The main purpose is to keep the state of previousData while the new data is loaded which was changed in apollo client v3
 * https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#apolloreact-hooks
 *
 * @param {Object} props
 * @returns {Node}
 */
export function Query({ children, ...restProps }: Object): Node {
  return (
    <ApolloQuery {...restProps}>
      {({ data, previousData, loading, ...queryRestProperties }) =>
        children({
          ...queryRestProperties,
          previousData,
          loading,
          rawData: data,
          data: loading && !data ? previousData : data,
        })
      }
    </ApolloQuery>
  );
}
