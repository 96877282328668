// @flow

import { CMS_VIEW_ACTIONS } from 'shared_services/redux/actions/container';

const DEFAULT_CMS_VIEW_STATE = { data: {} };

/**
 * cmsViewReducer
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} New CMS state
 */
export function cmsViewReducer(state: Object = DEFAULT_CMS_VIEW_STATE, action: Object): Object {
  switch (action.type) {
    case CMS_VIEW_ACTIONS.CMS_VIEW_FETCH:
      return {
        ...state,
        data: { ...action.payload },
      };
    default:
      return state;
  }
}
