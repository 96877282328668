import { FILTER_ACTIONS } from 'shared_services/redux/actions/container';
import { DEFAULT_STORE_STATE } from 'shared_services/redux/states/filter';

/**
 * filtersReducer
 * @param state
 * @param action
 * @returns {{filters: {}, pagination: number, sort: null, layout: string}}
 */
export const filtersReducer =
  (domain) =>
  (state = DEFAULT_STORE_STATE[domain], action) => {
    switch (action.type) {
      case FILTER_ACTIONS[domain].UPDATE:
        return { ...state, ...action.payload };

      default:
        return state;
    }
  };
