const DEFAULT_STORE_STATE = {
  data: null,
  error: null,
  status: {
    loading: false,
    loaded: false,
    error: false,
  },
};

export { DEFAULT_STORE_STATE };
