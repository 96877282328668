// Redux stuff
import { combineReducers } from 'redux';
import { store as STORE_CONFIG } from 'Config';
import { filter as FILTER_ENUM } from 'Enum';

// Reducers
import { notificationsReducer } from 'shared_services/redux/reducers/application/notification';
import { pagePreloaderReducer } from 'shared_services/redux/reducers/application/pagepreloader';
import { applicatonStoreReducer } from 'shared_services/redux/reducers/application/store';
import { meReducer } from 'shared_services/redux/reducers/me/me';
import { filtersReducer } from 'shared_services/redux/reducers/application/filters';
import { guiReducer } from 'shared_services/redux/reducers/application/gui';
import { authReducer } from 'shared_services/redux/reducers/auth/auth';
import { favoritesReducer } from 'shared_services/redux/reducers/favorites/favorites';
import { marketingReducer } from 'shared_services/redux/reducers/marketing/marketing';
import { cmsViewReducer } from 'shared_services/redux/reducers/cms/cms';
import { errorsReducer } from 'shared_services/redux/reducers/errors/errors';
import { notFoundReducer } from 'shared_services/redux/reducers/application/notFound';

const { keys: STORE_KEYS_CONFIG } = STORE_CONFIG;

// Create combiner
const reducers = {
  [STORE_KEYS_CONFIG.filterArt]: filtersReducer(FILTER_ENUM.domain.ART),
  [STORE_KEYS_CONFIG.filterCollection]: filtersReducer(FILTER_ENUM.domain.COLLECTION),
  [STORE_KEYS_CONFIG.filterCollectionArt]: filtersReducer(FILTER_ENUM.domain.COLLECTION_ART),
  [STORE_KEYS_CONFIG.filterArtist]: filtersReducer(FILTER_ENUM.domain.ARTIST),
  [STORE_KEYS_CONFIG.filterArtistArt]: filtersReducer(FILTER_ENUM.domain.ARTIST_ART),
  [STORE_KEYS_CONFIG.filterFavoritesArt]: filtersReducer(FILTER_ENUM.domain.FAVORITES_ART),
  [STORE_KEYS_CONFIG.filterFavoritesArtist]: filtersReducer(FILTER_ENUM.domain.FAVORITES_ARTIST),
  [STORE_KEYS_CONFIG.notifications]: notificationsReducer,
  [STORE_KEYS_CONFIG.gui]: guiReducer,
  [STORE_KEYS_CONFIG.auth]: authReducer,
  [STORE_KEYS_CONFIG.me]: meReducer,
  [STORE_KEYS_CONFIG.favorites]: favoritesReducer,
  [STORE_KEYS_CONFIG.marketing]: marketingReducer,
  [STORE_KEYS_CONFIG.cms]: cmsViewReducer,
  [STORE_KEYS_CONFIG.errors]: errorsReducer,
  [STORE_KEYS_CONFIG.pagePreloader]: pagePreloaderReducer,
  [STORE_KEYS_CONFIG.application]: applicatonStoreReducer,
  [STORE_KEYS_CONFIG.notFound]: notFoundReducer,
};

/**
 * Merge reducers
 *
 * @type {Reducer<S>}
 */
export const rootReducer = combineReducers(reducers);
