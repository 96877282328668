// @flow

/**
 * GTMService
 * Service wrapper arround the GTM dataLayer if existing.
 * It also provides interface to extract state and to push initial state into the data layer
 * which is particularly useful for collecting data from server side code into the layer
 * and then set it as initial data to the window.dataLayer
 */
export const GTMService = {
  dataLayer: [],
  /**
   * config
   * @param {Array<Object>} initialData
   * @returns {void}
   */
  config(initialData: Array<Object> = []): void {
    let clientDataLayer = [];

    if (typeof window === 'object') {
      // Rise Art Consent Library Setup
      window.riseartConsentListenersStack = [];
      window.riseartConsentRegisterListener = (callback) => {
        window.riseartConsentListenersStack.push(callback);
      };

      // first initialize window.dataLayer if not existing yet
      window.dataLayer = window.dataLayer || [];
      clientDataLayer = window.dataLayer;
    }

    GTMService.dataLayer = clientDataLayer;
    GTMService.push(initialData);
  },
  /**
   * push
   * @param {Object | Array<Object>} item
   * @returns {void}
   */
  push(item: Object | Array<Object> = []): void {
    GTMService.dataLayer.push(...(Array.isArray(item) ? item : [item]));
  },
  /**
   * extract
   * @returns Current dataLayer array
   */
  extract(): Array<Object> {
    return GTMService.dataLayer;
  },
};
