// @flow

import get from 'lodash/get';
import find from 'lodash/find';
import { marketing as ENUM_MARKETING } from 'Enum';
import MARKETING_CAMPAIGNS from 'Marketing';
import { cookies as CONFIG_COOKIES } from 'Config';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';

/**
 * isCampaignValid
 *
 * @param {Object} campaign
 * @returns {boolean}
 */
export function isCampaignValid(campaign: Object = {}): boolean {
  if (!campaign) {
    return false;
  }

  const { startDate, endDate, status } = campaign;
  const currentUnixtime = Date.now();
  const campaignStart = new Date(startDate).getTime();
  const campaignEnd = new Date(endDate).getTime();

  // check for status, add to enum
  return (
    parseInt(status, 10) === ENUM_MARKETING.status.STATUS_ACTIVE &&
    (!startDate || campaignStart <= currentUnixtime) &&
    (!endDate || currentUnixtime <= campaignEnd)
  );
}

/**
 * findValidCampaign
 *
 * @param {Object} campaignIds
 * @returns {?Object} valid campaign
 */
export function findValidCampaign({
  sessionCookieCode,
  queryStringCode,
  uri,
  cookieCampaignId,
}: Object): ?Object {
  // IMPORTANT: the order of the parameters in the list is specific
  // in order to find the first valid item
  const foundId = [
    MARKETING_CAMPAIGNS.code[sessionCookieCode],
    MARKETING_CAMPAIGNS.code[queryStringCode],
    MARKETING_CAMPAIGNS.uri[uri],
    cookieCampaignId,
  ].find((campaignId) => {
    const foundCampaign = campaignId && MARKETING_CAMPAIGNS.list[campaignId];

    return !!(foundCampaign && isCampaignValid(foundCampaign));
  });

  return MARKETING_CAMPAIGNS.list[foundId] || null;
}

/**
 * getMarketingCampaignSettingValue
 *
 * @param {Array<Object>} settings
 * @param {string} name
 * @param {string} store
 * @param {string} locale
 * @returns {any}
 */
function getMarketingCampaignSettingValue(
  settings: Array<Object>,
  name: string,
  store: ?string = null,
  locale: ?string = null,
): any {
  return get(
    find(
      settings,
      (setting) =>
        setting.name === name &&
        (setting.scope === ENUM_MARKETING.scope.SCOPE_GLOBAL ||
          (setting.scope === ENUM_MARKETING.scope.SCOPE_STORE &&
            store &&
            setting.storeCode === store)) &&
        (setting.localizable === false ||
          (setting.localizable === true && locale && setting.localeCode === locale)),
    ),
    'value',
  );
}

/**
 * setAffiliateCookie
 *
 * @param {Object} campaign
 * @param {Object} options
 * @returns {void}
 */
export function setAffiliateCookie({ id, settings }: Object = {}, { store, locale }: Object): void {
  const isAffiliatePersist =
    getMarketingCampaignSettingValue(
      settings,
      ENUM_MARKETING.setting.name.NAME_AFFILIATE_PERSIST,
      store,
      locale,
    ) === 'true';

  if (!isAffiliatePersist) {
    return;
  }

  const affiliateLifetime = getMarketingCampaignSettingValue(
    settings,
    ENUM_MARKETING.setting.name.NAME_AFFILIATE_LIFETIME,
    store,
    locale,
  );

  Cookies.set(CONFIG_COOKIES.marketing.name, id, {
    ...CONFIG_COOKIES.marketing,
    // affiliateLifetime is in seconds so it has to be converted because expires property expects days
    expires: affiliateLifetime ? affiliateLifetime / 3600 / 24 : CONFIG_COOKIES.marketing.expire,
  });
}
