// @flow

import React from 'react';
import { SSRContext } from 'shared_data/providers/ssr/Context';

type Props = {
  children: any,
};

export const SSRConsumer = ({ children }: Props) => (
  <SSRContext.Consumer>{(value) => children(value)}</SSRContext.Consumer>
);
