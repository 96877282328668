// @flow

export const CHECKOUT_ACTIONS = {
  STEP_ENTER: 'CHECKOUT_STEP_ENTER',
  STEP_UPDATE: 'CHECKOUT_STEP_UPDATE',
  PURCHASE: 'PURCHASE',
  CANCEL_ORDER: 'CANCEL_ORDER',
};

/**
 * enterCheckoutStep
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function enterCheckoutStep(payload: Object): Object {
  return {
    type: CHECKOUT_ACTIONS.STEP_ENTER,
    payload,
  };
}

/**
 * updateCheckoutStep
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function updateCheckoutStep(payload: Object): Object {
  return {
    type: CHECKOUT_ACTIONS.STEP_UPDATE,
    payload,
  };
}

/**
 * placeOrder
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function placeOrder(payload: Object): Object {
  return {
    type: CHECKOUT_ACTIONS.PURCHASE,
    payload,
  };
}

/**
 * cancelOrder
 *
 * @param {Object} payload
 * @returns {Object}
 */
export function cancelOrder(payload: Object): Object {
  return {
    type: CHECKOUT_ACTIONS.CANCEL_ORDER,
    payload,
  };
}
