// @flow

import queryString from 'query-string';
import Url from 'url-parse';
import {
  router as ROUTE_CONFIG,
  application as APP_CONFIG,
  navigation as CONFIG_NAVIGATION,
} from 'Config';
import { HTTP_STATUS } from 'Enum';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { stripTrailingSlash, find } from '@riseart/fe-utils';
import { RiseartLogger } from 'shared_services/riseart/Logger';

/**
 * getLocaleConfig
 *
 * @param {string} locale
 * @returns {Object}
 */
export function getLocaleConfig(value: any, compareField: string = 'name'): ?Object {
  return find(APP_CONFIG.i18n.locales, (locale) => locale[compareField] === value);
}

/**
 * getPageRouteConfig
 *
 * @param {string} key
 */
export function getPageRouteConfig(key: string, predicate?: Function = null): ?Object {
  return find(ROUTE_CONFIG, predicate || (({ key: routeKey }) => routeKey === key));
}

/**
 * assembleUrl
 *
 * @param {string} routeName
 * @param {?Object} options
 * @returns {string}
 */
export function assembleUrl(routeName: string, options?: Object = {}): string {
  const { path: routePaths } = getPageRouteConfig(routeName) || {};
  const locale = (options && options.locale) || getLocaleConfig(true, 'isDefault');

  if (!routePaths) {
    return null;
  }

  const { params } = options;
  const langRegEx = new RegExp(`^\\/:lang\\(${locale.basePath}\\)\\??`);
  let routePath = find(routePaths, (path) => !!path.match(langRegEx));
  routePath = routePath.replace(langRegEx, locale.isDefault ? '' : `/${locale.basePath}`);
  let pathname = routePath;

  if (params && Object.keys(params).length > 0) {
    const paramsToRegExp = Object.keys(params)
      .map((i) => `:${i}`)
      .join('|');
    const matchRegex = new RegExp(`${paramsToRegExp}`, 'gi');

    pathname = routePath
      .replace(/\(([^)]+)\)/g, '')
      .replace(matchRegex, (matched) => params[matched.replace(':', '')]);
  }

  return `${options.origin || ''}${pathname || (!options.origin ? '/' : '')}${
    options.search || ''
  }${options.hash || ''}`;
}

/**
 * findRedirect
 *
 * @param {string} requestUri
 * @param {Object} config
 * @param {number} hops
 * @param {string} initialRequestUri (optional)
 * @returns {?Object} found redirect configuration for the url or undefined
 */
export const findRedirect = (
  requestUri: string,
  config: Object,
  hops: number = 0,
  initialRequestUri?: string,
): ?Object => {
  const pathname = stripTrailingSlash(Url(requestUri).pathname);
  const foundRedirect = config[pathname];

  // No redirect found
  if (!foundRedirect || !foundRedirect.targetUri) {
    return;
  }

  // Log error when maximum redirect hops is exceeded
  if (hops === APP_CONFIG.redirector.maxHops) {
    RiseartLogger.exception(
      new Error(
        `Reached max redirect limit from redirector configuration for requestUri: ${
          initialRequestUri || requestUri || ''
        }.`,
      ),
    );

    return foundRedirect;
  }

  // Check for nested redirects if found redirect
  const foundNestedRedirect = findRedirect(
    foundRedirect.targetUri,
    config,
    hops + 1,
    initialRequestUri || requestUri,
  );

  if (foundNestedRedirect) {
    return foundNestedRedirect;
  }

  // Return found redirect
  return foundRedirect;
};

/**
 * combineQueryStringParams
 *
 * @param {string} targetUri
 * @param {string} requestUri
 * @returns {string}
 */
export function combineQueryStringParams(targetUri: string, requestUri: string): string {
  if (!requestUri || !targetUri) {
    return targetUri;
  }

  const parsedTargetUri = Url(targetUri);
  const parsedRequestUri = Url(requestUri);
  const combinedQueryStringParams = queryString.stringify({
    ...queryString.parse(parsedRequestUri.query),
    ...queryString.parse(parsedTargetUri.query),
  });

  return `${stripTrailingSlash(parsedTargetUri.pathname)}${
    combinedQueryStringParams ? `?${combinedQueryStringParams}` : ''
  }${parsedTargetUri.hash || ''}`;
}

/**
 * getQueryStringParam
 *
 * @param {string} name
 * @returns {Object}
 */
export function getQueryStringParam(name: string): ?string {
  const { search } = LocationManager.get();
  const parsedQsParams = search && queryString.parse(search);

  return (parsedQsParams && parsedQsParams[name]) || null;
}

/**
 * gql404RedirectHandler
 *
 * @param {string} redirectUrl
 * @param {Object} history
 * @param {?Object} staticContext
 */
export function gql404RedirectHandler(
  redirectUrl: string,
  history: Object,
  staticContext: ?Object,
): Function {
  return ({ graphQLErrors = [] }) => {
    const shouldRedirect = graphQLErrors.some(
      (err) => err && err.errorInfo && err.errorInfo.status === HTTP_STATUS.NOT_FOUND,
    );

    if (shouldRedirect) {
      if (staticContext) staticContext.status = HTTP_STATUS.MOVED_PERMANENTLY;
      history.replace(redirectUrl);

      return { shouldOverwriteDefaultHandler: true };
    }

    return { shouldOverwriteDefaultHandler: false };
  };
}

/**
 * normalizeUri
 *
 * @param {string} uri
 * @returns {string}
 */
export function normalizeUri(uri: string = ''): string {
  if (!uri) {
    return uri;
  }

  const parsedUrl = Url(uri);
  parsedUrl.set('pathname', `/${parsedUrl.pathname.replace(/^\/+|\/+$/g, '')}`);

  return `${parsedUrl.pathname}${parsedUrl.query}${parsedUrl.hash}`;
}

/**
 * getLocale
 *
 * @param {?string} urlLanguage
 * @param {?string} visitorLocale
 * @param {Array<Object>} localeConfig
 * @returns {Object}
 */
export function getLocale(
  urlLanguage: ?string,
  visitorLocale: ?string,
  localeConfig: Array<Object>,
): Object {
  // Get locale by detecting url language
  const validUrlLocale = urlLanguage && getLocaleConfig(urlLanguage, 'basePath');

  if (validUrlLocale) {
    return validUrlLocale;
  }

  // Get locale from visitor
  const validUserLocale = visitorLocale && getLocaleConfig(visitorLocale, 'name');

  if (validUserLocale) {
    return validUserLocale;
  }

  // Return default locale
  return find(localeConfig, ({ isDefault }) => isDefault);
}

/**
 * findDynamicPathParams
 *
 * @param {string} path
 * @param {Array<string>} exclude?
 * @returns {Array<string>}
 */
export function findDynamicPathParams(path: string, exclude?: Array<string> = []): Array<string> {
  if (!path) {
    return [];
  }

  const params: ?Array<string> = path.match(/:([a-zA-Z]*)/g);

  return params
    ? params.reduce((accumulator, param) => {
        const dynamicParam = param.replace(':', '');
        if (exclude.indexOf(dynamicParam) > -1) {
          return accumulator;
        }

        accumulator.push(dynamicParam);

        return accumulator;
      }, [])
    : [];
}

/**
 * extractLocaleFromUrl
 *
 * @param {string} uri
 * @returns {?Object}
 */
export function extractLocaleFromUrl(uri: string): ?Object {
  const path = normalizeUri(uri);

  if (!path) {
    return;
  }

  const language = path.substr(1).split('/').shift() || null;

  if (!language) {
    return;
  }

  return getLocaleConfig(language, 'basePath');
}

/*
 * normalizeLocalePath
 *
 * Checks if url includes the locale data in it,
 * and if not then try to assemble new url with found locale config
 *
 * @param {string} url
 * @param {string} locale
 * @returns {?string}
 */
export function normalizeLocalePath(url: string, locale: string): ?string {
  const normalizedUrl = normalizeUri(url);
  const urlLang = normalizedUrl.substr(1).split('/')[0];
  const validLocale = getLocaleConfig(urlLang, 'language');
  const localeBasedOnKey = getLocaleConfig(locale, 'name');

  // url has a valid and correct locale
  if (localeBasedOnKey && validLocale && validLocale.name === localeBasedOnKey.name) {
    return validLocale.isDefault ? normalizedUrl.substr(urlLang.length + 1) : normalizedUrl;
  }

  // url has a valid locale, but it is different than the one that it should be
  if (validLocale && validLocale.name !== locale && localeBasedOnKey) {
    const urlWithoutLang = normalizedUrl.substr(urlLang.length + 1);
    return localeBasedOnKey.isDefault
      ? urlWithoutLang
      : `/${localeBasedOnKey.language}${urlWithoutLang}`;
  }

  // no valid locale,, or url does not include the locale in it
  if (!validLocale && localeBasedOnKey) {
    return localeBasedOnKey.isDefault
      ? normalizedUrl
      : `/${localeBasedOnKey.language}${normalizedUrl}`;
  }
}

/**
 * parseHash
 *
 * @param {string} urlHash
 * @returns {Object}
 */
export function parseHash(urlHash: string): Object {
  return urlHash
    ? decodeURIComponent(urlHash)
        .replace('#', '')
        .split('&')
        .reduce((accumulator, pair) => {
          const [key, value] = pair.split('=');
          return { ...accumulator, [key]: value };
        }, {})
    : {};
}

/**
 * urlParamsAcl
 *
 * @param {Object} params
 * @param {'canonical' | 'hreflang'} field
 * @returns {Object}
 */
export function urlParamsAcl(params: Object, field: 'canonical' | 'hreflang'): Object {
  const { blacklist, whitelist, defaultBehaviour } = CONFIG_NAVIGATION.uri.query.acl[field];

  return Object.keys(params).reduce((accumulator, param) => {
    // Check if parameter is blacklisted
    if (blacklist.indexOf(param) > -1) {
      return accumulator;
    }

    // Check if parameter is whitelisted
    if (defaultBehaviour === 'allow' || whitelist.indexOf(param) > -1) {
      return { ...accumulator, [param]: params[param] };
    }

    return accumulator;
  }, {});
}
