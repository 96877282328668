// @flow

import { GUI_PROPERTIES } from 'shared_models/Gui';
import { GUI_ACTIONS } from 'shared_services/redux/actions/container';
import type { ActionType } from 'shared_services/redux/actions/application/gui';

const DEFAULT_STATE = {
  [GUI_PROPERTIES.CART_SIDEBAR]: { opened: null, trigger: null },
  [GUI_PROPERTIES.RECENT_ART_WIDGET]: { opened: false, explicitlyOpenWidgetOnPage: false },
  [GUI_PROPERTIES.REGIONAL_SETTINGS_SIDEBAR]: { open: false },
};

export function guiReducer(state: Object = DEFAULT_STATE, action: ActionType): Object {
  switch (action.type) {
    case GUI_ACTIONS.UPDATE_PROPERTY:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    case GUI_ACTIONS.UPDATE_RECENT_ART_WIDGET:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    default:
      return { ...state };
  }
}
