// @flow

import React, { Component } from 'react';
import get from 'lodash/get';
import { Picture as CommonPicture, ImagePlaceholder } from '@riseart/common';
import { generateSrc, generatePictureProps } from '@riseart/fe-utils';
import { ART_DIRECTION_SIZES } from 'shared_models/ArtDirectionSizes';
import { areDifferent } from 'shared_services/riseart/utils/Utils';
import { artDirection as CONFIG_ART_DIRECTION, cdn as CONFIG_CDN } from 'Config';

export const CDN_THUMBS_BASE_URL = `${CONFIG_CDN.fileSystem.host}/${CONFIG_CDN.fileSystem.thumbs}`;
export const generatePicturePropsForCDN = generatePictureProps.bind(null, CDN_THUMBS_BASE_URL);
export const generateSrcForCDN = generateSrc.bind(null, CDN_THUMBS_BASE_URL);

type Props = {
  alt: string,
  image: Object,
  imgProps: Object,
  type: string,
  className?: string,
};

/**
 * Picture
 */
export class Picture extends Component<*, Props> {
  shouldComponentUpdate(nextProps: Props): boolean {
    // exclude ref (if provided) from imgProps before comparison
    const { ref, ...imgProps } = this.props.imgProps || {};
    const { ref: nextImgRef, ...nextImgProps } = nextProps.imgProps || {};

    return (
      this.props.image.key !== nextProps.image.key ||
      areDifferent(imgProps, nextImgProps) ||
      areDifferent(this.props.className, nextProps.className)
    );
  }

  render() {
    const {
      alt,
      className,
      image,
      type,
      artDirectionKey,
      imgProps = {},
      lazyload = null,
    } = this.props;
    const artDirectionSizes = get(ART_DIRECTION_SIZES, type);

    if (!image) {
      throw new Error('[<Picture />] Image prop is required in Picture component');
    }

    if (!artDirectionSizes) {
      throw new Error(
        `[<Picture />] Unrecognized art direction type '${type}'. Please check if you added this type in ART_DIRECTION_SIZES list.`,
      );
    }

    return (
      <CommonPicture
        alt={alt}
        className={className}
        {...generatePicturePropsForCDN({
          image,
          sizesList: artDirectionSizes,
          artDirectionConfig: get(CONFIG_ART_DIRECTION, artDirectionKey),
        })}
        imgProps={imgProps}
        lazyload={lazyload}
      />
    );
  }
}

export const PicturePlaceholder = ImagePlaceholder(Picture);
