// @flow
import compact from 'lodash/compact';
import find from 'lodash/find';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import join from 'lodash/join';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import queryString from 'query-string';

const VALUES_SEPARATOR = ',';
const PATH_SEPARATOR = '/';
const QUERY_SEPARATOR = '&';
const PATH_FROM_QUERY_SEPARATOR = '?';

/**
 * assembleRelativedUrl
 *
 * @param {string} rootUrlPath
 * @param {Array<string>} urlParams
 */
export function assembleRelativedUrl(rootUrlPath: string, urlParams: Array<string>): string {
  const [pathParams, queryString] = urlParams;
  const hasPath = pathParams.length;
  const hasQueryString = queryString.length;

  if (hasPath && hasQueryString) {
    return rootUrlPath + PATH_SEPARATOR + urlParams.join(PATH_FROM_QUERY_SEPARATOR);
  }

  if (hasPath && !hasQueryString) {
    return rootUrlPath + PATH_SEPARATOR + pathParams;
  }

  if (!hasPath && hasQueryString) {
    return rootUrlPath + PATH_FROM_QUERY_SEPARATOR + queryString;
  }

  return rootUrlPath;
}

/**
 * validateDimensionValues
 * Validates values of specific dimension based on its configuration options and type
 *
 * @param {*} value
 * @param {Object} dimensionConfig
 * @returns {*}
 */
export function validateDimensionValues(value: any, dimensionConfig: Object): any {
  const valueHandlersByType = {
    list: (items, config) => {
      const itemsValues = isArray(items) ? [...items] : items;
      const { multiValue, values } = config;
      const itemsList = isString(itemsValues) ? [itemsValues] : itemsValues;
      const validValues = itemsList ? itemsList.filter((i) => values[i]) : [];

      return multiValue ? validValues : validValues[0];
    },
    range: (items, config) => {
      const { multiValue, rangeSeparator, rangeType, rangeUpperBound, values } = config;
      const valueKeys = Object.keys(values);
      const itemsList = isString(items) ? [items] : items;
      const predicates = {
        continuous: (i) => {
          const firstItem = i.split(rangeSeparator)[0];
          let secondItem = i.split(rangeSeparator)[1];

          const parsedFirst = parseInt(firstItem, 10);
          const parsedSecond = parseInt(secondItem, 10);

          // fix when range is returned with first value only (e.g. not 100-UP but UP only)
          secondItem = secondItem || firstItem;

          return (
            !Number.isNaN(parsedFirst) &&
            (!Number.isNaN(parsedSecond)
              ? parsedFirst <= parsedSecond
              : secondItem.toUpperCase() === rangeUpperBound)
          );
        },
        discreet: (i) => {
          const [firstItem, secondItem] = i.split(rangeSeparator);
          const firstIndex = valueKeys.indexOf(firstItem);
          const secondIndex = valueKeys.indexOf(secondItem);

          return firstIndex !== -1 && secondIndex !== -1 && firstIndex <= secondIndex;
        },
      };

      const predicateByType = predicates[rangeType || 'continuous'];

      if (!predicateByType) {
        return items;
      }

      const validValues = itemsList ? itemsList.filter(predicateByType) : [];

      return multiValue ? validValues : validValues[0];
    },
  };

  return valueHandlersByType[dimensionConfig.type || 'list'](value, dimensionConfig);
}

/**
 * pathToParams
 * Splits url path to params
 *
 * @param {string} path
 * @returns {Array}
 */
export function pathToParams(
  path: string = '',
  separator: string = PATH_SEPARATOR,
): Array<?string> {
  return isString(path) ? compact(path.split(separator)) : [];
}

/**
 * normalizeUrlParams
 * This function accepts the url parameters and defines semantically
 * which parameter is what type of data based on its value
 *
 * @param {Object} urlParams
 * @param {Object} configData
 * @returns {Object}
 */
export function normalizeUrlParams(
  urlParams: Array<?string> = [],
  configData: Object = {},
  translateFilterParameter: Function,
): Object {
  // Sort config by position
  const sortedConfig = Object.keys(configData)
    .map((key) => ({
      ...configData[key],
      filterKey: key,
    }))
    .sort((a, b) => {
      if (!a.position) return 1;
      if (!b.position) return -1;

      return a.position - b.position;
    });

  const collectedChildrenValues = [];

  // Parsing parent values
  const parsedParents = urlParams.reduce((aggr, urlParamValue, position) => {
    if ([null, undefined, false, 0, ''].indexOf(urlParamValue) > -1) {
      return aggr;
    }

    const wasFoundParent = sortedConfig.reduce((accumulator, configItem) => {
      const { inPath, filterKey } = configItem;

      // Do not proccess the iteration if value cannot be in the path or filter was already found
      if (!inPath || aggr[filterKey]) {
        return accumulator;
      }

      const translatedItem = translateFilterParameter(
        urlParamValue,
        null,
        false,
        [configItem.filterKey],
        true,
      );

      // Found trasnlated value and it is (valid) in the filter dimension configs
      if (translatedItem && configItem.values[translatedItem]) {
        aggr = {
          ...aggr,
          [filterKey]: {
            filterKey,
            urlValue: urlParamValue,
            translatedItem,
            configItem,
          },
        };
        return true;
      }

      return accumulator;
    }, false);

    // If parent value was not found, then it is considered as possible children value
    if (wasFoundParent === false) {
      collectedChildrenValues.push({ urlParamValue, position });
    }

    return aggr;
  }, {});

  // Parse children values based on collected values from parent iterations
  const parsedChildren = collectedChildrenValues.reduce((aggr, { urlParamValue }) => {
    if ([null, undefined, false, 0, ''].indexOf(urlParamValue) > -1) {
      return aggr;
    }

    // Extract and translate the children values based on valid parents
    Object.keys(parsedParents || {}).forEach((parentDimension) => {
      const { translatedItem: parentTranslatedValue, configItem: parentConfigItem } =
        parsedParents[parentDimension];

      // Get parent dimension key
      const [childDimensionKey] = Object.keys(
        (parentConfigItem && parentConfigItem.childDimension) || {},
      );

      // Check if children dimension can be in path
      if (childDimensionKey && parentConfigItem.childDimension[childDimensionKey].inPath) {
        const childTranslatedItem = translateFilterParameter(
          urlParamValue,
          null,
          false,
          [childDimensionKey],
          true,
        );

        // Found child translated value
        if (childTranslatedItem) {
          // Get valid children values for parent
          const validParentValues = Object.keys(
            parentConfigItem.values[parentTranslatedValue] || {},
          );

          // Check if each translated value is a valid one based on the parent trasnlation
          const validChildValue = [
            ...(Array.isArray(childTranslatedItem) ? childTranslatedItem : [childTranslatedItem]),
          ].reduce((accumulator, childValue) => {
            return validParentValues.indexOf(childValue) > -1
              ? [...accumulator, childValue]
              : accumulator;
          }, []);

          aggr = {
            ...aggr,
            [childDimensionKey]: {
              filterKey: childDimensionKey,
              urlValue: urlParamValue,
              translatedItem:
                validChildValue && validChildValue.length ? validChildValue : childTranslatedItem,
            },
          };
        }
      }
    });
    return aggr;
  }, {});

  const combinedFilterValues = { ...parsedParents, ...parsedChildren };
  return Object.keys(combinedFilterValues).reduce((accumulator, field) => {
    return { ...accumulator, [field]: combinedFilterValues[field].translatedItem };
  }, {});
}

/**
 * parseQueryString
 * Funtion to parse query string value pairs from string to object
 *
 * @param {string} params
 * @returns {Object}
 */
export function parseQueryString(
  params: string,
  multiValSeparator: string = VALUES_SEPARATOR,
): Object {
  const qParams = queryString.parse(params);

  return reduce(
    qParams,
    (result, item, k) => ({
      ...result,
      [k]: !item || item.indexOf(multiValSeparator) === -1 ? item : item.split(multiValSeparator),
    }),
    {},
  );
}

/**
 * combineUrlParams
 * Function to accept url parameters and query string parameters and combines them into one object
 * The query string parameters will overwrite any url parameters
 *
 * @param {Object} urlParams
 * @param {Object|string} queryParams
 * @param {Object} configData
 * @returns {Object}
 */
export function combineUrlParams(
  urlParams: Array<?string> = [],
  queryParams: Object | string = {},
  configData: Object = {},
  translateFilterParameter: Function,
): Object {
  const qParams = typeof queryParams === 'string' ? parseQueryString(queryParams) : queryParams;
  const params = normalizeUrlParams(urlParams, configData, translateFilterParameter);
  const combinedParams = reduce(
    params,
    (aggr: Object, p: string, k: string) => {
      const queryParam = qParams[k];
      // SUGGESTION: assign this always as a array (e.g. let combined = [p])
      let combined = p;

      if (queryParam) {
        combined = isString(queryParam) ? [p, queryParam] : union([p], queryParam); // [p, ...queryParam];
      }

      return { ...aggr, [k]: combined };
    },
    {},
  );

  return {
    ...qParams,
    ...combinedParams,
  };
}

/**
 * validatedUrlParams
 * Validation of all url parameters
 *
 * @param {*} combinedData
 * @param {*} configData
 * @returns {Object}
 */
export function validatedUrlParams(combinedData: Object, configData: Object): Object {
  const parentItems = { withChildren: {}, withoutChildren: {} };

  const childrenWithoutParents: Array<string> = reduce(
    combinedData,
    (aggr: Array<string>, item: any, key: string) => {
      const foundChild = find(
        configData,
        (i: Object, k: string) => i.childDimension && i.childDimension[key] && !combinedData[k],
      );

      if (foundChild) {
        aggr.push(key);
      }

      return aggr;
    },
    [],
  );

  const aggregatedValues = mapValues(omit(combinedData, childrenWithoutParents), (item, key) => {
    const itemExistsInConfig = configData[key];

    if (!itemExistsInConfig) {
      return item;
    }

    const newItem = validateDimensionValues(item, itemExistsInConfig);
    const childKey = !isEmpty(itemExistsInConfig.childDimension)
      ? 'withChildren'
      : 'withoutChildren';
    parentItems[childKey][key] = newItem;

    return newItem;
  });

  const childValues = reduce(
    parentItems.withChildren,
    (aggr, item, key) => {
      const parentItem = configData[key];
      const childKey = parentItem.childDimension && Object.keys(parentItem.childDimension)[0];
      const childItem = aggregatedValues[childKey];
      const parentList = reduce(pick(parentItem.values, item), (agg, i) => ({ ...agg, ...i }), {});

      if (!childItem) {
        return aggr;
      }

      aggr[childKey] = validateDimensionValues(childItem, {
        ...parentItem.childDimension[childKey],
        values: { ...parentList },
      });

      return aggr;
    },
    {},
  );

  // Allow empty string, 0 if number, and filter out any other empty values
  const result = omitBy(
    { ...aggregatedValues, ...childValues },
    (item) => item !== '' && item !== 0 && isEmpty(item),
  );

  return result;
}

/**
 * mapParameterToConfig
 *
 * @param {string} itemKey
 * @param {config} config
 * @returns {Object}
 */
export function mapParameterToConfig(itemKey: string, config: Object = {}): Object {
  let result = {};

  if (config[itemKey]) {
    return config[itemKey];
  }

  find(config, (item: Object, key: string) => {
    const childConfig = item.childDimension || {};

    if (childConfig[itemKey]) {
      result = { ...childConfig[itemKey], parent: { key, config: item } };
    }
  });

  return result;
}

/**
 * buildUrl
 * This function uses the pathname and query params and builds the
 * new url by passing a new set of parameters to replace or remove the old one
 *
 * USAGE: Passing input parameter of current url state (params) and newParams, and pathKeys.
 * newParams will overwrite or extend params and whatever is passed as pathKeys
 * will be moved to pathname of the url (e.g. /category/subcategory) and not in the queryString
 * The queryString parameters are those passed in params.queryString and the rest of the parameters
 * from params.pathParams which are not included in the pathKeys
 *
 * IMPORTANT: multiple values should be passed as Array<string> and if they should be in the pathname,
 * they will be moved to queryString with comma separated values
 *
 *
 * @param {{ pathParams: Object, queryParams: Object}} params
 * @param {Array<string>} pathKeys
 * @returns {string}
 */
export function buildUrl(
  params: { pathParams: Object, queryParams: Object } = {
    pathParams: {},
    queryParams: {},
  },
  newParams: Object = {},
  pathKeys?: Array<string> = [],
): string {
  const { pathParams, queryParams } = params;

  const movedToQueryParams = {};
  const cleanedPathParams = omitBy(
    {
      ...pick(pathParams, pathKeys),
      ...pick(newParams, pathKeys),
    },
    isNil,
  );
  const pathnameParams = join(
    compact(
      Object.keys(cleanedPathParams).map((key) => {
        const item = cleanedPathParams[key];
        const isItemArray = isArray(item);
        if (isItemArray && item.length > 1) {
          movedToQueryParams[key] = item.join(VALUES_SEPARATOR);
          return undefined;
        }
        return isItemArray ? item[0] : item;
      }),
    ),
    PATH_SEPARATOR,
  );

  const cleanedQueryParams = omitBy(
    {
      ...omit(pathParams, pathKeys),
      ...queryParams,
      ...omit(newParams, pathKeys),
      ...movedToQueryParams,
    },
    isNil,
  );
  const queryStringParams = join(
    Object.keys(cleanedQueryParams).map((key) => {
      const item = cleanedQueryParams[key];
      const value = isArray(item) ? item.join(VALUES_SEPARATOR) : item;
      return `${key}=${value}`;
    }),
    QUERY_SEPARATOR,
  );
  return [pathnameParams, queryStringParams ? `?${queryStringParams}` : ''].join('');
}

/**
 * assembleUrl
 *
 * @param {Object} values
 * @param {Object} config
 * @param {iteratorFilterHandler} Function filter function called on each iteration of filters
 *        and if it returns true the filter dimension will not be included in the final assembed url
 * @returns {string}
 */
export function assembleUrl(
  values: Object = {},
  config: Object = {},
  qsParamTranslator: Function,
  translateFilterParameter: Function,
  iteratorFilterHandler?: Function,
): string {
  const { dimensions, rootUrlPath } = config;
  const calcIfInPath = (inPath, items, isMultiValue) =>
    inPath &&
    (!isMultiValue ||
      (isMultiValue && ((isArray(items) && items.length <= 1) || typeof items === 'string')));
  const urlItems = reduce(
    values,
    (result: Object, item: any, key: string) => {
      const itemConfig = mapParameterToConfig(key, dimensions);

      if (
        typeof iteratorFilterHandler === 'function' &&
        iteratorFilterHandler(item, itemConfig, result)
      ) {
        return result;
      }

      const { position, inPath = false, multiValueSeparator = VALUES_SEPARATOR } = itemConfig;
      let isInPath = calcIfInPath(inPath, item, itemConfig.multiValue);

      if (isInPath && itemConfig.parent && itemConfig.parent.config) {
        const {
          config: { inPath: parentInPath },
          key: parentKey,
        } = itemConfig.parent;
        isInPath = calcIfInPath(parentInPath, values[parentKey], itemConfig.multiValue);
      }

      if (!isInPath) {
        const paramValue = isArray(item)
          ? sortBy(item.map((i) => translateFilterParameter(i, key))).join(multiValueSeparator)
          : translateFilterParameter(item, key);
        result.queryStringParams.push({
          position,
          item: `${qsParamTranslator(key)}=${encodeURIComponent(paramValue)}`,
        });
      } else {
        result.pathParams.push({
          position,
          item: encodeURIComponent(translateFilterParameter(item, key, true, null, true)),
        });
      }

      return result;
    },
    {
      pathParams: [],
      queryStringParams: [],
    },
  );

  urlItems.pathParams = sortBy(urlItems.pathParams, ['position']);
  urlItems.queryStringParams = sortBy(urlItems.queryStringParams, ['position', 'item']);

  return assembleRelativedUrl(rootUrlPath, [
    urlItems.pathParams.map((i) => i.item).join(PATH_SEPARATOR),
    urlItems.queryStringParams.map((i) => i.item).join(QUERY_SEPARATOR),
  ]);
}

/**
 * checkDimensionValueConfigMeta
 *
 * @param {any} filter
 * @param {'all' | 'none' | 'single' | 'multiple'} enumVal
 */
export function checkDimensionValueConfigMeta(
  filterDimensionValue: any,
  metaConfigValue: 'all' | 'none' | 'single' | 'multiple',
): boolean {
  const isFilterArray = isArray(filterDimensionValue);
  const hasSetValue =
    (isFilterArray && filterDimensionValue.length > 0) ||
    (!isFilterArray && (filterDimensionValue !== undefined || filterDimensionValue !== null));

  if (metaConfigValue === 'none' && hasSetValue) {
    return true;
  }

  if (!metaConfigValue) {
    return true;
  }

  if (
    metaConfigValue === 'single' &&
    ((!isFilterArray && hasSetValue) || (isFilterArray && filterDimensionValue.length === 1))
  ) {
    return false;
  }

  if (metaConfigValue === 'multiple' && isFilterArray && filterDimensionValue.length > 1) {
    return false;
  }

  if (
    metaConfigValue === 'all' &&
    ((isFilterArray && filterDimensionValue.length > 0) || (!isFilterArray && hasSetValue))
  ) {
    return false;
  }

  return true;
}

/**
 * updateValues
 *
 * @param {*} inputValues
 * @param {*} data
 * @returns {Object}
 */
export function updateValues(inputValues: Object = {}, data: Object = {}): Object {
  const existingValues = [];
  const result = mapValues(inputValues, (item, key) => {
    const newKey = data[key];
    if (isUndefined(newKey)) {
      return item;
    }

    existingValues.push(key);

    // replace isArray with isMultiple from config and check if item can be multiple or needs to replace current value
    return isArray(item) ? uniq([...item, ...newKey]) : newKey;
  });

  return { ...result, ...omit(data, existingValues) };
}

/**
 * removeValues
 *
 * @param {*} inputValues
 * @param {*} data
 * @param {*} dimensions
 * @returns {Object}
 */
export function removeValues(
  inputValues: Object = {},
  data: Object = {},
  dimensions: Array<string> = [],
): Object {
  const flattenMap = omit(inputValues, dimensions);
  const result = mapValues(flattenMap, (item, key) => {
    const removedValues = data[key];

    // No values for the current filter
    if (isUndefined(removedValues)) {
      return item;
    }

    // Both item and removedValues are arrays, then exclude them from list
    if (isArray(item) && isArray(removedValues)) {
      return without(item, ...removedValues);
    }

    // If item and removedValues are equal, then exclude it from list
    if (item === removedValues) {
      return;
    }

    // Current filter value is string, and removedValues is array,
    // check if current filter exists in values to remove
    if (
      isArray(removedValues) &&
      isString(item) &&
      removedValues.some((removedItem) => removedItem === item)
    ) {
      return;
    }

    return item;
  });

  return { ...result };
}

/**
 * flattenByKey
 *
 * @param {Object} inputObject
 * @param {string} key
 * @returns {Object}
 */
export function flattenByKey(inputObject: Object = {}, key: string): Object {
  if (has(inputObject, key)) {
    return {
      ...inputObject[key],
      ...omit(inputObject, [key]),
    };
  }

  return { ...inputObject };
}
