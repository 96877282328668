/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */
import React from 'react';
import loadable from '@loadable/component';
import { timeout } from 'promise-timeout';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';

const LOADABLE_TIMEOUT = 10000;
const FallbackComponent = (
  <IsomorphicRipple isFullScreen isActive customStyles={{ backgroundColor: '#ffffff' }} />
);

/**
 * LOCALE_COMPONENTS
 */
export const LOCALE_COMPONENTS = {
  translations: {
    'de-DE': loadable(() => timeout(import('./translations.de-DE'), LOADABLE_TIMEOUT), {
      fallback: FallbackComponent,
    }),
    'en-GB': loadable(() => timeout(import('./translations.en-GB'), LOADABLE_TIMEOUT), {
      fallback: FallbackComponent,
    }),
    'fr-FR': loadable(() => timeout(import('./translations.fr-FR'), LOADABLE_TIMEOUT), {
      fallback: FallbackComponent,
    }),
  },
  settings: {
    'de-DE': loadable(() => timeout(import('./settings.de-DE'), LOADABLE_TIMEOUT)),
    'en-GB': loadable(() => timeout(import('./settings.en-GB'), LOADABLE_TIMEOUT)),
    'fr-FR': loadable(() => timeout(import('./settings.fr-FR'), LOADABLE_TIMEOUT)),
  },
  metaCategories: {
    'de-DE': loadable(() => timeout(import('./meta-categories.de-DE'), LOADABLE_TIMEOUT)),
    'en-GB': loadable(() => timeout(import('./meta-categories.en-GB'), LOADABLE_TIMEOUT)),
    'fr-FR': loadable(() => timeout(import('./meta-categories.fr-FR'), LOADABLE_TIMEOUT)),
  },
  pageMeta: {
    'de-DE': loadable(() => timeout(import('./page-meta.de-DE'), LOADABLE_TIMEOUT)),
    'en-GB': loadable(() => timeout(import('./page-meta.en-GB'), LOADABLE_TIMEOUT)),
    'fr-FR': loadable(() => timeout(import('./page-meta.fr-FR'), LOADABLE_TIMEOUT)),
  },
};
