// @flow

import cloneDeep from 'lodash/cloneDeep';
import { FAVORITES_ACTIONS } from 'shared_services/redux/actions/container';
import { DEFAULT_STORE_STATE } from 'shared_services/redux/defaultStoreStates';

const DEFAULT_FAVORITE_STATE = { ...cloneDeep(DEFAULT_STORE_STATE), data: {} };

/**
 * reduceFavoritesList
 *
 * @param {Object} accumulator
 * @param {Object} item
 * @returns {Object}
 */
const reduceFavoritesList = (accumulator: Object = {}, item: Object = {}): Object => {
  if (item && item.artId) {
    accumulator[item.artId] = { id: item.id };
  }

  return accumulator;
};

/**
 * favoritesReducer
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} New state
 */
export function favoritesReducer(state: Object = DEFAULT_FAVORITE_STATE, action: Object): Object {
  switch (action.type) {
    case FAVORITES_ACTIONS.FAVORITES_FETCH_LOADING:
      return {
        ...state,
        status: {
          error: false,
          loading: true,
          loaded: false,
        },
      };
    case FAVORITES_ACTIONS.FAVORITES_FETCH_DONE:
      return {
        data: {
          ...DEFAULT_FAVORITE_STATE.data,
          ...(action.payload && action.payload.reduce(reduceFavoritesList, {})),
        },
        error: null,
        status: {
          error: false,
          loading: false,
          loaded: true,
        },
      };
    case FAVORITES_ACTIONS.FAVORITES_FETCH_ERROR:
      return {
        data: { ...state.data },
        error: { ...action.payload },
        status: {
          error: true,
          loading: false,
          loaded: false,
        },
      };
    case FAVORITES_ACTIONS.FAVORITES_UPDATE:
      return {
        data: (action.payload && action.payload.items.reduce(reduceFavoritesList, {})) || {},
      };
    case FAVORITES_ACTIONS.FAVORITE_ADD:
      return {
        ...state,
        data: { ...state.data, [action.payload.artId]: { id: action.payload.id } },
      };
    case FAVORITES_ACTIONS.FAVORITE_REMOVE:
      return {
        ...state,
        data: {
          ...((state.data &&
            Object.keys(state.data).reduce((accumulator, itemKey) => {
              if (parseInt(itemKey, 10) !== parseInt(action.payload.artId, 10)) {
                accumulator[itemKey] = state.data[itemKey];
              }

              return accumulator;
            }, {})) ||
            {}),
        },
      };
    default:
      return state;
  }
}
