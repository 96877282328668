// @flow

import get from 'lodash/get';
import { store as CONFIG_STORE } from 'Config';

/**
 * selectAuthShippingCountryCode
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectAuthShippingCountryCode(state: Object): ?string {
  return get(state[CONFIG_STORE.keys.auth], 'data.payload.shipping') || null;
}

/**
 * selectAuthStatus
 *
 * @param {string} property
 * @returns {Function}
 */
export function selectAuthStatus(property?: string): Function {
  return (state: Object): Object | boolean | string | null => {
    const { status: authStatus } = state[CONFIG_STORE.keys.auth] || {};

    if (!authStatus) {
      return null;
    }

    return property ? authStatus[property] : authStatus;
  };
}

/**
 * selectAuthToken
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectAuthToken(state: Object): ?string {
  return get(state[CONFIG_STORE.keys.auth], 'data.token') || null;
}

/**
 * selectAclRole
 *
 * @param {Object} state
 * @returns {?Object}
 */
export function selectAclRole(state: Object): ?Object {
  return get(state[CONFIG_STORE.keys.auth], 'data.payload.acl_role') || null;
}
