// @flow

// eslint-disable-next-line
import React from 'react';
import type { Node } from 'react';
import { HOCAuthParams } from 'shared_hocs/auth/Params';
import { useGoogleAuthListener } from 'shared_components/auth/google/useAuthListener';

type Props = {
  authParams: Object,
  pageConfig: Object,
  children?: Function,
};

/**
 * CallbackProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
const CallbackProvider = ({ authParams, pageConfig, children }: Props): Node => {
  const { loading } = useGoogleAuthListener({ authParams, pageConfig });

  return typeof children === 'function' ? children(loading) : null;
};

/**
 * GoogleAuthCallbackProvider
 */
export const GoogleAuthCallbackProvider = HOCAuthParams(CallbackProvider);
