// @flow

export const FAVORITES_ACTIONS = {
  FAVORITES_UPDATE: 'FAVORITES_UPDATE',
  FAVORITE_ADD: 'FAVORITE_ADD',
  FAVORITE_REMOVE: 'FAVORITE_REMOVE',
  FAVORITES_FETCH: 'FAVORITES_FETCH',
  FAVORITES_FETCH_LOADING: 'FAVORITES_FETCH_LOADING',
  FAVORITES_FETCH_DONE: 'FAVORITES_FETCH_DONE',
  FAVORITES_FETCH_ERROR: 'FAVORITES_FETCH_ERROR',
};

// Action Creators
/**
 * addFavorite
 * @param {Object} artData
 * @returns {Object} Action
 */
export function addFavorite(favorite: Object): Object {
  return {
    type: FAVORITES_ACTIONS.FAVORITE_ADD,
    payload: { ...favorite },
  };
}

/**
 * removeFavorite
 * @param {number} artId
 * @returns {Object} Action
 */
export function removeFavorite(favorite: Object): Object {
  return {
    type: FAVORITES_ACTIONS.FAVORITE_REMOVE,
    payload: { ...favorite },
  };
}

export function favoritesFetch(inputData: Object): Object {
  return {
    type: FAVORITES_ACTIONS.FAVORITES_FETCH,
    payload: {
      ...inputData,
    },
  };
}

export function favoritesFetchLoading(): Object {
  return {
    type: FAVORITES_ACTIONS.FAVORITES_FETCH_LOADING,
  };
}

export function favoritesFetchDone(payload: Object): Object {
  return {
    type: FAVORITES_ACTIONS.FAVORITES_FETCH_DONE,
    payload,
  };
}

export function favoritesFetchError(error: Object): Object {
  return {
    type: FAVORITES_ACTIONS.FAVORITES_FETCH_ERROR,
    payload: {
      ...error,
    },
  };
}
