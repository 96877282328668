// @flow

import get from 'lodash/get';
import { locales as CONFIG_LOCALE } from 'Config';

/**
 * LocalizedConfig
 *
 * A service to access localed config keys
 */
export const LocalizedConfig = {
  locale: null,

  /**
   * config
   *
   * @param {string} locale
   * @returns {void}
   */
  config(locale: string): void {
    LocalizedConfig.locale = locale;
  },

  /**
   * get
   *
   * @param {string} key
   * @param {string} locale?
   * @returns {any}
   */
  get(key: string, locale?: string | null = null): any {
    return get(CONFIG_LOCALE[locale || LocalizedConfig.locale], key);
  },
};
