// @flow

import find from 'lodash/find';
import {
  activity as ENUM_ACTIVITY,
  art as ENUM_ART,
  collection as ENUM_COLLECTION,
  article as ENUM_ARTICLE,
  artist as ENUM_ARTIST,
  event as ENUM_EVENT,
  quiz as ENUM_QUIZ,
} from 'Enum';
import { components as CONFIG_COMPONENTS } from 'Config';
import { isRetina } from 'shared_services/riseart/utils/Utils';

export const AUTO_HEIGHT = 'AUTO';
export const IMAGE_FORMAT_WEBP = 'webp';

const SCREEN_TYPES = {
  normal: 'normal',
  retina: 'retina',
};

export const imageTypeKeysByScreenType = {
  small: {
    [SCREEN_TYPES.normal]: 'small',
    [SCREEN_TYPES.retina]: 'small-retina',
  },
  medium: {
    [SCREEN_TYPES.normal]: 'medium',
    [SCREEN_TYPES.retina]: 'medium-retina',
  },
  large: {
    [SCREEN_TYPES.normal]: 'large',
    [SCREEN_TYPES.retina]: 'large-retina',
  },
};

/**
 * getImageBySize
 *
 * @param {Array<Object>} imagesList
 * @param {Object} imageTypeKeys
 * @param {boolean} fallbackToNextImage
 * @returns {Object}
 */
export const getImageBySize = (
  imagesList: Array<Object> = [],
  imageTypeKeys: Object,
  fallbackToNextImage: boolean = true,
): Object => {
  if (!imagesList.length) {
    return {};
  }

  const images = imagesList.reduce((result, { type, file, ...rest }) => {
    if (type && file && file.url) {
      result[type] = file;
    } else if (type && rest.url) {
      result[type] = rest;
    }

    return result;
  }, {});
  const isRetinaDisplay = isRetina();
  const screenType = isRetinaDisplay ? SCREEN_TYPES.retina : SCREEN_TYPES.normal;
  const screenImageType = imageTypeKeys[screenType];
  const imageKeys = Object.keys(images) || [];

  return (
    images[screenImageType] ||
    images[imageTypeKeys[isRetinaDisplay ? SCREEN_TYPES.normal : SCREEN_TYPES.retina]] ||
    (fallbackToNextImage && images[imageKeys[0]]) ||
    {}
  );
};

/**
 * extractImageFileFromData
 */
export const extractImageFileFromData = {
  collectionImage: (images: Array<Object>, type: string = 'TYPE_MAIN_MASTER') => {
    const searchedType = ENUM_COLLECTION.image.type[type];
    return (
      find(images, { type: searchedType }) ||
      images.reduce((accumulator, image) => {
        if (image.children) {
          const foundChildImage = find(image.children, { type: searchedType });

          if (foundChildImage) {
            accumulator = foundChildImage;
          }
        }
        return accumulator;
      }, null) ||
      find(CONFIG_COMPONENTS.collections.fallbackImages, { type: searchedType })
    );
  },
  artImage: (
    images: Object | Array<Object>,
    type?: string = 'TYPE_MAIN_MASTER',
    skipFallback?: boolean = false,
  ) => {
    const imgType = ENUM_ART.image.type[type];

    if (Array.isArray(images)) {
      const foundImage =
        find(images, { type: imgType }) ||
        images.reduce((accumulator, image) => {
          if (image.children) {
            const foundChildImage = find(image.children, { type: imgType });

            if (foundChildImage) {
              accumulator = foundChildImage;
            }
          }
          return accumulator;
        }, null);

      if (foundImage) {
        return foundImage;
      }
    }
    if (images && images.type && images.type === imgType) {
      return images;
    }
    if (images && images.children && images.children.length) {
      const foundImage = find(images.children, { type: imgType });

      if (foundImage) {
        return foundImage;
      }
    }

    return skipFallback ? null : find(CONFIG_COMPONENTS.art.fallbackImages, { type: imgType });
  },
  quizProfileRelationImage: (images: Array<Object>) =>
    find(images, { type: ENUM_QUIZ.profile.relation.image.type.TYPE_ORIGINAL }),
  quizProfileImage: (images: Array<Object>) =>
    find(images, { type: ENUM_QUIZ.profile.image.type.TYPE_ORIGINAL }),
  artistFeedImage: (
    images: Array<Object>,
    type: string = ENUM_ARTIST.feed.image.type.TYPE_ORIGINAL,
  ) => find(images, { type }) || find(CONFIG_COMPONENTS.artist.feed.fallbackImages, { type }),
  eventImage: (images: Array<Object>, type: string = ENUM_EVENT.image.type.TYPE_MASTER) =>
    find(images, { type }) || find(CONFIG_COMPONENTS.events.fallbackImages, { type }),
  articleImage: (images: Array<Object>, type: string = ENUM_ARTICLE.image.type.TYPE_MAIN_MASTER) =>
    find(images, { type }) || find(CONFIG_COMPONENTS.articles.fallbackImages, { type }),
  activityImage: (images: Array<Object>, type: string = ENUM_ACTIVITY.image.type.TYPE_MASTER) =>
    find(images, { type }),
  byType: (images: Array<Object>, type: string) => find(images, { type }),
};
