// @flow

import { store as CONFIG_STORE } from 'Config';

/**
 * localeSelector
 *
 * @param {Object} state
 * @returns {?Object}
 */
export function localeSelector(state: Object, localeType: string = 'routeLocale'): Object {
  const app = state[CONFIG_STORE.keys.application];
  return (app && app[localeType]) || null;
}
