// @flow

export const FOLLOW_ACTIONS = {
  FOLLOW_CREATE: 'FOLLOW_CREATE',
  FOLLOW_DELETE: 'FOLLOW_DELETE',
};

// Action Creators
/**
 * createFollow
 *
 * @param {Object} payload
 * @returns {Object} action
 */
export function createFollow(payload: Object): Object {
  return {
    type: FOLLOW_ACTIONS.FOLLOW_CREATE,
    payload,
  };
}

/**
 * deleteFollow
 *
 * @param {Object} payload
 * @returns {Object} action
 */
export function deleteFollow(payload: Object): Object {
  return {
    type: FOLLOW_ACTIONS.FOLLOW_DELETE,
    payload,
  };
}
