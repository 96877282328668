// @flow

import { useEffect } from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationStoreUpdateLocales } from 'shared_services/redux/actions/application/store';
import { application as CONFIG_APP } from 'Config';
import { selectVisitorLocale } from 'shared_services/redux/selectors/visitor';
import {
  getLocale,
  getLocaleConfig,
  extractLocaleFromUrl,
} from 'shared_services/riseart/utils/RouteUtils';

type Props = {
  isSSR: boolean,
  urlLanguage: ?string,
  location: Object,
  children: Function,
};

/**
 * AppLocale
 *
 * Determines the locale based on url and auth locales (or falls back to default)
 * and dispatches an event to store the locale in redux store
 *
 * @param {Props} props
 * @returns {Node}
 */
export const AppLocale = ({ isSSR, urlLanguage, location, children }: Props): Node => {
  const dispatch: Function = useDispatch();
  const visitorLocale = useSelector(selectVisitorLocale);
  const routeLocale =
    getLocaleConfig(urlLanguage, 'basePath') ||
    extractLocaleFromUrl(location && location.pathname) ||
    getLocaleConfig(true, 'isDefault');
  const userLocale = getLocale(urlLanguage, visitorLocale, CONFIG_APP.i18n.locales);

  // useEffect is only executed on client
  useEffect(() => {
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }, [dispatch, userLocale, routeLocale]);

  // called on server to workaround the useEffect not called on server issue
  if (isSSR) {
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }

  return children({ userLocale, routeLocale });
};
