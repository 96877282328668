// @flow

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { errors as ERRORS_ENUM } from 'Enum';
import {
  sentry as SENTRY_CONFIG,
  debug as DEBUG_CONFIG,
  environment as ENVIRONMENT,
  version as APP_VERSION,
  store as STORE_CONFIG,
} from 'Config';

const {
  sentry: IS_SENTRY_ENABLED,
  console: SHOW_CONSOLE_LOGS,
  logStoreState: LOG_STORE_STATE,
} = DEBUG_CONFIG;
const { levels: ERROR_LEVELS } = ERRORS_ENUM;

export const MESSAGE_TYPES = {
  ...ERROR_LEVELS,
};

export const ERROR_HANDLERS = {
  ...ERRORS_ENUM.handlers,
};

/**
 * LogMessageType
 */
type LogMessageType = {
  message: string,
  level: string,
  data?: Object,
};

/**
 * getReduxStateData
 *
 * @param {Object} reduxStore
 * @returns {Object} redux store state if allowed in config and token, visitor_id and user_id
 */
function getReduxStateData(reduxStore) {
  const reduxStoreState = reduxStore && reduxStore.getState();

  if (!reduxStoreState) {
    return {};
  }

  const authStoreState = get(reduxStoreState, STORE_CONFIG.keys.auth);
  const meStoreState = get(reduxStoreState, STORE_CONFIG.keys.me);

  return {
    ...(LOG_STORE_STATE && reduxStoreState ? { state: reduxStoreState } : {}),
    token: get(authStoreState, 'data.token', null),
    visitor_id: get(meStoreState, 'data.visitor.id', null),
    user_id: get(meStoreState, 'data.user.id', null),
  };
}

/**
 * RiseartLogger
 */
export const RiseartLogger = {
  sentrySdk: {},
  reduxStore: null,

  /**
   * Config
   *
   * @param {Object} sdk
   * @returns {Object}
   */
  config: (sdk: Object): Object => {
    RiseartLogger.sentrySdk = sdk;

    return RiseartLogger;
  },

  /**
   * isSentryEnabled
   *
   * @returns {boolean}
   */
  get isSentryEnabled(): boolean {
    return IS_SENTRY_ENABLED;
  },

  /**
   * isConfigured
   *
   * @returns {boolean}
   */
  get isConfigured(): boolean {
    return !isEmpty(RiseartLogger.sentrySdk);
  },

  /**
   * init
   *
   * @param {Object} reduxStore
   * @returns {void}
   */
  init: (reduxStore?: Object = null): void => {
    if (!RiseartLogger.isSentryEnabled) {
      return;
    }

    // no need to set reduxStore if sentry is not enabled,
    // because we use it only when logging to sentry
    RiseartLogger.reduxStore = reduxStore;
    RiseartLogger.sentrySdk.init({
      dsn: SENTRY_CONFIG.accountEndpoint,
      environment: ENVIRONMENT,
      release: APP_VERSION.number,
    });
  },

  /**
   * exception
   *
   * @param {Error} exception
   * @param {Object} data
   * @returns {void}
   */
  exception: (exception: Error, data: Object = {}): void => {
    if (RiseartLogger.isSentryEnabled) {
      RiseartLogger.sentrySdk.withScope((scope) => {
        const logData = { ...data, ...getReduxStateData(RiseartLogger.reduxStore) };

        Object.keys(logData).forEach((k) => {
          scope.setExtra(k, logData[k]);
        });

        RiseartLogger.sentrySdk.captureException(exception);
      });
    }

    /* eslint no-console:0 */
    SHOW_CONSOLE_LOGS && typeof console === 'object' && console.error && console.error(exception);
  },

  /**
   * message
   *
   * @param {LogMessageType}
   * @returns {void}
   */
  message: ({ message, level = ERROR_LEVELS.INFO, data = {} }: LogMessageType): void => {
    if (SHOW_CONSOLE_LOGS) {
      if (level >= ERROR_LEVELS.INFO) {
        console.info(message, data);
      } else if (level >= ERROR_LEVELS.WARNING) {
        console.warn(message, data);
      } else if (level >= ERROR_LEVELS.ERROR) {
        console.error(message, data);
      } else {
        console.info(message, data);
      }
    }

    if (RiseartLogger.isSentryEnabled && level < ERROR_LEVELS.WARNING) {
      RiseartLogger.sentrySdk.withScope((scope) => {
        scope.setLevel(level);

        const logData = { ...data, ...getReduxStateData(RiseartLogger.reduxStore) };

        Object.keys(logData).forEach((k) => {
          scope.setExtra(k, logData[k]);
        });

        RiseartLogger.sentrySdk.captureMessage(message);
      });
    }
  },

  /**
   * groupedLog
   *
   * @param {string} title
   * @param {any} data
   * @returns void
   */
  groupedLog: (title: string, data: any): void => {
    if (!SHOW_CONSOLE_LOGS) {
      return;
    }

    console.groupCollapsed(title);
    console.log(data); // eslint-disable-line
    console.groupEnd();
  },

  /**
   * setUserContext
   *
   * @param {Object} me
   * @returns void
   */
  setUserContext: (me: Object): void => {
    if (!RiseartLogger.isSentryEnabled) {
      return;
    }

    RiseartLogger.sentrySdk.configureScope((scope) => {
      scope.setUser({
        email: me.visitor.email,
        id: me.visitor.id,
        extra: me,
      });
    });
  },
};
