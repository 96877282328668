// @flow

import createSagaMiddleware, { END } from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { debug as DEBUG_CONFIG } from 'Config';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import MiddlewareActionsRouter from 'shared_services/redux/middleware/MiddlewareActionsRouter';
import MiddlewareGoogleTagManager from 'shared_services/redux/middleware/MiddlewareGoogleTagManager';
import { MiddlewareEvent } from 'shared_services/redux/middleware/MiddlewareEvent';
import { MiddlewareApplication } from 'shared_services/redux/middleware/MiddlewareApplication';
import { MiddlewareLogger } from 'shared_services/redux/middleware/MiddlewareLogger';
import { MiddlewareError } from 'shared_services/redux/middleware/MiddlewareError';
import { MiddlewareAuth } from 'shared_services/redux/middleware/MiddlewareAuth';
import { rootReducer } from 'shared_services/redux/reducers/container';

// Disable redux debug tools for security on server
// rendering and if debug.redux === false
const composeEnhancers =
  (DEBUG_CONFIG.redux &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

/**
 * Application store settings
 *
 * @param { Object } initialState The initial redux store state
 * @returns {{runSaga: Task, close: void}}
 */
const configureStore = (initialState: Object = {}): Object => {
  // Create and configure saga middleware
  const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error) => {
      // Uses the reduxStore from parent scope
      // although it is used before defined
      // eslint-disable-next-line
      reduxStore.dispatch(errorAdd(ErrorService.mapJSError(error)));
    },
  });

  // Create redux store
  const reduxStore = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        MiddlewareLogger.middleware,
        MiddlewareActionsRouter.middleware,
        MiddlewareGoogleTagManager.middleware,
        MiddlewareEvent.middleware,
        MiddlewareApplication.middleware,
        MiddlewareAuth.middleware,
        MiddlewareError.middleware,
        sagaMiddleware,
      ),
    ),
  );

  // Return redux store augmented with helper
  // functions runSaga() and close()
  return {
    ...reduxStore,
    runSaga: sagaMiddleware.run,
    close: () => reduxStore.dispatch(END),
  };
};

export default configureStore;
