// @flow

import { application as APP_CONFIG } from 'Config';
import companyReviews from 'shared_static/data/reviews';

const REVIEWS_IO_RATING = {
  '@type': 'AggregateRating',
  ratingValue: companyReviews.averageRating,
  bestRating: '5',
  ratingCount: companyReviews.totalReviews,
};

const MEDIUM_TO_LD_TYPE = {
  installation: 'Sculpture',
  sculpture: 'Sculpture',
  collage: 'Painting',
  painting: 'Painting',
  digital: 'Painting',
  print: 'Painting',
  drawings: 'Drawing',
  photography: 'Photograph',
};

export const LDTYPES = {
  WEBPAGE: 'WebPage',
  BREADCRUMBS: 'Breadcrumbs',
  PRODUCT: 'Product',
  ARTWORK: 'Artwork',
  ARTWORKS_LIST: 'ArtworksList',
  COLLECTION: 'Collection',
  CAROUSEL_ART: 'ArtCarousel',
  ARTISTS_LIST: 'ArtistsList',
  ARTIST: 'Artist',
  ARTICLE: 'Article',
  CAROUSEL_ARTICLES: 'ArticlesCarousel',
  ARTICLE_CATEGORIES_LIST: 'ArticleCategoriesList',
  EVENT: 'Event',
  AGGREGATE_RATING: 'AggregateRating',
};

/**
 * geneateMainEntityOfPage
 * @param {Object} mainEntityOfPage
 * @returns {Object}
 */
function geneateMainEntityOfPage({ type, url }: Object = {}) {
  return type && url
    ? {
        mainEntityOfPage: {
          '@type': type,
          '@id': url,
        },
      }
    : {};
}

/**
 * mapProductAvailability
 * Available values based on schema.org documentation: Discontinued, InStock, InStoreOnly, LimitedAvailability, OnlineOnly, OutOfStock, PreOrder, PreSale, SoldOut
 * @param {Object} art
 * @returns {string}
 */
function mapProductAvailability(art: Object): string {
  if (!art.productCanBuy && !art.productCanRent) {
    return 'https://schema.org/OutOfStock';
  }

  if (art.productIsEnquire) {
    return 'https://schema.org/LimitedAvailability';
  }

  if (art.productIsSoldOut) {
    return 'https://schema.org/SoldOut';
  }

  return 'https://schema.org/InStock';
}

/**
 * generateArtworkJSON
 *
 * @param {Object} data
 * @returns {Object}
 */
function generateArtworkJSON(data: Object = {}): Object {
  return {
    '@context': 'https://schema.org/',
    '@type': MEDIUM_TO_LD_TYPE[data.mediumKey] || MEDIUM_TO_LD_TYPE.painting,
    name: data.title,
    description: data.description,
    image: data.image,
    genre: data.medium,
    accountablePerson: {
      '@type': 'Person',
      name: data.artistName,
      description: data.artistStatement,
      url: data.artistUrl,
    },
    creator: {
      '@type': 'Person',
      name: data.artistName,
      description: data.artistStatement,
      url: data.artistUrl,
    },
    locationCreated: data.locationCreated,
    keywords: data.keywords,
    url: data.url,
    ...geneateMainEntityOfPage(data.mainEntityOfPage),
  };
}

/**
 * generateArtistJSON
 *
 * @param {Object} data
 * @returns {Object}
 */
function generateArtistJSON(data: Object = {}): Object {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Person',
    additionalType: 'Artist',
    name: data.name,
    description: data.statement,
    jobTitle: 'Artist',
    url: data.url,
    ...(data.birthCity ? { birthPlace: data.birthCity } : {}),
    ...(data.birthCountry ? { nationality: { '@type': 'Country', name: data.birthCountry } } : {}),
    ...(data.gender ? { gender: data.gender.toLowerCase() === 'm' ? 'Male' : 'Female' } : {}),
    ...geneateMainEntityOfPage(data.mainEntityOfPage),
  };
}

/**
 * generateArticleJSON
 *
 * @param {Object} data
 * @returns {Object}
 */
function generateArticleJSON(data: Object = {}): Object {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    additionalType: 'Article',
    ...(data.author && data.author.fullName
      ? {
          author: {
            '@type': 'Person',
            name: data.author.fullName,
            ...(data.author.url ? { url: data.author.url } : null),
          },
        }
      : null),
    datePublished: data.publishedDate,
    dateModified: data.publishedDate,
    headline: data.title,
    backstory: data.summary,
    image: data.image,
    publisher: {
      '@type': 'Organization',
      name: APP_CONFIG.name,
      url: 'https://www.riseart.com/',
      logo: { '@type': 'ImageObject', url: APP_CONFIG.logo },
      ...(data.publisher || {}),
    },
    ...geneateMainEntityOfPage(data.mainEntityOfPage),
  };
}

/**
 * generateArticleCategoryJSON
 *
 * @param {Object} data
 * @returns {Object}
 */
function generateArticleCategoryJSON(data: Object = {}): Object {
  return {
    '@context': 'https://schema.org/',
    '@type': 'ListItem',
    url: data.url,
    name: data.name,
    description: data.description,
    ...geneateMainEntityOfPage(data.mainEntityOfPage),
  };
}

/**
 * ldSchemaTypes
 */
const ldSchemaTypes = {
  [LDTYPES.WEBPAGE]: (data: Object = {}): Object => ({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: data.metaTitle,
    description: data.metaDescription,
    url: data.url,
  }),
  [LDTYPES.BREADCRUMBS]: (items: Array<Object> = []): Object => {
    const lastIndex = (items && items.length && items.length - 1) || 0;

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: items.reduce(
        (accumulator: Array<Object>, item: Object, position): Array<Object> => {
          const accumulatedLength = accumulator.length;
          const lastAccumulatedItem = accumulatedLength && accumulator[accumulatedLength - 1];

          // Do not add any other items if last item is not a link
          if (lastAccumulatedItem && !lastAccumulatedItem.item) {
            return accumulator;
          }

          return [
            ...accumulator,
            {
              '@type': 'ListItem',
              position: position + 1,
              name: item.name,
              ...(position < lastIndex && item.url ? { item: item.url } : null),
            },
          ];
        },
        [],
      ),
    };
  },
  [LDTYPES.AGGREGATE_RATING]: (
    name: string,
    additonal?: Object = {},
    type?: string = 'Product',
  ): Object => ({
    '@context': 'http://schema.org',
    '@type': type,
    name,
    ...additonal,
    aggregateRating: REVIEWS_IO_RATING,
  }),
  // More information about Event LdJSON: https://developers.google.com/search/docs/advanced/structured-data/event
  [LDTYPES.EVENT]: (data: Object = {}): Object => {
    const {
      name,
      description,
      startDate,
      endDate,
      ticketAmount,
      ticketCurrency,
      externalUrl,
      creatorName,
      creatorUrl,
      venueAddress,
      fullAddress,
    } = data || {};

    // Do not generate any event LDJSON if all required fields are not set
    if (!name || !venueAddress || !fullAddress || !startDate) {
      return null;
    }

    return {
      '@context': 'https://schema.org/',
      '@type': 'Event',
      name,
      ...(description ? { description } : null),
      ...(startDate ? { startDate } : null),
      ...(endDate ? { endDate } : null),
      ...(ticketAmount !== null
        ? {
            offers: {
              '@type': 'Offer',
              price: ticketAmount,
              priceCurrency: ticketCurrency,
              ...(externalUrl ? { url: externalUrl } : null),
            },
          }
        : null),
      // $FlowFixMe
      ...(creatorName
        ? {
            organizer: {
              '@type': 'Person',
              name: creatorName,
              ...(creatorUrl ? { url: creatorUrl } : null),
            },
          }
        : null),
      ...(venueAddress && fullAddress
        ? {
            location: {
              '@type': 'Place',
              name: venueAddress,
              ...(fullAddress
                ? {
                    address: {
                      '@type': 'PostalAddress',
                      name: fullAddress,
                    },
                  }
                : null),
            },
          }
        : null),
    };
  },
  [LDTYPES.PRODUCT]: (data: Object = {}): Object => ({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: data.title,
    description: data.description,
    brand: {
      '@type': 'Person',
      name: data.artistName,
      url: data.artistUrl,
    },
    image: data.image,
    sku: data.productPrimarySku && data.productPrimarySku.sku,
    url: data.url,
    height: {
      '@type': 'QuantitativeValue',
      unitText: data.productPrimarySku && data.productPrimarySku.units,
      value: data.productPrimarySku && data.productPrimarySku.width,
    },
    width: {
      '@type': 'QuantitativeValue',
      unitText: data.productPrimarySku && data.productPrimarySku.units,
      value: data.productPrimarySku && data.productPrimarySku.width,
    },
    aggregateRating: REVIEWS_IO_RATING,
    offers: {
      '@type': 'Offer',
      url: data.url,
      priceCurrency: data.productStore && data.productStore.currency,
      price:
        data.productStore && data.productStore.price && typeof data.productStore.price === 'number'
          ? data.productStore.price.toFixed(2)
          : data.productStore && data.productStore.price,
      availability: mapProductAvailability(data),
      seller: {
        '@type': 'Organization',
        name: 'Rise Art',
        url: 'https://www.riseart.com/',
      },
    },
  }),
  [LDTYPES.ARTWORK]: generateArtworkJSON,
  [LDTYPES.COLLECTION]: ({ author, ...data }: Object = {}): Object => ({
    '@context': 'https://schema.org/',
    '@type': 'Collection',
    name: data.name,
    description: data.summary,
    author: {
      '@type': 'Person',
      name: author.fullName,
      url: author.url,
    },
    ...geneateMainEntityOfPage(data.mainEntityOfPage),
  }),
  [LDTYPES.CAROUSEL_ART]: (data: Array<Object> = []): Object => {
    return {
      '@type': 'ItemList',
      itemListElement: data.map((item, key) => {
        return {
          '@type': 'ListItem',
          position: key + 1,
          item: generateArtworkJSON(item),
        };
      }),
    };
  },
  [LDTYPES.ARTIST]: generateArtistJSON,
  [LDTYPES.ARTISTS_LIST]: (data: Array<Object> = []): Object => {
    return {
      '@type': 'ItemList',
      itemListElement: data.map((item, key) => {
        return {
          '@type': 'ListItem',
          position: key + 1,
          item: generateArtistJSON(item),
        };
      }),
    };
  },
  [LDTYPES.ARTICLE]: generateArticleJSON,
  [LDTYPES.CAROUSEL_ARTICLES]: (data: Array<Object> = []): Object => {
    return {
      '@type': 'ItemList',
      itemListElement: data.map((item, key) => {
        return {
          '@type': 'ListItem',
          position: key + 1,
          item: generateArticleJSON(item),
        };
      }),
    };
  },
  [LDTYPES.ARTICLE_CATEGORIES_LIST]: (data: Array<Object> = []): Object => {
    return {
      '@type': 'ItemList',
      itemListElement: data.map((item, key) => {
        return {
          '@type': 'ListItem',
          position: key + 1,
          item: generateArticleCategoryJSON(item),
        };
      }),
    };
  },
};

/**
 * generateLDJSON
 * @param {string} type
 * @param {Object} data
 * @returns {Object} Generated json for ld+json value
 */
export const generateLDJSON = (type: string, data: Object = {}): Object => {
  const schema = ldSchemaTypes[type];

  if (typeof schema !== 'function') {
    throw new Error(`[generateLDJSON] Invalid type ${type} for ld+json.`);
  }

  return schema(data);
};
