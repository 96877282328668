// @flow

import { libs as LIBS_CONFIG } from 'Config';

/**
 * RaEventsService
 */
export const RaEventsService = {
  eventState: [],
  namespace: LIBS_CONFIG.events.namespace,
  isSSR: typeof window !== 'object',

  /**
   * init
   */
  init(initialData: Array<Object> = []): void {
    RaEventsService.eventState = [];
    initialData &&
      initialData.forEach((data) => {
        RaEventsService.push(data);
      });
  },
  /**
   * lib
   * Libs Getter
   *
   * @returns {object}
   */
  get lib(): Object {
    if (!RaEventsService.isSSR) {
      return window[RaEventsService.namespace];
    }

    return RaEventsService.eventState;
  },
  /**
   * identify
   *
   * @param {'user' | 'visitor'} type
   * @param {string} id
   * @returns {void}
   */
  identify(type: 'user' | 'visitor', id: string | number): void {
    RaEventsService.push(['identify', type, id]);
  },
  /**
   * track
   *
   * @param {string} eventName
   * @param {Object} eventData
   * @param {?Array<Object>} eventResources
   * @returns {void}
   */
  track(eventName: string, eventData?: Object = {}, eventResources?: ?Array<Object> = null): void {
    RaEventsService.push(['track', eventName, eventData, eventResources]);
  },
  /**
   * push
   *
   * @param {Array<Object>} data
   */
  push(data: Array<Object>): void {
    if (data && data.length) {
      RaEventsService.lib.push(data);
    }
  },
  /**
   * extract
   *
   * @returns {Array<Object>}
   */
  extract(): Array<Object> {
    return RaEventsService.eventState;
  },
};
