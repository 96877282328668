// @flow

import { PAGE_PRELOAD_ACTIONS } from 'shared_services/redux/actions/container';

/**
 * pagePreloaderReducer
 *
 * @param {Array<?Object>} state
 * @param {Object} action
 * @returns {Array<?Object>} New state
 */
export function pagePreloaderReducer(
  state: Array<?Object> = [],
  { type, payload }: Object,
): Array<?Object> {
  switch (type) {
    case PAGE_PRELOAD_ACTIONS.DETACH_COMPONENT:
    case PAGE_PRELOAD_ACTIONS.ATTACH_COMPONENT: {
      // first check if item exists in the current state
      const hasItem = state.filter((item) => item && item.id === payload.id).length;

      // if item does not exists in current state and the action is detaching it, do not add it at all becuase it is already resolved
      if (!hasItem && type === PAGE_PRELOAD_ACTIONS.DETACH_COMPONENT) {
        return [...state];
      }

      // if item exists in state, then update it with new data from payload
      if (hasItem) {
        return state.reduce(
          (newState, item) => [
            ...newState,
            item && item.id === payload.id ? { ...item, ...payload } : item,
          ],
          [],
        );
      }

      // if it is a new item, add it to the state
      return [...state, payload];
    }
    case PAGE_PRELOAD_ACTIONS.CLEAR_STATE:
      return [];
    default:
      return state;
  }
}
