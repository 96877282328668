// @flow

import { Component } from 'react';
import queryString from 'query-string';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { cookies as COOKIES_CONFIG } from 'Config';

/**
 * RefererProvider
 */
class ReferrerProvider extends Component<*, *> {
  /**
   * constructor
   *
   * @param {Object} props
   */
  constructor(props: Object) {
    super(props);

    this.setReferrerCookie();
  }

  /**
   * componentDidUpdate
   */
  componentDidUpdate() {
    this.setReferrerCookie();
  }

  /**
   * setReferrerCookie
   */
  setReferrerCookie() {
    const referrer = queryString.parse(LocationManager.get('search')).referer || null;

    if (referrer) {
      Cookies.set(COOKIES_CONFIG.referrerSession.name, referrer, COOKIES_CONFIG.referrerSession);
    }
  }

  /**
   * render
   */
  render() {
    return this.props.children || null;
  }
}

export { ReferrerProvider };
