export const ME_ACTIONS = {
  // Triggered
  ME_FETCH: 'ME_FETCH',
  ME_FETCH_LOADING: 'ME_FETCH_LOADING',
  ME_FETCH_DONE: 'ME_FETCH_DONE',
  ME_FETCH_ERROR: 'ME_FETCH_ERROR',
  ME_UPDATED: 'ME_UPDATED',
  ME_PATCH: 'ME_PATCH',
  ME_CREDIT_ADD: 'ME_CREDIT_ADD',
  ME_SETTINGS_UPDATE: 'ME_SETTINGS_UPDATE',
};

// Action Creators
export function meFetch(inputData) {
  return {
    type: ME_ACTIONS.ME_FETCH,
    payload: {
      ...inputData,
    },
  };
}

export function meFetchLoading() {
  return {
    type: ME_ACTIONS.ME_FETCH_LOADING,
  };
}

export function meFetchDone(payload) {
  return {
    type: ME_ACTIONS.ME_FETCH_DONE,
    payload,
  };
}

export function meFetchError(error) {
  return {
    type: ME_ACTIONS.ME_FETCH_ERROR,
    payload: { ...error },
  };
}

export function mePatch(payload) {
  return {
    type: ME_ACTIONS.ME_PATCH,
    payload,
  };
}

export function meAddCredit(payload) {
  return {
    type: ME_ACTIONS.ME_CREDIT_ADD,
    payload,
  };
}

export function updatedMeSettings(payload) {
  return {
    type: ME_ACTIONS.ME_SETTINGS_UPDATE,
    payload,
  };
}
