// @flow

import React, { useContext } from 'react';
import type { Node } from 'react';
import { injectIntl } from 'react-intl';
import { Logo as LogoCommon } from '@riseart/common';
import { SSRContext } from 'shared_data/providers/ssr/Context';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { withGUIState } from 'shared_hocs/gui/withGuiState';
import { GUI_PROPERTIES } from 'shared_models/Gui';

type Props = {
  intl?: Object,
  black?: boolean,
  external?: boolean,
  pageTitle?: any,
  showTitle?: boolean,
};

/**
 * LogoStatic
 * Riseart Logo component
 *
 * @param {Props} props
 * @returns {Node}
 */
export const LogoStatic = ({
  black = false,
  pageTitle: guiPageTitle = null,
  external = false,
  showTitle = false,
  intl,
  ...restProps
}: Props): Node => {
  const { isSSR, pageTitle: ssrPageTitle } = useContext(SSRContext);
  const pageTitle = isSSR ? ssrPageTitle : guiPageTitle;
  const title =
    showTitle && pageTitle && !pageTitle.disabled
      ? (pageTitle && pageTitle.text) || (intl && intl.formatMessage({ id: 'layouts.logo.title' }))
      : null;

  return (
    <LogoCommon
      title={title}
      linkTitle={(intl && intl.formatMessage({ id: 'meta.home.pageTitle' })) || ''}
      external={external}
      href={UrlAssembler.byRouteKey('home')}
      theme={black ? 'black' : 'white'}
      {...restProps}
    />
  );
};

export const LogoComponent = injectIntl(LogoStatic);
const mapStateToProps = (state) => ({ pageTitle: state[GUI_PROPERTIES.PAGE_TITLE] });
const Logo = withGUIState(mapStateToProps)(LogoComponent);

export default Logo;
