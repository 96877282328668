// @flow

import get from 'lodash/get';
import { store as CONFIG_STORE } from 'Config';

/**
 * selectUserProperty
 *
 * @param {string} property
 * @returns {Function}
 */
export function selectUserProperty(property?: string): Function {
  return (state: Object): ?string => {
    const user = get(state[CONFIG_STORE.keys.me], property ? `data.user.${property}` : 'data.user');

    return user || null;
  };
}

/**
 * selectUserId
 * @param {Object} state
 * @returns {string}
 */
export function selectUserId(state: Object): ?string {
  return selectUserProperty('id')(state);
}
