// @flow

import { GUI_PROPERTIES } from 'shared_models/Gui';

export const GUI_ACTIONS = {
  UPDATE_PROPERTY: 'GUI_UPDATE_PROPERTY',
  UPDATE_RECENT_ART_WIDGET: 'GUI_UPDATE_RECENT_ART_WIDGET',
  TOGGLE_REGIONAL_SETTINGS: 'GUI_TOGGLE_REGIONAL_SETTINGS',
};

export type ActionType = {
  type: string,
  payload: Object,
};

// Action Creators
export function guiUpdate(property: string, value: any): ActionType {
  let decoratedValue = value;

  if (property === 'pageTitle' && typeof decoratedValue === 'string') {
    decoratedValue = { disabled: false, value };
  }

  return {
    type: GUI_ACTIONS.UPDATE_PROPERTY,
    payload: {
      property,
      value: decoratedValue,
    },
  };
}

export function guiUpdateRecentArtWidget(value: any): ActionType {
  return {
    type: GUI_ACTIONS.UPDATE_RECENT_ART_WIDGET,
    payload: {
      property: GUI_PROPERTIES.RECENT_ART_WIDGET,
      value,
    },
  };
}

export function guiToggleRegionalSettings(value: any): ActionType {
  return {
    type: GUI_ACTIONS.UPDATE_PROPERTY,
    payload: {
      property: GUI_PROPERTIES.REGIONAL_SETTINGS_SIDEBAR,
      value,
    },
  };
}
