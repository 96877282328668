import { ERRORS_ACTIONS } from 'shared_services/redux/actions/container';

const DEFAULT_ERROR_STATE = {
  data: [],
};

export function errorsReducer(state = DEFAULT_ERROR_STATE, action) {
  switch (action.type) {
    case ERRORS_ACTIONS.ERROR_ADD:
      return {
        data: [...state.data, { ...action.payload }],
      };
    default:
      return { ...state };
  }
}
