// @flow

import {
  ME_ACTIONS,
  AUTH_ACTIONS,
  FAVORITES_ACTIONS,
  MARKETING_ACTIONS,
  CART_ACTIONS,
  CHECKOUT_ACTIONS,
  FOLLOW_ACTIONS,
  ENQUIRE_ACTIONS,
  EVENT_ACTIONS,
  APP_STORE_ACTIONS,
  META_ACTIONS,
  EVENT_RSVP_ACTIONS,
  GUI_ACTIONS,
} from 'shared_services/redux/actions/container';
import { GOOGLE_TAGMANAGER_ACTIONS } from 'shared_data/providers/google/tagmanager/Actions';

type ActionMapperRule = {
  match: {
    type?: string,
    method?: string,
    endpoint?: string,
  },
  actions: Array<any>,
};

/**
 * List of actions routes
 *
 * @type { Array<ActionMapperRule> }
 */
const ACTION_MAPPER = [
  {
    match: {
      type: AUTH_ACTIONS.TOKEN_UPDATED,
    },
    actions: [
      AUTH_ACTIONS.AUTH_TOKEN_COOKIE_UPDATE,
      AUTH_ACTIONS.AUTH_VISITOR_COOKIE_UPDATE,
      ME_ACTIONS.ME_FETCH,
    ],
  },
  {
    match: {
      type: AUTH_ACTIONS.TOKEN_REFRESH_DONE,
    },
    actions: [AUTH_ACTIONS.AUTH_TOKEN_COOKIE_UPDATE, AUTH_ACTIONS.AUTH_VISITOR_COOKIE_UPDATE],
  },
  {
    match: {
      type: AUTH_ACTIONS.TOKEN_CLEAR,
    },
    actions: [AUTH_ACTIONS.AUTH_TOKEN_COOKIE_CLEAR],
  },
  {
    match: {
      type: ME_ACTIONS.ME_PATCH,
    },
    actions: [
      ME_ACTIONS.ME_UPDATED,
      GOOGLE_TAGMANAGER_ACTIONS.ME_UPDATED,
      EVENT_ACTIONS.ME_UPDATED,
      AUTH_ACTIONS.TOKEN_REFRESH,
    ],
  },
  {
    match: {
      type: ME_ACTIONS.ME_FETCH_DONE,
    },
    actions: [
      ME_ACTIONS.ME_UPDATED,
      GOOGLE_TAGMANAGER_ACTIONS.ME_UPDATED,
      EVENT_ACTIONS.ME_UPDATED,
    ],
  },
  {
    match: {
      type: ME_ACTIONS.ME_CREDIT_ADD,
    },
    actions: [ME_ACTIONS.ME_FETCH],
  },
  {
    match: {
      type: ME_ACTIONS.ME_SETTINGS_UPDATE,
    },
    actions: [ME_ACTIONS.ME_FETCH],
  },
  {
    match: {
      type: FAVORITES_ACTIONS.FAVORITE_ADD,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.FAVORITE_ADD],
  },
  {
    match: {
      type: FAVORITES_ACTIONS.FAVORITE_REMOVE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.FAVORITE_REMOVE],
  },
  {
    match: {
      type: FOLLOW_ACTIONS.FOLLOW_CREATE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.FOLLOW_CREATE],
  },
  {
    match: {
      type: FOLLOW_ACTIONS.FOLLOW_DELETE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.FOLLOW_DELETE],
  },
  {
    match: {
      type: MARKETING_ACTIONS.CAMPAIGN_MATCHED,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.MARKETING_CAMPAIGN_MATCHED],
  },
  {
    match: {
      type: CART_ACTIONS.ITEM_ADD,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_ITEM_ADD],
  },
  {
    match: {
      type: CART_ACTIONS.ITEM_UPDATE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_ITEM_UPDATE],
  },
  {
    match: {
      type: CART_ACTIONS.ITEM_REMOVE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_ITEM_REMOVE],
  },
  {
    match: {
      type: CART_ACTIONS.CREDIT_USE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_CREDIT_USE],
  },
  {
    match: {
      type: CART_ACTIONS.CREDIT_REMOVE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_CREDIT_REMOVE],
  },
  {
    match: {
      type: CART_ACTIONS.COUPON_USE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_COUPON_USE],
  },
  {
    match: {
      type: CART_ACTIONS.COUPON_REMOVE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CART_COUPON_REMOVE],
  },
  {
    match: {
      type: CHECKOUT_ACTIONS.STEP_ENTER,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CHECKOUT_STEP_ENTER],
  },
  {
    match: {
      type: CHECKOUT_ACTIONS.STEP_UPDATE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CHECKOUT_STEP_UPDATE],
  },
  {
    match: {
      type: CHECKOUT_ACTIONS.PURCHASE,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CHECKOUT_PURCHASE, ME_ACTIONS.ME_FETCH],
  },
  {
    match: {
      type: CHECKOUT_ACTIONS.CANCEL_ORDER,
    },
    actions: [ME_ACTIONS.ME_FETCH],
  },
  {
    match: {
      type: ENQUIRE_ACTIONS.ARTWORK_ENQUIRE_REQUEST,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_ENQUIRE_SEND, EVENT_ACTIONS.ARTWORK_ENQUIRE_SUBMIT],
  },
  {
    match: {
      type: ENQUIRE_ACTIONS.ARTWORK_OFFER_REQUEST,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_OFFER_SEND],
  },
  {
    match: {
      type: ENQUIRE_ACTIONS.ARTIST_COMMISSION_REQUEST,
    },
    actions: [
      GOOGLE_TAGMANAGER_ACTIONS.ARTIST_COMMISSION_SEND,
      EVENT_ACTIONS.ARTIST_COMMISSION_SUBMIT,
    ],
  },
  {
    match: {
      type: ENQUIRE_ACTIONS.CMS_ENQUIRE_REQUEST,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.CMS_ENQUIRE_SEND, EVENT_ACTIONS.CMS_ENQUIRE_SUBMIT],
  },
  {
    match: {
      type: APP_STORE_ACTIONS.UPDATE_PROPERTY,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.APPLICATION_PROPERTY_UPDATE],
  },
  {
    match: {
      type: META_ACTIONS.PAGE_META_RESOLVED,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.PAGE_META_RESOLVED],
  },
  {
    match: {
      type: EVENT_RSVP_ACTIONS.STATUS_SET,
    },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.EVENT_RSVP],
  },
  {
    match: { type: GUI_ACTIONS.UPDATE_RECENT_ART_WIDGET },
    actions: [GOOGLE_TAGMANAGER_ACTIONS.RECENT_WIDGET_TOGGLE],
  },
];

/**
 * Middleware actions dispatcher
 */
export default class MiddlewareActionsRouter {
  /**
   * Actions router
   *
   * @param {*} action
   * @returns {Array<any>}
   */
  static route = (action: any): Array<any> => {
    const actions = [];

    ACTION_MAPPER.forEach((rule: ActionMapperRule) => {
      if (
        action.type === rule.match.type &&
        action.method === rule.match.method &&
        action.endpoint === rule.match.endpoint &&
        rule.actions
      ) {
        rule.actions.forEach((item) => {
          if (typeof item === 'function') {
            actions.push(item());
          } else {
            actions.push({ type: item, payload: action });
          }
        });
      }
    });

    return actions;
  };

  /**
   * Redux Middleware
   *
   * @param { Object } store
   */
  static middleware = (store: Object) => (next: Function) => (action: any) => {
    const actions = MiddlewareActionsRouter.route(action);

    actions.forEach((item) => {
      store.dispatch(item);
    });

    next(action);
  };
}
