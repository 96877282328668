// @flow

import { APP_STORE_ACTIONS } from 'shared_services/redux/actions/container';
import type { ActionType } from 'shared_services/redux/actions/application/store';

const DEFAULT_STATE = {
  userLocale: null,
  routeLocale: null,
  redirects: null,
};

/**
 * applicatonStoreReducer
 *
 * @param {Object} state
 * @param {ActionType} action
 * @returns {Object}
 */
export function applicatonStoreReducer(state: Object = DEFAULT_STATE, action: ActionType): Object {
  switch (action.type) {
    case APP_STORE_ACTIONS.UPDATE_PROPERTY:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    case APP_STORE_ACTIONS.UPDATE_LOCALES: {
      const { routeLocale, userLocale } = action.payload.locales;

      return {
        ...state,
        ...(routeLocale ? { routeLocale } : null),
        ...(userLocale ? { userLocale } : null),
      };
    }
    case APP_STORE_ACTIONS.UPDATE_REDIRECT_PROPERTY: {
      return {
        ...state,
        redirects: (action && action.payload && action.payload.redirects) || {
          locale: null,
          canonical: null,
        },
      };
    }
    case APP_STORE_ACTIONS.RESET_PROPERTY: {
      return {
        ...state,
        [action.payload.property]: null,
      };
    }
    default:
      return { ...state };
  }
}
