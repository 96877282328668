// @flow

import React from 'react';
import type { Node } from 'react';
import { SSRContext } from 'shared_data/providers/ssr/Context';

type Props = {
  children: any,
  isSSR?: ?boolean,
  httpRequest?: Object,
  httpResponse?: Object,
  pageTitle?: Object,
};

/**
 * SSRProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
export const SSRProvider = ({
  isSSR = false,
  httpRequest = null,
  httpResponse = null,
  children,
  ...restProps
}: Props): Node => (
  <SSRContext.Provider value={{ isSSR, httpRequest, httpResponse, ...restProps }}>
    {children}
  </SSRContext.Provider>
);
